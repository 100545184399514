import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { Profile } from "../../models/profile.model";
import { ToastService } from "../../services/toast.service";
import { NavigationService } from "../../../core/services/navigation.service";
import { pages } from "../../config/pages";
import {
  ANESTHETIST,
  IADE,
  NURSE_TYPES,
  OPPOSITE_ZOOM_VALUE,
  SURGEON,
  WEIGHT,
  ZOOM_VALUE
} from "../../const/glabals.const";
import domtoimage from 'dom-to-image';
import { UtilisService } from "../../services/utilis.service";
import { RoomService } from "../../services/room.service";
import { UserService } from "../../services/user.service";
import { PythonAPIService } from "../../services/pythonApi.service";
import { User } from "../../models/user.model";
import { HospitalService } from "../../services/hospital.service";
import { Observable, Subscription } from "rxjs";
import { ErrorService } from "../../services/error.service";
import { cloneWithoutPromise, dateWithTime, getFirstHospitalSelectedData, getRoomHoursBySelectedDay, getSelectedHospitalData, setTypeAttributeArray, sortRoomsByPriority } from "../../utils/cross-functions";
import { BufferProgramService } from "../../services/buffer-program.service";
import { Paramedical } from "../../models/paramedical.model";
import { Role } from "../../models/role.model";
import { ParamedicalService } from "../../services/paramedical.service";
import { SO, SurgeonOpening } from "../../models/surgeonOpenings.model";
import { roomWithDoctorAgendas, roomWithSOAndSOH } from "../../interfaces/room.interfaces";
import { roomBufferPrograms } from "../../interfaces/buffer-program.interfaces";
import { SurgeonOpeningHistory } from "../../models/surgeonOpeningsHistory.model";
import { consultationExtracliniqueData, consultationExtracliniquePrograms, profileCard, roomFinalData, surgeonCard } from "./day-program-interfaces";
import { SpecialtyService } from "../../services/specialty.service";
import { Specialty } from "../../models/specialty.model";
import { CdkDrag, CdkDragDrop, CdkDragMove, moveItemInArray } from "@angular/cdk/drag-drop";
import { BufferProgram } from "../../models/buffer-program.model";
import { ProfileService } from "../../services/profile.service";
import { allAvailableNurses } from "../../interfaces/profile.interfaces";
import { Calendar, isAbsent, isConsultation, isGuard } from "../../models/calendar.model";
import { AutofillingComponent } from "../autofilling-popup/autofilling-popup.component";
import { Formation } from "../../models/formation.model";
import { ExportDayComponent } from "../export-day-popup/export-day-popup.component";
import { ProgramCommentary } from "../../models/program-commentary.model";
import { ProgramCommentaryService } from "../../services/program-commentary.service";
import { dailyNeeds, typesDailyNeeds } from "../../models/anesthNeed.model";
import { anesthsRanks, nursesRanking } from "../../interfaces/pythonAPI.interfaces";
import { SkillService } from "../../services/skill.service";
import { RoleLevel } from "../../models/skill.model";
import { Hospital } from "../../models/hospital.model";
import jsPDF, { jsPDFOptions } from "jspdf";
import { DataColumnComponent } from "./data-column/data-column.component";
import { resolve } from "url";
@Component({
  selector: "app-day-program-recap",
  templateUrl: "./day-program-recap.component.html",
  styleUrls: ["./day-program-recap.component.scss"],
})
export class DayProgramRecapComponent implements OnInit, OnDestroy {
  @Input() day: Date;
  @Input() isHome: boolean;
  @Input() readOnly: boolean = false;
  @Input() redirectOnClickOnProfile: boolean = false; //TODO
  @Input() showAllRooms: boolean = true;
  @Input() hideAvailableProfiles: boolean = false;

  @Output() onRolesReception: EventEmitter<Role[]> = new EventEmitter<Role[]>();

  // Export
  @ViewChild('roomsArea', null) roomsArea: ElementRef
  @ViewChild('roomsAreaExport', null) roomsAreaExport: ElementRef
  @ViewChildren('dataColumn') dataColumns: QueryList<ElementRef>;
  @ViewChildren('dataColumnConsult') dataColumnsConsult: QueryList<DataColumnComponent>;
  @ViewChildren('dataColumnExtra') dataColumnsExtra: QueryList<DataColumnComponent>;
  public isExporting: boolean = false;

  // Header
  public headers: string[] = ["Bloc", "Consultation", "Extraclinique"]
  public selectedHeader: string = "Bloc";
  public isBlocSelected: boolean = true;
  public isConsultationSelected: boolean = false;
  public isExtracliniqueSelected: boolean = false;

  // RankingData
  public nursesRankings: nursesRanking[];
  public anesthsRankings: anesthsRanks;
  public nursesRankingsLoaded: boolean = false;
  public anesthsRankingsLoaded: boolean = false;

  // RoomsData
  public roomsSurgeonOpenings: roomWithSOAndSOH[];
  public roomsBufferPrograms: roomBufferPrograms[];
  public rooms: roomFinalData[];
  public specialties: Specialty[];
  public extracliniqueSpecialty: consultationExtracliniqueData[];
  public consultationSpecialties: consultationExtracliniqueData[];
  public consultationNeeds: dailyNeeds[] = [];
  public blocNeeds: dailyNeeds[] = [];
  public interventions: roomWithDoctorAgendas[] = []
  public typeAttributeArray: string[];
  public listIdsBloc: string[] = [];
  public listIdsConsultationExtraclinique: string[] = [];

  // Effective Needs Data
  public effectif: any[];
  public needs: any[];
  public effectifPerResidency: any[];
  public effectifPerResidencyAnesth: any[];
  public isEffectiveNeedsLoading: boolean = false;
  public isEffectiveNeedsAnesthLoading: boolean = false;
  public needsEffectifsBlocAnesth: any;
  public needsEffectifsBlocIade: any;
  public needsEffectifsConsultation: { effectifs: any; needs: any; difference: number; }[];
  public needsEffectifs: any;
  public needsEffectifsBlocGlobal: any;
  public needsEffectifsInterns: any;
  public nursesObjectifStatus: string = '';
  private residency: any[];

  // Hospital Data
  public hospitalType: string;
  
  // User Data
  public currentUser: User;
  public levelOfAccess: number = 1;
  public hospitalHaveAnesthDetailsOption: boolean = false;
  public isRespIade: boolean = false;
  public isLowLevel: boolean = false;
  public isInternRes: boolean = false;
  public isAnesthRes: boolean = false;
  public isCadreDeBloc: boolean = false;
  public isMultiService: boolean = false;
  public isLoading: boolean = true;
  public isLoadingRooms: boolean = true;
  public isLoadingConsultExtra: boolean = false;
  public currentPosition: number = 0;
  public canDeleteAllRooms: boolean = true;
  public allNurses: allAvailableNurses[];
  public anesthetistsCalendars: Calendar[];
  public urgenceProfile: Profile;
  public cardPressed: boolean;
  public nbColumns: number = 2;
  public sortByName: boolean = true;
  public showRoles : boolean = true;


  // Comment
  public comment: string = null;
  public programCommentary: ProgramCommentary;
  public hidden: boolean = false;
  public commentHidden: boolean = false;
  
  // Paramedical Data
  public rolesData: Role[]
  public paramedicalsData: Paramedical[]
  public formationsData: Formation[]
  
  // Subscription
  private getSOSubscription: Subscription;
  private getBufferProgramsSubscription: Subscription;
  private getRolesSubscription: Subscription;
  private getParamedicalsSubscription: Subscription;
  private getFormationsSubscription: Subscription;
  private getRoomServiceReloadSubscription: Subscription;
  private getRoomServiceSplitRoomSubscription: Subscription;
  private getRoomServiceEmptyRoomSubscription: Subscription;
  private getSpecialtiesSubscription: Subscription;
  private allNursesSubscription: Subscription;
  private availableAnesthetistsSubscription: Subscription;
  private dailyNeedsSubscription: Subscription;
  private interventionsSubscription: Subscription;
  private getNursesRankingsSubscription: Subscription;
  private getAnesthsRankingsSubscription: Subscription;
  private updateBufferProgramSubscription: Subscription;
  private getUrgenceProfileSubscription: Subscription;
  private programCommentarySubscription: Subscription;
  private getDayAveragesSubscription: Subscription;
  private getDayAveragesAnesthSubscription: Subscription;

  private maxConsultTitleLength: number = 22;
  public reasonMapColors: Map<string, string> = new Map<string, string>(); // key : profileID   value : color in hexadecimal

  get nbRoomsNotEmpty() {
    const rooms = this.rooms.filter((room) => !room.isEmpty)

    return rooms.length;
  }

  get pastDate() {
    const currentDay = new Date();
    const copyDay = new Date(this.day)
    currentDay.setUTCHours(0, 0, 0, 0)
    copyDay.setUTCHours(0, 0, 0, 0)
    if (copyDay.getTime() < currentDay.getTime()) {
      return true;
    }

    return false;
  }

  get date() {
    return moment(this.day).format("YYYY-MM-DD").toString();
  }
  
  get isDayWeek(): boolean {
    const day: number = new Date(this.date).getUTCDay();
    return 0 < day && day < 6;
  }

  get isProgramsChanged(): boolean {
    return this.rooms && ((this.rooms.filter((room) => room.modified)).length > 0);
  }

  get mergedRooms(): roomFinalData[] {
    const mergedRooms: roomFinalData[] = []

    this.rooms.forEach(room => {
      const copyRoom = JSON.parse(JSON.stringify(this.removePromises(room)))
      if (copyRoom.isBP) {
        if (copyRoom.isSplit && !copyRoom.splitByVacation) {
          copyRoom.programs = []
          copyRoom.morningPrograms.programs.forEach(program => {
            program.nurses = copyRoom.morningPrograms.team.others.filter((card) => String(card.profile.position) !== "Anesthésiste").map((card) => ({
              profile: card.profile,
              role: card.role
            }))
            program.anesthesists = copyRoom.morningPrograms.team.others.filter((card) => String(card.profile.position) === "Anesthésiste").map((card) => card.profile)

            copyRoom.programs.push(program)
          });

          copyRoom.afternoonPrograms.programs.forEach(program => {
            program.nurses = copyRoom.afternoonPrograms.team.others.filter((card) => String(card.profile.position) !== "Anesthésiste").map((card) => ({
              profile: card.profile,
              role: card.role
            }))
            program.anesthesists = copyRoom.afternoonPrograms.team.others.filter((card) => String(card.profile.position) === "Anesthésiste").map((card) => card.profile)

            copyRoom.programs.push(program)
          });
        } else if (room.isSplit && room.splitByVacation) {
          copyRoom.programs.forEach(program => {
            program.nurses = program.team.others.filter((card) => String(card.profile.position) !== "Anesthésiste").map((card) => ({
              profile: card.profile,
              role: card.role
            }))
            program.anesthesists = program.team.others.filter((card) => String(card.profile.position) === "Anesthésiste").map((card) => card.profile)
          });
        } else {
          copyRoom.programs.forEach(program => {
            program.nurses = copyRoom.team.others.filter((card) => String(card.profile.position) !== "Anesthésiste").map((card) => ({
              profile: card.profile,
              role: card.role
            }))
            program.anesthesists = copyRoom.team.others.filter((card) => String(card.profile.position) === "Anesthésiste").map((card) => card.profile)
          });
        }
      } else {
        if (copyRoom.isSplit && !copyRoom.splitByVacation) {
          copyRoom.surgeonOpenings = []
          copyRoom.morningPrograms.surgeonOpenings.forEach(surgeonOpening => {
            surgeonOpening.nurses = copyRoom.morningPrograms.team.others.filter((card) => String(card.profile.position) !== "Anesthésiste").map((card) => ({
              profile: card.profile,
              role: card.role
            }))
            surgeonOpening.anesthesists = copyRoom.morningPrograms.team.others.filter((card) => String(card.profile.position) === "Anesthésiste").map((card) => card.profile)

            copyRoom.surgeonOpenings.push(surgeonOpening)
          });

          copyRoom.afternoonPrograms.surgeonOpenings.forEach(surgeonOpening => {
            surgeonOpening.nurses = copyRoom.afternoonPrograms.team.others.filter((card) => String(card.profile.position) !== "Anesthésiste").map((card) => ({
              profile: card.profile,
              role: card.role
            }))
            surgeonOpening.anesthesists = copyRoom.afternoonPrograms.team.others.filter((card) => String(card.profile.position) === "Anesthésiste").map((card) => card.profile)

            copyRoom.surgeonOpenings.push(surgeonOpening)
          });
        } else if (room.isSplit && room.splitByVacation) {
          copyRoom.surgeonOpenings.forEach(surgeonOpening => {
            surgeonOpening.nurses = surgeonOpening.team.others.filter((card) => String(card.profile.position) !== "Anesthésiste").map((card) => ({
              profile: card.profile,
              role: card.role
            }))
            surgeonOpening.anesthesists = surgeonOpening.team.others.filter((card) => String(card.profile.position) === "Anesthésiste").map((card) => card.profile)
          });
        } else {
          copyRoom.surgeonOpenings.forEach(surgeonOpening => {
            surgeonOpening.nurses = copyRoom.team.others.filter((card) => String(card.profile.position) !== "Anesthésiste").map((card) => ({
              profile: card.profile,
              role: card.role
            }))
            surgeonOpening.anesthesists = copyRoom.team.others.filter((card) => String(card.profile.position) === "Anesthésiste").map((card) => card.profile)
          });
        }
      }
      copyRoom.roomNumber = room.room.roomNumber
      mergedRooms.push(copyRoom)
    });

    return mergedRooms
  }

  get roomsWithPrograms() {
    const mergedRoomsNotEmpty = this.mergedRooms.filter((room) => !room.isEmpty && room.isBP);

    (mergedRoomsNotEmpty as any).forEach(mergedRoomNotEmpty => {
      const programsUnMerged = []
      mergedRoomNotEmpty.programs.forEach(program => {
        if (program.mergedPrograms && program.mergedPrograms.length > 1) {
          program.mergedPrograms.forEach(mergedProgram => {
            mergedProgram.team.others = program.team.others;
            programsUnMerged.push(mergedProgram)
          });
        } else {
          programsUnMerged.push(program)
        }
      });

      mergedRoomNotEmpty.programs = programsUnMerged;
    });

    return mergedRoomsNotEmpty;
  }

  get columnHeight() {
    const calc = 100 / this.nbColumns;

    return `calc(${calc}% - 3px)`
  }

  get isAnesthetist(): boolean {
    if (this.currentUser.profile.position && this.currentUser.profile.position === "Anesthésiste") {
      return true;
    }
    return false;
  }

  get readOnlyByPermission(): boolean {
    if (this.isAnesthetist && this.levelOfAccess === 2) {
      return true;
    }
    return false;
  }
  
  constructor(
    private toastService: ToastService,
    private roomsService: RoomService,
    private pythonAPIService: PythonAPIService,
    private userService: UserService,
    private dialog: MatDialog,
    private navigationService: NavigationService,
    private utilisService: UtilisService,
    private hospitalService: HospitalService,
    private errorService: ErrorService,
    private bufferProgramService: BufferProgramService,
    private paramedicalService: ParamedicalService,
    private specialtyService: SpecialtyService,
    private profileService: ProfileService,
    private programCommentaryService: ProgramCommentaryService,
    private skillService: SkillService,
    private renderer: Renderer2
  ) {
    this.isCadreDeBloc = this.userService.isCadreBloc()
    this.isMultiService = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.currentUser = this.userService.getCurrentUser();
    this.levelOfAccess = this.currentUser.levelOfAccess;
    this.hospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption()
    if (this.hospitalHaveAnesthDetailsOption) {
      this.isLoadingConsultExtra = true;
    }
    this.isLowLevel = this.userService.isUserHasLowLevelAccess()
    this.isRespIade = this.userService.isIadRes()

    this.isAnesthRes = this.userService.isAnestgWithHighLevel();
    this.isInternRes = this.userService.isInternResponsible();

    this.subscribeReload();
    this.subscribeSplitRoom();
    this.subscribeEmptyRoom();
  }

  removePromises(room: roomFinalData) {
    if (room.isSplit) {
      if (room.splitByVacation) {
        if (room.isBP) {
          room.programs.forEach(program => {
            program.team.others.forEach(card => {
              card.scorePromise = null;
            });
          });
        } else {
          room.surgeonOpenings.forEach(surgeonOpening => {
            surgeonOpening.team.others.forEach(card => {
              card.scorePromise = null;
            });
          });
        }
      } else {
        room.morningPrograms.team.others.forEach(card => {
          card.scorePromise = null;
        });
        
        room.afternoonPrograms.team.others.forEach(card => {
          card.scorePromise = null;
        });
      }
    }
    room.team.others.forEach(card => {
      card.scorePromise = null;
    });
    return room;
  }

  getNursesObjectifStatus(status: string) {
    setTimeout(() => {
      this.nursesObjectifStatus = status;
    }, 50);
  }

  setCommentHidden() {
    this.commentHidden = !this.commentHidden;
  }

  setHidden() {
    this.hidden = !this.hidden
  }

  getUrgenceProfile() {
    return new Promise<void>((resolve, reject) => {
      if (this.getUrgenceProfileSubscription) {
        this.getUrgenceProfileSubscription.unsubscribe();
      }
  
      this.getUrgenceProfileSubscription = this.profileService
        .getProfiles(1, 1, "Urgences")
        .subscribe(
          (res) => {
            this.urgenceProfile = res.docs[0];
            resolve();
          },
          (error) => {
            this.isLoading = false;
            this.errorService.handleError(error);
            reject();
          }
        );
    })
  }

  isChangesUnsaved() {
    return this.isProgramsChanged;
  }

  // Check if data has been changed before going to the next event
  goToNextEvent() {
    if (this.isProgramsChanged) {
      return this.utilisService.openUnSavedChangesPopup();
    } else {
      return new Observable((sub) => sub.next(true));
    }
  }

  // Format date for cleaning room
  formatDate(date: Date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  getNursesRankings(): void {
    if (this.isHome) {
      return;
    }

    this.nursesRankings = []
    
    if (this.getNursesRankingsSubscription) {
      this.getNursesRankingsSubscription.unsubscribe();
    }

    this.getNursesRankingsSubscription = this.pythonAPIService.getNursesRanking(this.date).subscribe((response) => {
      this.nursesRankings = response
      this.nursesRankingsLoaded = true;
      this.roomsService.updateRankingsSmartPlanning.next({anesthsRankings: this.anesthsRankings, nursesRankings: this.nursesRankings})
    });
  }

  getAnesthsRankings(): void {
    if (this.isHome || !this.hospitalHaveAnesthDetailsOption){
      return;
    }

    this.anesthsRankings = null

    if (this.getAnesthsRankingsSubscription) {
      this.getAnesthsRankingsSubscription.unsubscribe();
    }
    
    this.getAnesthsRankingsSubscription = this.pythonAPIService.getAnesthRanking(this.date).subscribe((response) => {
      this.anesthsRankings = response
      this.anesthsRankingsLoaded = true;
      this.roomsService.updateRankingsSmartPlanning.next({anesthsRankings: this.anesthsRankings, nursesRankings: this.nursesRankings})
    });
  }

  clearRoom(team: profileCard[], room: roomFinalData) {
    if (this.isRespIade) {
      team = team.filter((card) => !IADE.includes(card.profile.position))
    } else if (this.isAnesthRes && !this.isInternRes) {
      if (room.isSplit) {
        const otherRooms = this.rooms.filter((room2) => String(room2.roomId) === String(room.roomId))

        otherRooms.forEach(otherRoom => {
          if (!room.splitByVacation) {
            if (otherRoom.morningPrograms === room) {
              otherRoom.morningPrograms.team.others = otherRoom.team.others.filter((card) => !IADE.includes(card.profile.position) && card.profile.position !== 'Anesthésiste')
              otherRoom.afternoonPrograms.team.others = otherRoom.afternoonPrograms.team.others.filter((card) => card.profile.position !== 'Anesthésiste')
            } else if (otherRoom.afternoonPrograms === room) {
              otherRoom.afternoonPrograms.team.others = otherRoom.team.others.filter((card) => !IADE.includes(card.profile.position) && card.profile.position !== 'Anesthésiste')
              otherRoom.morningPrograms.team.others = otherRoom.morningPrograms.team.others.filter((card) => card.profile.position !== 'Anesthésiste')
            }
          }
        });
      } else {
        team = team.filter((card) => !IADE.includes(card.profile.position) && card.profile.position !== 'Anesthésiste')
      }
    } else if (this.userService.isCadreBloc() && this.userService.getLevelAccess() === 4) {
      team = team.filter((card) => !NURSE_TYPES.includes(card.profile.position))
    } else if (this.isInternRes) {
      if (room.isSplit && !room.splitByVacation) {
        const otherRooms = this.rooms.filter((room2) => String(room2.roomId) === String(room.roomId))

        otherRooms.forEach(otherRoom => {
          if (!room.splitByVacation) {
            otherRoom.team.others = otherRoom.team.others.filter((card) => (card.profile.position !== 'Anesthésiste' && card.profile.seniority === 'Interne'))
            otherRoom.morningPrograms.team.others = otherRoom.morningPrograms.team.others.filter((card) => (card.profile.position !== 'Anesthésiste' && card.profile.seniority === 'Interne'))
            otherRoom.afternoonPrograms.team.others = otherRoom.afternoonPrograms.team.others.filter((card) => (card.profile.position !== 'Anesthésiste' && card.profile.seniority === 'Interne'))
          } else {
            if (otherRoom.isBP) {
              otherRoom.programs.forEach(program => {
                program.team.others = program.team.others.filter((card) => (card.profile.position !== 'Anesthésiste' && card.profile.seniority === 'Interne'))
              });
            } else {
              otherRoom.surgeonOpenings.forEach(surgeonOpening => {
                surgeonOpening.team.others = surgeonOpening.team.others.filter((card) => (card.profile.position !== 'Anesthésiste' && card.profile.seniority === 'Interne'))
              });
            }
          }
        });
      } else {
      team = team.filter((card) => (card.profile.position !== 'Anesthésiste' && card.profile.seniority === 'Interne'))
      }
    } else {
      if (room.isSplit) {
        const otherRooms = this.rooms.filter((room2) => String(room2.roomId) === String(room.roomId))
        
        otherRooms.forEach(otherRoom => {
          if (!room.splitByVacation) {
            if (otherRoom.morningPrograms === room) {
              otherRoom.morningPrograms.team.others = otherRoom.morningPrograms.team.others.splice(0, otherRoom.morningPrograms.team.others.length)
            } else if (otherRoom.afternoonPrograms === room) {
              otherRoom.afternoonPrograms.team.others = otherRoom.afternoonPrograms.team.others.splice(0, otherRoom.afternoonPrograms.team.others.length)
            } else {
              otherRoom.team.others = otherRoom.team.others.filter((card) => card.profile.position !== 'Anesthésiste')
              otherRoom.morningPrograms.team.others = otherRoom.morningPrograms.team.others.filter((card) => card.profile.position !== 'Anesthésiste')
              otherRoom.afternoonPrograms.team.others = otherRoom.afternoonPrograms.team.others.filter((card) => card.profile.position !== 'Anesthésiste')
            }
          } else {
            if (otherRoom.isBP) {
              otherRoom.programs.forEach(program => {
                if (program.team.others === team) {
                  program.team.others = program.team.others.splice(0, program.team.others.length)
                }
                program.team.others = program.team.others.filter((card) => card.profile.position !== 'Anesthésiste')
              });
            } else {
              otherRoom.surgeonOpenings.forEach(surgeonOpening => {
                if (surgeonOpening.team.others === team) {
                  surgeonOpening.team.others = surgeonOpening.team.others.splice(0, surgeonOpening.team.others.length)
                }
                surgeonOpening.team.others = surgeonOpening.team.others.filter((card) => card.profile.position !== 'Anesthésiste')
              });
            }
          }
        });
      }
      team = []
    }
    return team;
  }

  // To Empty room
  subscribeEmptyRoom() {
    if (this.getRoomServiceEmptyRoomSubscription) {
      this.getRoomServiceEmptyRoomSubscription.unsubscribe();
    }

    this.getRoomServiceEmptyRoomSubscription = this.roomsService.emptyRoomSmartPlanning.subscribe((data) => {
      const copyData = JSON.parse(JSON.stringify(data))
      if (data.isBloc) {
        let room = this.rooms.find((room2) => room2 === data.data)
  
        if (!room) {
          this.rooms.forEach(room2 => {
            if ((room2.morningPrograms || room2.afternoonPrograms) && (room2.morningPrograms === data.data) || (room2.afternoonPrograms === data.data)) {
              room = room2;
            }
          });
        }
        if (room.isBP) {
          let clearBuffer = false;
          if (room.isSplit) {
            let allEmpty = true;
            const rooms = []

            if (room.splitByVacation) {
              room.programs.forEach((program, index) => {
                if (index !== data.programIndex) {
                  rooms.push(program)
                }
              });
            } else {
              if (data.programIndex === 1) {
                rooms.push(room.morningPrograms)
              } else {
                rooms.push(room.afternoonPrograms)
              }
            }

            rooms.forEach(roomData => {
              if (roomData.team.others.length > 0) {
                allEmpty = false;
              }
            });
            if (allEmpty) {
              clearBuffer = true;
            } else {
              if (room.splitByVacation) {
                room.programs[data.programIndex].team.others = this.clearRoom(room.programs[data.programIndex].team.others, room)
              } else {
                if (data.programIndex === 0) {
                  room.morningPrograms.team.others = this.clearRoom(room.morningPrograms.team.others, room)
                } else {
                  room.afternoonPrograms.team.others = this.clearRoom(room.afternoonPrograms.team.others, room)
                }
              }
            }
          } else {
            clearBuffer = true;
          }
          if (clearBuffer) {
            this.isLoading = true;
            this.goToNextEvent().subscribe((res) => {
              if (res && res != "cancel") {
                // no changes or discard changes and continue
                this.bufferProgramService
                .cleanRoom(room.roomId, this.formatDate(this.day))
                .subscribe(
                  async () => {
                    await this.reload(true);
                    this.isLoadingRooms = false;
                  },
                  (error) => {
                    this.errorService.handleError(error);
                  }
                );
              } else if (res != "cancel") {
                // save
                this.save()
                this.bufferProgramService
                .cleanRoom(room.roomId, this.formatDate(this.day))
                .subscribe(
                  async () => {
                    await this.reload(true);
                    this.isLoadingRooms = false;
                  },
                  (error) => {
                    this.errorService.handleError(error);
                  }
                );
              }
            });
            this.isLoadingRooms = false;
            this.isLoading = false;
          }
        } else {
          this.isLoadingRooms = true;
          if (room.isSplit) {
            let allEmpty = true;
            const rooms = []

            if (room.splitByVacation) {
              room.surgeonOpenings.forEach((surgeonOpening, index) => {
                if (index !== data.programIndex) {
                  rooms.push(surgeonOpening)
                }
              });
            } else {
              if (data.programIndex === 1) {
                rooms.push(room.morningPrograms)
              } else {
                rooms.push(room.afternoonPrograms)
              }
            }

            rooms.forEach(roomData => {
              if (roomData.team.others.length > 0) {
                allEmpty = false;
              }
            });
            if (allEmpty) {
              if (!room.splitByVacation) {
                room.morningPrograms.team.others = this.clearRoom(room.morningPrograms.team.others, room)
                room.afternoonPrograms.team.others = this.clearRoom(room.afternoonPrograms.team.others, room)
              }
              room.roomNumber = room.room.roomNumber
              room.surgeonOpenings = JSON.parse(JSON.stringify(room.originalSO))
              room.surgeonOpenings.forEach(surgeonOpening => {
                surgeonOpening.team = this.getTeam(room.isBP, room.isSplit, [surgeonOpening], true, false, room)
              });
              room.team = this.getTeam(room.isBP, room.isSplit, room.originalSO, true, false, room)
              room.isSplit = false;
              room.splitByVacation = null;
            } else {
              if (room.splitByVacation) {
                room.surgeonOpenings[data.programIndex].team.others = this.clearRoom(room.surgeonOpenings[data.programIndex].team.others, room)
              } else {
                if (data.programIndex === 0) {
                  room.morningPrograms.team.others = this.clearRoom(room.morningPrograms.team.others, room)
                } else {
                  room.afternoonPrograms.team.others = this.clearRoom(room.afternoonPrograms.team.others, room)
                }
              }
            }
          } else {
            room.roomNumber = room.room.roomNumber
            room.surgeonOpenings = JSON.parse(JSON.stringify(room.originalSO))
            room.team = this.getTeam(room.isBP, room.isSplit, room.originalSO, true, false, room)
            room.isSplit = false;
            room.splitByVacation = null;
          }
          this.isLoadingRooms = false;
        }
        room.modified = true;
        this.listIdsBloc = this.getDragDropListIdsBloc();
        this.setFillingSurgeons();
      } else {
        const consultExtraData: consultationExtracliniqueData = [].concat(this.consultationSpecialties, this.extracliniqueSpecialty).find((data2) => data2 === data.data)

        if (consultExtraData.isSplit) {
          let allEmpty = true;
          const consultExtraDatas: consultationExtracliniqueData[] = [].concat(this.consultationSpecialties, this.extracliniqueSpecialty).filter((data2: consultationExtracliniqueData) => String(data2.specialty._id) === String(consultExtraData.specialty._id) && data2 !== data.data)

          consultExtraDatas.forEach(consultExtraData2 => {
            if (consultExtraData2.team.length > 0) {
              allEmpty = false;
            }
          });
          if (allEmpty) {
            consultExtraDatas.forEach(consultExtraData2 => {
              if (consultExtraData2.specialty.name === "Extraclinique") {
                const extraDataIndex = this.extracliniqueSpecialty.findIndex((extraData) => extraData === consultExtraData2)
                this.extracliniqueSpecialty.splice(extraDataIndex, 1)
              } else {
                const consultDataIndex = this.consultationSpecialties.findIndex((consultData) => consultData === consultExtraData2)
                this.consultationSpecialties.splice(consultDataIndex, 1)
              }
            });
            consultExtraData.title = consultExtraData.specialty.name;
            consultExtraData.isSplit = false;
            consultExtraData.dayPrograms = []
            consultExtraData.morningPrograms = [];
            consultExtraData.afternoonPrograms = [];
            consultExtraData.team = [];
          } else {
            consultExtraData.team = [];
          }
        } else {
          consultExtraData.title = consultExtraData.specialty.name;
          consultExtraData.dayPrograms = []
          consultExtraData.morningPrograms = []
          consultExtraData.afternoonPrograms = []
          consultExtraData.isSplit = false;
          consultExtraData.team = []
        }
        consultExtraData.modified = true;
        this.listIdsConsultationExtraclinique = this.getDragDropListIdsConsultationExtraclinique();
      }
      this.roomsService.updateEmptyRoomSmartPlanning.next(copyData)
    })
  }

  getDragDropListIdsConsultationExtraclinique() {
    const listIds = []
    this.consultationSpecialties.forEach(consultationSpecialty => {
      if (!consultationSpecialty.isSplit) {
        listIds.push(consultationSpecialty.specialty._id)
      } else {
        if (consultationSpecialty.isMorning) {
          listIds.push(consultationSpecialty.specialty._id + 'morning')
        } else {
          listIds.push(consultationSpecialty.specialty._id + 'afternoon')
        }
      }
    });

    this.extracliniqueSpecialty.forEach(extracliniqueSpecialty => {
      if (!extracliniqueSpecialty.isSplit) {
        listIds.push(extracliniqueSpecialty.specialty._id)
      } else {
        if (extracliniqueSpecialty.isMorning) {
          listIds.push(extracliniqueSpecialty.specialty._id + 'morning')
        } else {
          listIds.push(extracliniqueSpecialty.specialty._id + 'afternoon')
        }
      }
    });

    return listIds
  }

  getDragDropListIdsBloc() {
    const listIds = []
    this.rooms.forEach(room => {
      if (!room.isSplit || this.isAnesthRes) {
        listIds.push(room.roomId)
      } else {
        if (room.splitByVacation) {
          if (room.isBP) {
            room.programs.forEach((program, index) => {
              listIds.push(room.roomId + index)
            });
          } else {
            room.surgeonOpenings.forEach((surgeonOpening, index) => {
              listIds.push(room.roomId + index)
            });
          }
        } else {
          listIds.push(room.roomId + "AM")
          listIds.push(room.roomId + "M")
        }
      }
    });

    return listIds
  }

  generateId() {
    const ObjectId = (
      m = Math,
      d = Date,
      h = 16,
      s = (s) => m.floor(s).toString(h)
    ) =>
      s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));
    return ObjectId();
  }

  splitSurgeonOpenings(room: roomFinalData, isMultipleSO: boolean, mode: string) {
    if (isMultipleSO) {
      room.isSplit = true;
      room.modified = true;
      room.splitByVacation = mode === "vacation" ? true : false
    } else {
      const selectedHospital = getFirstHospitalSelectedData(this.currentUser, this.userService.getSelectedHospitals())
      const middleTime = new Date(selectedHospital.middleTime);

      let newSO = {
        ...room.surgeonOpenings[0]
      }
      newSO._id = this.generateId();
      newSO._originalId = room.surgeonOpenings[0]._id
      let sDate = new Date(room.surgeonOpenings[0].startTime)
      sDate.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())
      newSO.startTime = sDate;

      let eDate = new Date(room.surgeonOpenings[0].startTime)
      eDate.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())
      room.surgeonOpenings[0].endTime = eDate;
      room.surgeonOpenings.push(newSO)

      room.isSplit = true;
      room.modified = true;
      room.splitByVacation = mode === "vacation" ? true : false
    }
  }

  splitBufferPrograms(room: roomFinalData, isMultipleBP: boolean, mode: string) {
    if (isMultipleBP) {
      room.isSplit = true;
      room.modified = true;
      room.splitByVacation = mode === "vacation" ? true : false
    } else {
      const selectedHospital = getFirstHospitalSelectedData(this.currentUser, this.userService.getSelectedHospitals())
      const middleTime = new Date(selectedHospital.middleTime);

      let newBP = {
        ...room.programs[0]
      }
      newBP._id = this.generateId();
      let pDate = new Date(room.programs[0].startTime)
      pDate.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())
      newBP.startTime = pDate
      room.programs[0].endTime = pDate
      room.programs.push(newBP)

      let sDate = new Date(room.surgeonOpenings[0].startTime)
      sDate.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())
      const tmpSO = JSON.parse(JSON.stringify(room.surgeonOpenings[0]))
      tmpSO.startTime = sDate;
      room.surgeonOpenings[0].endTime = sDate
      room.surgeonOpenings.push(tmpSO)

      room.isSplit = true;
      room.modified = true;
      room.splitByVacation = mode === "vacation" ? true : false
    }
  }

  splitConsultExtra(data: consultationExtracliniqueData) {
    data.dayPrograms = []
    let newDataMorning = JSON.parse(JSON.stringify(data))
    newDataMorning.title = newDataMorning.specialty.name + ' | Equipe Matin'
    newDataMorning.isMorning = true
    newDataMorning.isSplit = true
    newDataMorning.modified = true
    newDataMorning.team = this.getConsultExtracliniqueTeam(newDataMorning.morningPrograms)

    let newDataAfternoon = JSON.parse(JSON.stringify(data))
    newDataAfternoon.title = newDataMorning.specialty.name + ' | Equipe Après-midi'
    newDataAfternoon.isMorning = false
    newDataAfternoon.isSplit = true
    newDataAfternoon.modified = true
    newDataAfternoon.team = this.getConsultExtracliniqueTeam(newDataMorning.afternoonPrograms)

    if (data.specialty.name === "Extraclinique") {
      this.extracliniqueSpecialty[0].isSplit = true;
      this.extracliniqueSpecialty.push(newDataMorning)
      this.extracliniqueSpecialty.push(newDataAfternoon)
    } else {
      const dataDay = this.consultationSpecialties.find((data2) => data2 === data)
      dataDay.isSplit = true;
      this.consultationSpecialties.push(newDataMorning)
      this.consultationSpecialties.push(newDataAfternoon)

      this.consultationSpecialties.sort((a, b) => {
        if ((a.specialty.priority - b.specialty.priority) === 0 || a.specialty.name === 'Anesthésie-réanimation' && b.specialty.name === 'Anesthésie-réanimation') {
          if (a.title.includes("Journée") && b.title.includes("Après-midi")) {
            return -1
          }
  
          if (b.title.includes("Journée") && a.title.includes("Après-midi")) {
            return 1
          }
  
          if (a.title.includes("Après-midi") && b.title.includes("Matin")) {
            return -1
          }
  
          if (b.title.includes("Après-midi") && a.title.includes("Matin")) {
            return -1
          }
          
          if (a.title.includes("Journée") && b.title.includes("Matin")) {
            return -1
          }
  
          if (b.title.includes("Journée") && a.title.includes("Matin")) {
            return 1
          }
        }

        if (a.specialty.name === 'Anesthésie-réanimation') {
          return -1;
        }

        if (b.specialty.name === 'Anesthésie-réanimation') {
          return 1;
        }

        return a.specialty.priority - b.specialty.priority;
      });
    }

    this.roomsService.refreshAvailableProfilesSmartPlanning.next(null)
  }

  subscribeSplitRoom() {
    if (this.getRoomServiceSplitRoomSubscription) {
      this.getRoomServiceSplitRoomSubscription.unsubscribe();
    }

    this.getRoomServiceSplitRoomSubscription = this.roomsService.splitRoomSmartPlanning.subscribe((data) => {
      this.isLoadingRooms = true;
      if (data.isBloc) {
        const room = this.rooms.find((room) => room.roomId && String(room.roomId) === data.id)
        const originalRoom = JSON.parse(JSON.stringify(room))

        if (room.isBP) {
          if (room.programs && room.programs.length > 1) {
            this.splitBufferPrograms(room, true, data.mode)
          } else {
            this.splitBufferPrograms(room, false, data.mode)
          }
        } else {
          if (room.surgeonOpenings && room.surgeonOpenings.length > 1) {
            this.splitSurgeonOpenings(room, true, data.mode);
          } else {
            this.splitSurgeonOpenings(room, false, data.mode);
          }
        }
        this.unwindFinalDataSplit(false, room);
        this.setFillingSurgeons();
        this.listIdsBloc = this.getDragDropListIdsBloc();
        setTimeout(()=>{
          this.roomsService.updateEmptyRoomOnSplitSmartPlanning.next({isBloc: true, data: originalRoom})
        })
      } else {
        const consultExtra: consultationExtracliniqueData[] = [].concat(this.consultationSpecialties, this.extracliniqueSpecialty)
        const consultExtraData = consultExtra.find((data2) => String(data2.specialty._id) === data.id)

        this.splitConsultExtra(consultExtraData)
        this.listIdsConsultationExtraclinique = this.getDragDropListIdsConsultationExtraclinique();
      }
      this.isLoadingRooms = false;
    })
  }

  getAllNurses() {
    return new Promise<void>((resolve, reject) => {
      if (this.allNursesSubscription) {
        this.allNursesSubscription.unsubscribe();
      }

      this.allNursesSubscription = this.profileService.getAllAvailableNurses(this.date).subscribe((nurses) => {

        this.allNurses = nurses;
        this.reasonMapColors.clear();
        this.allNurses.forEach((nurse) => {
          if (nurse.colorReasonMap) {
            this.reasonMapColors.set(nurse._id, nurse.colorReasonMap);
          }
        })

        resolve();
      }, (error) => {
        this.errorService.handleError(error);
        reject();
      })
    })
  }

  getAnesthetistsCalendars() {
    const date = new Date(moment(this.day).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0);
    const from = moment.utc(date).toDate();
    from.setUTCHours(5, 0, 0, 0);
    const to = moment.utc(date).toDate();
    to.setUTCHours(23, 0, 0, 0);
    const period = {
      from: from.toISOString(),
      to: to.toISOString(),
    };

    return new Promise<void>((resolve, reject) => {
      if (this.availableAnesthetistsSubscription) {
        this.availableAnesthetistsSubscription.unsubscribe();
      }

      this.availableAnesthetistsSubscription = this.profileService.getProfilesWithTimes(ANESTHETIST, null, period).subscribe((calendars: Calendar[]) => {
        this.anesthetistsCalendars = calendars

        this.anesthetistsCalendars = this.anesthetistsCalendars.filter((calendar) => !isAbsent(calendar))

        if (!this.hospitalHaveAnesthDetailsOption) {
          this.anesthetistsCalendars = this.anesthetistsCalendars.filter((calendar) => !isConsultation(calendar))
        }

        // Sort calendars
        this.anesthetistsCalendars = this.anesthetistsCalendars.sort(((a, b) => {
          if ((isGuard(a) && isGuard(b)) || (!isGuard(a) && !isGuard(b))) {
            let positionOrder = a.profile.position.localeCompare(b.profile.position);
            if (positionOrder !== 0) {
              return positionOrder;
            } else {
              // Same position
              let residencyOrder = a.profile.residency.localeCompare(b.profile.residency);
              if (residencyOrder !== 0) {
                return residencyOrder;
              } else {
                // Same residency
                if (a.profile.position === ANESTHETIST) {
                  if (this.hospitalHaveAnesthDetailsOption) {
                    // Filter by seniority
                    if (a.profile.seniority !== b.profile.seniority) {
                      switch (a.profile.seniority) {
                        case 'Senior':
                          return -1;
                        case 'Junior':
                          if (b.profile.seniority === 'Senior') {
                            return 1;
                          } else {
                            return -1;
                          }
                        case 'Interne':
                          return 1;
                      }
                    }
                  }
                }
    
                // Filter by name
                let firstNameOrder = a.profile.firstName.localeCompare(b.profile.firstName);
    
                if (firstNameOrder !== 0) {
                  return firstNameOrder;
                } else {
                  return a.profile.lastName.localeCompare(b.profile.lastName);
                }
              }
            }
          } else {
            return (isGuard(a) && !isGuard(b)) ? -1 : 1;
          }
        }));
        resolve();
      }, (error) => {
        this.errorService.handleError(error);
        reject();
      })
    })
  }

  getInterventions() {
    return new Promise<void>((resolve, reject) => {
      if (this.interventionsSubscription) {
        this.interventionsSubscription.unsubscribe();
      }

      const date = moment(this.day).format("YYYY-MM-DD").toString();
      this.interventionsSubscription = this.roomsService.getHospitalRoomsDayDoctorAgendas(date).subscribe((data) => {
        this.interventions = data;
        resolve();
      }, (error) => {
        this.errorService.handleError(error);
        reject();
      })
    })
  }

  getDailyNeeds() {
    return new Promise<void>((resolve, reject) => {
      if (this.dailyNeedsSubscription) {
        this.dailyNeedsSubscription.unsubscribe();
      }

      const date = moment(this.day).format("YYYY-MM-DD").toString();
      this.dailyNeedsSubscription = this.roomsService.getAnesthDailyNeeds(date).subscribe((data) => {
        this.consultationNeeds = data.filter((elt) => elt.type === typesDailyNeeds.CONSULTATION);
        this.blocNeeds = data.filter((elt) => elt.type === typesDailyNeeds.BLOC);
        resolve();
      }, (error) => {
        this.errorService.handleError(error);
        reject();
      })
    })
  }

  getDayAverages(): void {
    if (this.getDayAveragesSubscription) {
      this.getDayAveragesSubscription.unsubscribe();
    }
    this.isEffectiveNeedsLoading = true;
    this.getDayAveragesSubscription = this.profileService.getAverages(this.date, this.date, false).subscribe((result) => {
      this.effectif = result.effectif ? result.effectif : [];
      this.effectifPerResidency = result.residency ? result.residency : [];
      this.needs = result.nurseNeeds ? result.nurseNeeds : [];
      this.fixEffectif();
      this.isEffectiveNeedsLoading = false;
    }, error => this.errorService.handleError(error));
  }

  fixEffectif() {
    const effectifArray: any[] = [];
    for (const needItem of this.needs) {
      const eItem = this.effectif.find((effectifItem) => effectifItem.date === needItem.date);
      const item: any = {};
      item.morningNurseEffectif = this.getValueOrZero(eItem && eItem.morningNurseEffectif);
      item.afternoonNurseEffectif = this.getValueOrZero(eItem && eItem.afternoonNurseEffectif);
      item.morningNurseAs = this.getValueOrZero(eItem && eItem.morningNurseAs);
      item.afternoonNurseAs = this.getValueOrZero(eItem && eItem.afternoonNurseAs);
      item.dayNurseEffectif = this.getValueOrZero(eItem && eItem.dayNurseEffectif);
      item.date = this.getValueOrZero(needItem.date);
      item.day = this.getValueOrZero(needItem.day + 1);
      
      effectifArray.push(item);
    }
    this.effectif = effectifArray;
  }

  getValueOrZero(value: number) {
    return ((value) ? value : 0);
  }

  // averages anesth

  getAnesthDayAverages(): void {
    if (this.getDayAveragesAnesthSubscription) {
      this.getDayAveragesAnesthSubscription.unsubscribe();
    }
    this.isEffectiveNeedsAnesthLoading = true;
    this.getDayAveragesAnesthSubscription = this.profileService.getAverages(this.date, this.date, false).subscribe((result) => {
      this.effectifPerResidencyAnesth = result.effectif ? result.effectif : [];
      this.residency = result.residency ? result.residency : [];
      
      this.setNeedsEffectifs(result.anesthNeeds, this.formatEffectif(result.effectif, result.anesthNeeds))
      this.isEffectiveNeedsAnesthLoading = false;
    }, error => this.errorService.handleError(error));
  }

  formatEffectif(effectifs, needs) {
    const effectifArray: any[] = [];
    for (const needItem of needs) {
      const eItem = effectifs.find((effectifItem) => effectifItem.date === moment(needItem.date).format("YYYY-MM-DD"));
      effectifArray.push(eItem);
    }
    return effectifArray;
  }

  setNeedsEffectifs(needs: any[], effectifs: any[]) {
    if (this.userService.isIadRes()) {
      this.needsEffectifs = needs.map((_, i) => {
        const effectif = effectifs[i] ? effectifs[i].iade : 0;
        const need = needs[i] ? needs[i].iadeNeeds : 0;
        return this.getEffectifNeeds(effectif, need)
      })
    } else {
      this.needsEffectifsBlocAnesth = needs.map((_, i) => {
        let effectif, need;

        if (this.hospitalHaveAnesthDetailsOption && this.userService.isInternResponsible()) {
          effectif = effectifs[i] && effectifs[i].detailsBloc && effectifs[i].detailsConsultation ? effectifs[i].detailsBloc.intern.titulaire + effectifs[i].detailsBloc.intern.vacataire
                    + effectifs[i].detailsConsultation.intern.titulaire + effectifs[i].detailsConsultation.intern.vacataire : 0;
          need = needs[i] ? needs[i].bloc.internNeeds + needs[i].consultation.internNeeds : 0;
        } else {
          effectif = effectifs[i] ? effectifs[i].anesthBloc : 0;
          need = needs[i] ? needs[i].blocAnesthNeeds : 0;
        }
        return this.getEffectifNeeds(effectif, need)
      })

      this.needsEffectifsBlocIade = needs.map((_, i) => {
        const effectif = effectifs[i] ? effectifs[i].iadeBloc : 0;
        const need = needs[i] ? needs[i].blocIadeNeeds : 0;
        return this.getEffectifNeeds(effectif, need)
      })
      
      const currentUser: any = this.userService.getCurrentUser()
      const userHospitals = currentUser.profile.hospitals
      const selectedHospitals = this.userService.getSelectedHospitals()
      const hospital = userHospitals.find((hospital) => String(hospital._id) === String(selectedHospitals[0]))
      this.hospitalType = hospital.type;

      if (this.hospitalType === "hopital privé") {
        this.needsEffectifsBlocGlobal = this.needsEffectifsBlocAnesth.map((anesth, i) => {
          return {
            effectifs: anesth.effectifs + (!this.userService.isInternResponsible() ? this.needsEffectifsBlocIade[i].effectifs : 0),
            needs: anesth.needs + (!this.userService.isInternResponsible() ? this.needsEffectifsBlocIade[i].needs : 0),
            difference: anesth.difference + (!this.userService.isInternResponsible() ? this.needsEffectifsBlocIade[i].difference : 0)
          };
        })
      }

      this.needsEffectifsConsultation = needs.map((_, i) => {
        let effectif, need;

          effectif = effectifs[i] ? effectifs[i].totalConsultation : 0;
          need = needs[i] ? needs[i].consultationNeeds : 0;
        return this.getEffectifNeeds(effectif, need)
      })

      this.needsEffectifsInterns = needs.map((_, i) => {
        let effectif, need;
        
        if (this.hospitalHaveAnesthDetailsOption && this.userService.isInternResponsible()) {
          effectif = effectifs[i] && effectifs[i].detailsBloc && effectifs[i].detailsConsultation ? effectifs[i].detailsBloc.intern.titulaire + effectifs[i].detailsBloc.intern.vacataire
                    + effectifs[i].detailsConsultation.intern.titulaire + effectifs[i].detailsConsultation.intern.vacataire : 0;
          need = needs[i] ? needs[i].bloc.internNeeds + needs[i].consultation.internNeeds : 0;
        } else {
          effectif = 0;
          need = 0;
        }
        return this.getEffectifNeeds(effectif, need)
      })
    }
  }

  getEffectifNeeds(effectif, need) {
    return {
      effectifs: effectif,
      needs: need,
      difference: effectif - need
    }
  }

  async reload(init: boolean = false) {
    this.isLoading = true;
    const promises = []
    if (init) {
      this.anesthetistsCalendars = undefined;
    }
    this.programCommentary = null;
    this.comment = "";

    promises.push(this.getSOwithBP())
    promises.push(this.getRolesData())
    promises.push(this.getParamedicalData())
    promises.push(this.getFormationsData())
    promises.push(this.getProgramCommentary())
    if (init) {
      promises.push(this.getUrgenceProfile());
      promises.push(this.getAllNurses())
      promises.push(this.getDailyNeeds())
      this.getInterventions();
    }
    if (!this.isLowLevel && !this.isRespIade && !(this.isCadreDeBloc && this.levelOfAccess === 4)) {
      this.getAnesthsRankings();
    }
    if (!this.isLowLevel) {
      this.getNursesRankings();
    }
    this.showRoles = this.hospitalService.checkIfHospitalAllowsShowingRoles();

    if (this.isCadreDeBloc)
      this.getDayAverages();

    if (!(this.isCadreDeBloc && this.levelOfAccess == 4))
      this.getAnesthDayAverages();

    await Promise.all(promises)
    if (init) {
      this.roomsService.loadedSmartPlanning.next();
    }
    this.isLoading = false;
  }

  subscribeReload() {
    if (this.getRoomServiceReloadSubscription) {
      this.getRoomServiceReloadSubscription.unsubscribe();
    }

    this.getRoomServiceReloadSubscription = this.roomsService.reloadSmartPlanning.subscribe(async () => {
      await this.reload(true);
    })
  }

  @HostListener("window:beforeunload")
  saveDate() {
    this.utilisService.saveDate(this, this.day);
  }

  async ngOnInit() {
    await this.reload(true);
  }

  cleanAllRooms() {
    this.isLoading = true;
    this.bufferProgramService
    .cleanAllRooms(this.userService.getSelectedHospitals()[0], this.formatDate(this.day))
    .subscribe(
      async (res) => {
        this.reload();
      }, (error) => {
        this.errorService.handleError(error);
      }
    );
  }

  calculateEffectif(dayPrograms: any[], morningPrograms: any[], afternoonPrograms: any[]): number {
    let response = 0;

    dayPrograms.forEach((program) => {
      switch (program.anesth.seniority) {
        case 'Senior':
          response += WEIGHT.SENIOR;
          break;
        case 'Junior':
          response += WEIGHT.JUNIOR;
          break;
        case 'Interne':
          response += WEIGHT.INTERN;
          break;
        default:
          response += 1;
          break;
      }
    });

    morningPrograms.forEach((program) => {
      switch (program.anesth.seniority) {
        case 'Senior':
          response += WEIGHT.SENIOR / 2;
          break;
        case 'Junior':
          response += WEIGHT.JUNIOR / 2;
          break;
        case 'Interne':
          response += WEIGHT.INTERN / 2;
          break;
        default:
          response += 0.5;
          break;
      }
    });

    afternoonPrograms.forEach((program) => {
      switch (program.anesth.seniority) {
        case 'Senior':
          response += WEIGHT.SENIOR / 2;
          break;
        case 'Junior':
          response += WEIGHT.JUNIOR / 2;
          break;
        case 'Interne':
          response += WEIGHT.INTERN / 2;
          break;
        default:
          response += 0.5;
          break;
      }
    });

    return response;
  }

  formatPrograms(programs: BufferProgram[]): consultationExtracliniquePrograms[] {
    return programs.map((bufferProgram) => {
       return { anesth: bufferProgram.anesthesists[0], currentStartTime: new Date(bufferProgram.startTime), currentEndTime: new Date(bufferProgram.endTime) } 
      })
  }

  getFormattedMorningPrograms(programs: BufferProgram[]): consultationExtracliniquePrograms[] {
    const response = this.utilisService.getMorningPrograms({ programs });
    return this.formatPrograms(response);
  }

  getFormattedAfternoonPrograms(programs: BufferProgram[]): consultationExtracliniquePrograms[] {
    const response = this.utilisService.getAfternoonPrograms({ programs });
    return this.formatPrograms(response);
  }

  getFormattedDayPrograms(programs: BufferProgram[]): consultationExtracliniquePrograms[] {
    const response = this.utilisService.getDayPrograms({ programs });
    return this.formatPrograms(response);
  }

  getConsultationNeedsBySpecialtyId(specialtyId: string): number {
    const found = this.consultationNeeds.find((elt) => elt.specialty && elt.specialty._id === specialtyId);

    const response = found ? (found.seniorNeeds * WEIGHT.SENIOR) + (found.juniorNeeds * WEIGHT.JUNIOR) + (found.internNeeds * WEIGHT.INTERN) : 0;

    return response;
  }

  getConsultExtracliniqueTeam(programs: consultationExtracliniquePrograms[]) {
    const team: profileCard[] = []
    programs.forEach(program => {
      const originalProfile = this.anesthetistsCalendars.find((anesthetist2) => String(anesthetist2.profile._id) === String(program.anesth._id))
      team.push({
        profile: program.anesth,
        originalTime: {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()},
        time: {startTime: moment.utc(program.currentStartTime).toDate(), endTime: moment.utc(program.currentEndTime).toDate()},
      })
    });

    return team;
  }

  setConsultationExtraclinique(consultationSpecialties: Specialty[], extracliniqueSpecialties: Specialty[]) {
    let consultationAndExtracliniqueBufferPrograms1 = this.roomsBufferPrograms.find((obj) => obj._id == null);
    let consultationAndExtracliniqueBufferPrograms = consultationAndExtracliniqueBufferPrograms1 ? consultationAndExtracliniqueBufferPrograms1.programs : [];
    consultationAndExtracliniqueBufferPrograms = consultationAndExtracliniqueBufferPrograms.filter((program) => program.anesthesists.length === 1);
    const consultationsBufferPrograms = consultationAndExtracliniqueBufferPrograms.filter((program) => program.type === 'consultation');
    
    
    // updatedBy is the always the same for all the objects of the day
    const tmp = consultationAndExtracliniqueBufferPrograms.find((elt) => elt.updatedBy && elt.updatedAt);
    const updatedBy = tmp ? tmp.updatedBy : null;
    const updatedAt = tmp ? tmp.updatedAt : null;
    this.extracliniqueSpecialty = [];
    
    // the field anesthesists in bufferprogram contain only one anesth if the bufferprogram is for consultation or extraclinique
    extracliniqueSpecialties.forEach(extracliniqueSpecialty => {
      if (!this.extracliniqueSpecialty.find((extracliniqueSpecialty_) => String(extracliniqueSpecialty_.specialty._id) === String(extracliniqueSpecialty._id))) {
        const extracliniqueBufferPrograms = consultationAndExtracliniqueBufferPrograms.filter((program) => program.type === 'bloc' && String(program.specialty) === extracliniqueSpecialty._id);
        
        const extracliniqueDayPrograms = this.getFormattedDayPrograms(extracliniqueBufferPrograms);
        const extracliniqueMorningPrograms = this.getFormattedMorningPrograms(extracliniqueBufferPrograms);
        const extracliniqueAfternoonPrograms = this.getFormattedAfternoonPrograms(extracliniqueBufferPrograms);
        
        this.extracliniqueSpecialty.push({
          specialty: extracliniqueSpecialty,
          title: extracliniqueSpecialty.name,
          isMorning: false,
          isSplit: false,
          dayPrograms: extracliniqueDayPrograms,
          morningPrograms: extracliniqueMorningPrograms,
          afternoonPrograms: extracliniqueAfternoonPrograms,
          effectif: this.calculateEffectif(extracliniqueDayPrograms, extracliniqueMorningPrograms, extracliniqueAfternoonPrograms),
          updatedAt: updatedAt,
          updatedBy: updatedBy,
          modified: false,
          team: [].concat(this.getConsultExtracliniqueTeam(extracliniqueDayPrograms),
                          this.getConsultExtracliniqueTeam(extracliniqueAfternoonPrograms),
                          this.getConsultExtracliniqueTeam(extracliniqueMorningPrograms))
        })
      }
    });


    this.consultationSpecialties = [];
    
    consultationSpecialties.forEach((spe) => {
      const specialtyBufferPrograms = consultationsBufferPrograms.filter((program) => String(program.specialty) === String(spe._id));

      // the field anesthesists in bufferprogram contain only one anesth if the bufferprogram is for consultation or extraclinique
      const dayPrograms = this.getFormattedDayPrograms(specialtyBufferPrograms);
      const morningPrograms = this.getFormattedMorningPrograms(specialtyBufferPrograms);
      const afternoonPrograms = this.getFormattedAfternoonPrograms(specialtyBufferPrograms);

      this.consultationSpecialties.push({
        specialty: spe,
        title: spe.name.length > this.maxConsultTitleLength ? spe.diminutif : spe.name,
        isMorning: false,
        isSplit: false,
        dayPrograms: dayPrograms,
        morningPrograms: morningPrograms,
        afternoonPrograms: afternoonPrograms,
        effectif: this.calculateEffectif(dayPrograms, morningPrograms, afternoonPrograms),
        needs: this.getConsultationNeedsBySpecialtyId(spe._id),
        updatedAt: updatedAt,
        updatedBy: updatedBy,
        modified: false,
        team: [].concat(this.getConsultExtracliniqueTeam(dayPrograms), this.getConsultExtracliniqueTeam(morningPrograms), this.getConsultExtracliniqueTeam(afternoonPrograms))
      });
    });
  }

  getSpecialties() {
    return new Promise<void>((resolve, reject) => {
      if (this.getSpecialtiesSubscription) {
        this.getSpecialtiesSubscription.unsubscribe();
      }
  
      this.getSpecialtiesSubscription = this.specialtyService.getAllSpecialties(true).subscribe((specialties) => {
        this.specialties = specialties;
        const extracliniqueSpecialties = this.specialties.filter((spe) => spe.name === 'Extraclinique');
        const consultationSpecialties = this.specialties
          .filter((spe) => spe.type.includes('consultation'))
          .sort((a, b) => {
            if (a.name === 'Anesthésie-réanimation') {
              return -1;
            }
  
            if (b.name === 'Anesthésie-réanimation') {
              return 1;
            }
  
            if (a.hospital === b.hospital) {
              return a.priority - b.priority;
            } else {
              const hospitalOrder = a.hospital.localeCompare(b.hospital);
            
              if (hospitalOrder === 0) {
                const priorityOrder = a.priority - b.priority;
                if (priorityOrder === 0) {
                  return a._id.localeCompare(b._id);
                } else {
                  return priorityOrder;
                }
              } else {
                return hospitalOrder;
              }
            }
          });
        this.setConsultationExtraclinique(consultationSpecialties, extracliniqueSpecialties)

        this.listIdsConsultationExtraclinique = this.getDragDropListIdsConsultationExtraclinique();
        resolve();
      }, (error) => {
        this.errorService.handleError(error);
        reject();
      })
    })
  }

  async getConsultExtraData() {
    await this.getSpecialties();

    this.isLoadingConsultExtra = false;
  }

  getParamedicalData() {
    if (this.getParamedicalsSubscription) {
      this.getParamedicalsSubscription.unsubscribe();
    }

    return new Promise<void>((resolve, reject) => {
      this.getParamedicalsSubscription = this.paramedicalService.getParamedicals()
      .subscribe((paramedicals) => {
        this.paramedicalsData = paramedicals
        resolve();
      }, (error) => {
        this.errorService.handleError(error);
        reject();
      })
    })
  }

  getFormationsData() {
    if (this.getFormationsSubscription) {
      this.getFormationsSubscription.unsubscribe();
    }

    return new Promise<void>((resolve, reject) => {
      this.getFormationsSubscription = this.paramedicalService.getFormations()
      .subscribe((formations) => {
        this.formationsData = formations
        resolve();
      }, (error) => {
        this.errorService.handleError(error);
        reject();
      })
    })
  }

  getRolesData() {
    if (this.getRolesSubscription) {
      this.getRolesSubscription.unsubscribe();
    }

    return new Promise<void>((resolve, reject) => {
      this.getRolesSubscription = this.paramedicalService.getRoles()
      .subscribe((roles) => {
        this.rolesData = roles
        this.onRolesReception.emit(this.rolesData);
        resolve();
      }, (error) => {
        this.errorService.handleError(error);
        reject();
      })
    })
  }

  sortPrograms(rooms: roomBufferPrograms[]) {
    for (let i = 0; i < rooms.length; i++) {
      if (rooms[i] && rooms[i].programs) {
        rooms[i].programs = rooms[i].programs.filter(program => program);
        rooms[i].programs.sort((a, b) => {
            let dateA = new Date(a.startTime).getUTCHours();
            let dateB = new Date(b.startTime).getUTCHours();
            return dateA - dateB;
          }
        );
      }
    }
    return rooms;
  }

  getSurgeons(roomSurgeonOpenings: roomWithSOAndSOH, typeAttribute: string) {
    return roomSurgeonOpenings[typeAttribute].map(
      (surgeonOpening: SO | SurgeonOpeningHistory) => surgeonOpening.surgeon
    );
  }

  isRoomWithoutSurgeonOpenings(roomSurgeonOpenings: roomWithSOAndSOH, typeAttribute: string) {
    return roomSurgeonOpenings[typeAttribute].length == 0;
  }

  async autoFilling() {
    const dialogRef = this.dialog.open(AutofillingComponent, {
      data: { date: this.date, isDayWeek: this.isDayWeek, rooms: this.mergedRooms, roleData: this.rolesData },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.toastService.infoToast("Le remplissage automatique a été fait");
        this.reload(false);
      }
    });
  }

  checkIfSplitByVacation(isBP: boolean, programs: BufferProgram[] | SO[], room: roomFinalData | consultationExtracliniqueData) {
    const morningPrograms: BufferProgram[] | SO[] = []
    const afternoonPrograms: BufferProgram[] | SO[] = []
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    const middleTime = new Date(hospital.middleTime)
    const middleTimeModified = new Date("1970-01-01");
    middleTimeModified.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())

    if (isBP) {
      (programs as BufferProgram[]).forEach(program => {
        const startTime = new Date("1970-01-01")
        startTime.setUTCHours(new Date(program.startTime).getUTCHours(), new Date(program.startTime).getUTCMinutes(), new Date(program.startTime).getUTCSeconds(), new Date(program.startTime).getUTCMilliseconds())
        const endTime = new Date("1970-01-01")
        endTime.setUTCHours(new Date(program.endTime).getUTCHours(), new Date(program.endTime).getUTCMinutes(), new Date(program.endTime).getUTCSeconds(), new Date(program.endTime).getUTCMilliseconds())
        
        if (endTime.getTime() <= (middleTimeModified.getTime() + 3600000)) {
          morningPrograms.push(program)
        } else {
          if (startTime.getTime() >= (middleTimeModified.getTime() - 3600000)) {
            afternoonPrograms.push(program)
          } else {
            const morningProgram = JSON.parse(JSON.stringify(program))
            morningProgram.endTime = new Date(morningProgram.endTime)
            morningProgram.endTime.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())
            const afternoonProgram = JSON.parse(JSON.stringify(program))
            afternoonProgram.startTime = new Date(afternoonProgram.startTime)
            afternoonProgram.startTime.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())

            morningPrograms.push(morningProgram)
            afternoonPrograms.push(afternoonProgram)
          }
        }
      });

      if (morningPrograms.length === 1 && afternoonPrograms.length === 1) {
        return false;
      }

      if (morningPrograms.length === 0 || afternoonPrograms.length === 0) {
        return true;
      }
  
      let sameMorningTeam: boolean = true;
      let sameAfternoonTeam: boolean = true;

      if (morningPrograms.length > 1) {
        const firstProgramTeam = this.getTeam(true, true, [morningPrograms[0]], true, false, room)
        morningPrograms.forEach((morningProgram: BufferProgram) => {
          const team = this.getTeam(true, true, [morningProgram], true, false, room)

          if (team.others.length === 0 && firstProgramTeam && firstProgramTeam.others.length !== 0) {
            sameMorningTeam = false;
          }

          team.others.forEach(card => {
            if (!firstProgramTeam.others.find((card2) => card2.role && card.role ? String(card2.profile._id) === String(card.profile._id) && String(card2.role._id) === String(card.role._id) : String(card2.profile._id) === String(card.profile._id))) {
              sameMorningTeam = false;
            }
          });
        });
      }
  
      if (afternoonPrograms.length > 1) {
        const firstProgramTeam = this.getTeam(true, true, [afternoonPrograms[0]], true, false, room)
        afternoonPrograms.forEach((afternoonProgram: BufferProgram) => {
          const team = this.getTeam(true, true, [afternoonProgram], true, false, room)

          if (team.others.length === 0 && firstProgramTeam && firstProgramTeam.others.length !== 0) {
            sameAfternoonTeam = false;
          }
          team.others.forEach(card => {
            if (!firstProgramTeam.others.find((card2) => card2.role && card.role ? String(card2.profile._id) === String(card.profile._id) && String(card2.role._id) === String(card.role._id) : String(card2.profile._id) === String(card.profile._id))) {
              sameAfternoonTeam = false;
            }
          });
        });
      }
      
      if ((!sameMorningTeam || !sameAfternoonTeam) && (morningPrograms.length > 1 || afternoonPrograms.length > 1)) {
        return true;
      }
    }

    return false;
  }

  getCorrespondingSO(surgeonOpenings: SO[], program: BufferProgram, isSplitMiddle: boolean = false) {
    for (let index = 0; index < surgeonOpenings.length; index++) {
      const surgeonOpening = surgeonOpenings[index];
      
      if (isSplitMiddle) {
        if (String(surgeonOpening.surgeon._id) === String(program.surgeon._id) &&
        this.formatCompareTime(new Date(surgeonOpening.startTime)) <= this.formatCompareTime(new Date(program.startTime)) &&
        this.formatCompareTime(new Date(surgeonOpening.endTime)) >= this.formatCompareTime(new Date(program.endTime))) {
          return surgeonOpening
        }
      } else {
        if (String(surgeonOpening.surgeon._id) === String(program.surgeon._id) &&
        this.formatCompareTime(new Date(program.startTime)) >= this.formatCompareTime(new Date(surgeonOpening.startTime))  &&
        this.formatCompareTime(new Date(program.endTime)) <= this.formatCompareTime(new Date(surgeonOpening.endTime))) {
          return surgeonOpening
        }
      }
    }
  }

  getNurseScore(newCard: profileCard, hospital: Hospital, openings: SO[], isSplit: boolean, splitByVacation: boolean): Promise<RoleLevel[]> {
    let vacIds = []

    if (!isSplit || (isSplit && !splitByVacation)) {
      vacIds = openings.map((elem) => elem._originalId ? elem._originalId : elem._id);
    } else {
      vacIds.push(openings[0]._originalId ? openings[0]._originalId : openings[0]._id)
    }
    
    return this.skillService.getNurseRating(newCard.profile._id, hospital._id, this.date, vacIds).toPromise();
  }

  splitByMorningAfternoon(isBP: boolean, room: roomFinalData, init: boolean = false) {
    const roomMorning: roomFinalData = {
      roomId: room.roomId,
      roomNumber: room.room.roomNumber + ' | M',
      room: JSON.parse(JSON.stringify(room.room)),
      isEmpty: room.isEmpty,
      modified: room.modified,
      isSplit: room.isSplit,
      programs: isBP ? [] : null,
      surgeonOpenings: [],
      isBP: room.isBP,
      originalSO: JSON.parse(JSON.stringify(room.originalSO)),
      team: null,
      splitByVacation: false
    };
    const roomAfternoon: roomFinalData = {
      roomId: room.roomId,
      roomNumber: room.room.roomNumber + ' | AM',
      room: JSON.parse(JSON.stringify(room.room)),
      isEmpty: room.isEmpty,
      modified: room.modified,
      isSplit: room.isSplit,
      programs: isBP ? [] : null,
      surgeonOpenings: [],
      isBP: room.isBP,
      originalSO: JSON.parse(JSON.stringify(room.originalSO)),
      team: null,
      splitByVacation: false
    }

    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    const middleTime = new Date(hospital.middleTime)
    const middleTimeModified = new Date("1970-01-01");
    middleTimeModified.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())

    if (isBP) {
      room.programs.forEach((program) => {
        const startTime = new Date("1970-01-01")
        startTime.setUTCHours(new Date(program.startTime).getUTCHours(), new Date(program.startTime).getUTCMinutes(), new Date(program.startTime).getUTCSeconds(), new Date(program.startTime).getUTCMilliseconds())
        const endTime = new Date("1970-01-01")
        endTime.setUTCHours(new Date(program.endTime).getUTCHours(), new Date(program.endTime).getUTCMinutes(), new Date(program.endTime).getUTCSeconds(), new Date(program.endTime).getUTCMilliseconds())

        if (endTime.getTime() <= (middleTimeModified.getTime() + 3600000)) {
          roomMorning.surgeonOpenings.push(this.getCorrespondingSO(room.surgeonOpenings, program, false))
          roomMorning.programs.push(JSON.parse(JSON.stringify(program)))
        } else {
          if (startTime.getTime() >= (middleTimeModified.getTime() - 3600000)) {
            roomAfternoon.surgeonOpenings.push(this.getCorrespondingSO(room.surgeonOpenings, program, false))
            roomAfternoon.programs.push(JSON.parse(JSON.stringify(program)))
          } else {
            const morningProgram = JSON.parse(JSON.stringify(program))
            morningProgram.endTime = new Date(morningProgram.endTime)
            morningProgram.endTime.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())
            const afternoonProgram = JSON.parse(JSON.stringify(program))
            afternoonProgram.startTime = new Date(afternoonProgram.startTime)
            afternoonProgram.startTime.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())
            
            roomMorning.surgeonOpenings.push(this.getCorrespondingSO(room.surgeonOpenings, morningProgram, true))
            roomMorning.programs.push(JSON.parse(JSON.stringify(morningProgram)))
            roomAfternoon.surgeonOpenings.push(this.getCorrespondingSO(room.surgeonOpenings, afternoonProgram, true))
            roomAfternoon.programs.push(JSON.parse(JSON.stringify(afternoonProgram)))
          }
        }
      })

      roomMorning.surgeonOpenings = roomMorning.surgeonOpenings.filter((so) => so !== null)
      roomAfternoon.surgeonOpenings = roomAfternoon.surgeonOpenings.filter((so) => so !== null)

      roomMorning.team = this.getTeam(true, true, roomMorning.programs, false, init, roomMorning)
      roomAfternoon.team = this.getTeam(true, true, roomAfternoon.programs, false, init, roomAfternoon)
    } else {
      room.surgeonOpenings.forEach((surgeonOpening) => {
        const startTime = new Date("1970-01-01")
        startTime.setUTCHours(new Date(surgeonOpening.startTime).getUTCHours(), new Date(surgeonOpening.startTime).getUTCMinutes(), new Date(surgeonOpening.startTime).getUTCSeconds(), new Date(surgeonOpening.startTime).getUTCMilliseconds())
        const endTime = new Date("1970-01-01")
        endTime.setUTCHours(new Date(surgeonOpening.endTime).getUTCHours(), new Date(surgeonOpening.endTime).getUTCMinutes(), new Date(surgeonOpening.endTime).getUTCSeconds(), new Date(surgeonOpening.endTime).getUTCMilliseconds())

        if (endTime.getTime() <= (middleTimeModified.getTime() + 3600000)) {
          roomMorning.surgeonOpenings.push(JSON.parse(JSON.stringify(surgeonOpening)))
        } else {
          if (startTime.getTime() >= (middleTimeModified.getTime() - 3600000)) {
            roomAfternoon.surgeonOpenings.push(JSON.parse(JSON.stringify(surgeonOpening)))
          } else {
            const morningSO = JSON.parse(JSON.stringify(surgeonOpening))
            morningSO.endTime = new Date(morningSO.endTime)
            morningSO.endTime.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())
            const afternoonSO = JSON.parse(JSON.stringify(surgeonOpening))
            afternoonSO.startTime = new Date(morningSO.startTime)
            afternoonSO.startTime.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())

            roomMorning.surgeonOpenings.push(JSON.parse(JSON.stringify(morningSO)))
            roomAfternoon.surgeonOpenings.push(JSON.parse(JSON.stringify(afternoonSO)))
          }
        }
      })

      roomMorning.surgeonOpenings = roomMorning.surgeonOpenings.filter((so) => so !== null)
      roomAfternoon.surgeonOpenings = roomAfternoon.surgeonOpenings.filter((so) => so !== null)

      roomMorning.team = this.getTeam(false, true, roomMorning.surgeonOpenings, false, init, roomMorning)
      roomAfternoon.team = this.getTeam(false, true, roomAfternoon.surgeonOpenings, false, init, roomAfternoon)
    }

    return {morningPrograms: roomMorning, afternoonPrograms: roomAfternoon}
  }

  mergeSameTeams(programs: BufferProgram[] | SO[]) {
    const programsFinal = []

    for (let index = 0; index < programs.length; index++) {
      const program = programs[index];
      let continueLoop = true
      const newProgram = JSON.parse(JSON.stringify(program))
      newProgram.mergedPrograms = [JSON.parse(JSON.stringify(program))]
      
      if (index !== programs.length - 1 && (index + 1) !== programs.length - 1) {
        let maxIteration = 30
        let nbIterations = 0

        while(continueLoop && index !== programs.length - 1 && (index + 1) !== programs.length - 1 && (nbIterations < maxIteration)) {
          const programN1 = programs[index + 1]
          let sameTeam = true;

          if (this.formatCompareTime(new Date(programs[index].endTime)) !== this.formatCompareTime(new Date(programN1.startTime))) { //Compare program times
            continueLoop = false;
          } else { // Compare program teams
            programN1.team.others.forEach(card => {
              if (!program.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
                sameTeam = false;
                continueLoop = false;
              }
            });
  
            programN1.team.surgeons.filter((surgeon) => !surgeon.isFillingSurgeon).forEach(card => {
              if (!program.team.surgeons.filter((surgeon) => !surgeon.isFillingSurgeon).find((card2) => String(card2.profile._id) === String(card.profile._id))) {
                sameTeam = false;
                continueLoop = false;
              }
            });

            if (sameTeam) {
              newProgram.mergedPrograms.push(programN1);
              index++
              newProgram.endTime = programN1.endTime;
              newProgram.team.surgeons.filter((surgeon) => !surgeon.isFillingSurgeon)[0].time.endTime = programN1.endTime;
            }
          }
          nbIterations++
        }
      }

      programsFinal.push(newProgram)
    }

    return programsFinal;
  }

  setTeamOrder(room?: roomFinalData | BufferProgram | SO) {
    const data = room
    const anesths = data.team.others.filter((card) => card.profile.position === "Anesthésiste")
    const iades = data.team.others.filter((card) => IADE.includes(card.profile.position))
    const team = data.team.others.filter((card) => NURSE_TYPES.includes(card.profile.position))
  
    data.team.others = anesths.concat(iades, team)
  }

  setCardTime(surgeonOpenings: SO[], splitByVacation: boolean) {
    let time = { startTime: moment.utc(surgeonOpenings[0].startTime).toDate(), endTime: moment.utc(surgeonOpenings[0].endTime).toDate() }

    if (!splitByVacation) {
      surgeonOpenings.forEach(surgeonOpening => {
        let startTime = moment.utc(surgeonOpening.startTime).toDate();
        let endTime = moment.utc(surgeonOpening.endTime).toDate();

        if (startTime.getTime() < new Date(time.startTime).getTime()) {
          time.startTime = moment.utc(startTime).toDate()
        }

        if (endTime.getTime() > new Date(time.endTime).getTime()) {
          time.endTime = moment.utc(endTime).toDate()
        }
      });
    }

    return time;
  }

  unwindFinalDataSplit(init: boolean = false, roomTmp?: roomFinalData) {
    let rooms = [...this.rooms.filter((room) => !room.isSplit)]
    const splitRooms = [...this.rooms.filter((room) => room.isSplit)]
    const alreadySplit = []
    for (let index = 0; index < splitRooms.length; index++) {
      const room = splitRooms[index];
      if (roomTmp && room !== roomTmp) {
        alreadySplit.push(room)
        continue;
      }
      if (this.rooms.find((room2) => String(room2.roomId) === String(room.roomId) && String(room.roomNumber) !== String(room2.roomNumber))) {
        alreadySplit.push(room)
      } else {
        if (room.isBP) {
          if (init) {
            const splitByVacation = this.checkIfSplitByVacation(true, room.programs, room)
            room.splitByVacation = splitByVacation
            if (!splitByVacation) {
              const data = this.splitByMorningAfternoon(true, room, init);
              const newRoom = JSON.parse(JSON.stringify(room));
              newRoom.morningPrograms = JSON.parse(JSON.stringify(data.morningPrograms))
              newRoom.afternoonPrograms = JSON.parse(JSON.stringify(data.afternoonPrograms))
              newRoom.team = {surgeons: [], others: []}
              newRoom.team.surgeons = newRoom.team.surgeons.concat(newRoom.morningPrograms.team.surgeons)
              newRoom.team.surgeons = newRoom.team.surgeons.concat(newRoom.afternoonPrograms.team.surgeons)
              newRoom.team.others = newRoom.team.others.concat(newRoom.morningPrograms.team.others)
              newRoom.team.others = newRoom.team.others.concat(newRoom.afternoonPrograms.team.others)
              
              // Remove duplicate anesth
              newRoom.team.others.forEach(card => {
                if (card.profile.position === "Anesthésiste") {
                  if (newRoom.team.others.find((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2)) {
                    newRoom.team.others.splice(newRoom.team.others.findIndex((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2), 1)
                  }
                }
              });
              this.setTeamOrder(newRoom)
              newRoom.isSplit = true;
              newRoom.splitByVacation = false;
              rooms.push(newRoom)
            } else {
              room.team = {surgeons: [], others: []}
              room.programs.forEach(program => {
                const team = this.getTeam(room.isBP, true, [program], room.splitByVacation, true, room)
                program.team = team
                room.team.surgeons = room.team.surgeons.concat(team.surgeons)
                room.team.others = room.team.others.concat(team.others)
              });
              // Remove duplicate anesth
              room.team.others.forEach(card => {
                if (card.profile.position === "Anesthésiste") {
                  if (room.team.others.find((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2)) {
                    room.team.others.splice(room.team.others.findIndex((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2), 1)
                  }
                }
              });
              room.programs = this.mergeSameTeams(room.programs)
              this.setTeamOrder(room)
              rooms.push(room)
            }
          } else {
            if (!room.splitByVacation) {
              const data = this.splitByMorningAfternoon(true, room);
              const newRoom = JSON.parse(JSON.stringify(room));
              newRoom.morningPrograms = JSON.parse(JSON.stringify(data.morningPrograms))
              newRoom.afternoonPrograms = JSON.parse(JSON.stringify(data.afternoonPrograms))
              newRoom.team = {surgeons: [], others: []}
              newRoom.team.surgeons = newRoom.team.surgeons.concat(newRoom.morningPrograms.team.surgeons)
              newRoom.team.surgeons = newRoom.team.surgeons.concat(newRoom.afternoonPrograms.team.surgeons)
              newRoom.team.others = []

              room.team.others.forEach((card: profileCard) => {
                const newCard = cloneWithoutPromise(card)
                newCard.originalTime = card.originalTime
                newRoom.team.others.push(newCard)
              });

              newRoom.morningPrograms.team.others = []
              room.team.others.forEach((card: profileCard) => {
                const newCard = cloneWithoutPromise(card)
                newCard.originalTime = card.originalTime
                newRoom.morningPrograms.team.others.push(newCard)
              });
              
              newRoom.morningPrograms.team.others.forEach(card => {
                card.scorePromise = this.getNurseScore(card,
                  room.room.hospital,
                  newRoom.morningPrograms.surgeonOpenings, true, true);
                card.time = this.setCardTime(newRoom.morningPrograms.surgeonOpenings, false)
              });
              
              newRoom.afternoonPrograms.team.others = []
              room.team.others.forEach((card: profileCard) => {
                const newCard = cloneWithoutPromise(card)
                newCard.originalTime = card.originalTime
                newRoom.afternoonPrograms.team.others.push(newCard)
              });
              newRoom.afternoonPrograms.team.others.forEach(card => {
                card.scorePromise = this.getNurseScore(card,
                  room.room.hospital,
                  newRoom.afternoonPrograms.surgeonOpenings, true, true);
                card.time = this.setCardTime(newRoom.afternoonPrograms.surgeonOpenings, false)
              });
              // Remove duplicate anesth
              newRoom.team.others.forEach(card => {
                if (card.profile.position === "Anesthésiste") {
                  if (newRoom.team.others.find((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2)) {
                    newRoom.team.others.splice(newRoom.team.others.findIndex((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2), 1)
                  }
                }
              });
              
              this.setTeamOrder(newRoom)
              newRoom.isSplit = true;
              newRoom.splitByVacation = false;
              rooms.push(newRoom)
            } else {
              room.programs.forEach(program => {
                const team = this.getTeam(room.isBP, true, [program], room.splitByVacation, false, room)
                program.team = team
                program.team.others = []
                room.team.others.forEach((card: profileCard) => {
                  const newCard = cloneWithoutPromise(card)
                  newCard.originalTime = card.originalTime
                  program.team.others.push(newCard)
                });
                program.team.others.forEach(card => {
                  card.scorePromise = this.getNurseScore(card,
                    room.room.hospital,
                    [program], true, true);
                  card.time = this.setCardTime([program], true)
                });
              });
              // Remove duplicate anesth
              room.team.others.forEach(card => {
                if (card.profile.position === "Anesthésiste") {
                  if (room.team.others.find((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2)) {
                    room.team.others.splice(room.team.others.findIndex((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2), 1)
                  }
                }
              });
              room.programs = this.mergeSameTeams(room.programs)
              this.setTeamOrder(room)
              rooms.push(room)
            }
          }
        } else {
          if (init) {
            const splitByVacation = this.checkIfSplitByVacation(false, room.surgeonOpenings, room)
            room.splitByVacation = splitByVacation
            if (!splitByVacation) {
              const data = this.splitByMorningAfternoon(false, room);
              const newRoom = JSON.parse(JSON.stringify(room));
              newRoom.morningPrograms = JSON.parse(JSON.stringify(data.morningPrograms))
              newRoom.afternoonPrograms = JSON.parse(JSON.stringify(data.afternoonPrograms))
              newRoom.team.surgeons = newRoom.team.surgeons.concat(newRoom.morningPrograms.team.surgeons)
              newRoom.team.surgeons = newRoom.team.surgeons.concat(newRoom.afternoonPrograms.team.surgeons)
              newRoom.team.others = newRoom.team.others.concat(newRoom.morningPrograms.team.others)
              newRoom.team.others = newRoom.team.others.concat(newRoom.afternoonPrograms.team.others)
              // Remove duplicate anesth
              newRoom.team.others.forEach(card => {
                if (card.profile.position === "Anesthésiste") {
                  if (newRoom.team.others.find((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2)) {
                    newRoom.team.others.splice(newRoom.team.others.findIndex((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2), 1)
                  }
                }
              });
              this.setTeamOrder(newRoom)
              newRoom.isSplit = true;
              newRoom.splitByVacation = false;
              rooms.push(newRoom)
            } else {
              room.team = {surgeons: [], others: []}
              room.surgeonOpenings.forEach(surgeonOpening => {
                const team = this.getTeam(room.isBP, true, [surgeonOpening], room.splitByVacation, true, room)
                surgeonOpening.team = team
                room.team.surgeons = room.team.surgeons.concat(team.surgeons)
                room.team.others = room.team.others.concat(team.others)
              });
              // Remove duplicate anesth
              room.team.others.forEach(card => {
                if (card.profile.position === "Anesthésiste") {
                  if (room.team.others.find((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2)) {
                    room.team.others.splice(room.team.others.findIndex((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2), 1)
                  }
                }
              });
              room.surgeonOpenings = this.mergeSameTeams(room.surgeonOpenings)
              this.setTeamOrder(room)
              rooms.push(room)
            }
          } else {
            if (!room.splitByVacation) {
              const data = this.splitByMorningAfternoon(false, room);
              const newRoom = JSON.parse(JSON.stringify(room));
              newRoom.morningPrograms = JSON.parse(JSON.stringify(data.morningPrograms))
              newRoom.afternoonPrograms = JSON.parse(JSON.stringify(data.afternoonPrograms))
              newRoom.team.surgeons = newRoom.team.surgeons.concat(newRoom.morningPrograms.team.surgeons)
              newRoom.team.surgeons = newRoom.team.surgeons.concat(newRoom.afternoonPrograms.team.surgeons)
              newRoom.team.others = []

              room.team.others.forEach((card: profileCard) => {
                const newCard = cloneWithoutPromise(card)
                newCard.originalTime = card.originalTime
                newRoom.team.others.push(newCard)
              });

              newRoom.morningPrograms.team.others = []
              room.team.others.forEach((card: profileCard) => {
                const newCard = cloneWithoutPromise(card)
                newCard.originalTime = card.originalTime
                newRoom.morningPrograms.team.others.push(newCard)
              });
              
              newRoom.morningPrograms.team.others.forEach(card => {
                card.scorePromise = this.getNurseScore(card,
                  room.room.hospital,
                  newRoom.morningPrograms.surgeonOpenings, true, true);
                card.time = this.setCardTime(newRoom.morningPrograms.surgeonOpenings, false)
              });
              
              newRoom.afternoonPrograms.team.others = []
              room.team.others.forEach((card: profileCard) => {
                const newCard = cloneWithoutPromise(card)
                newCard.originalTime = card.originalTime
                newRoom.afternoonPrograms.team.others.push(newCard)
              });
              newRoom.afternoonPrograms.team.others.forEach(card => {
                card.scorePromise = this.getNurseScore(card,
                  room.room.hospital,
                  newRoom.afternoonPrograms.surgeonOpenings, true, true);
                card.time = this.setCardTime(newRoom.afternoonPrograms.surgeonOpenings, false)
              });
              // Remove duplicate anesth
              newRoom.team.others.forEach(card => {
                if (card.profile.position === "Anesthésiste") {
                  if (newRoom.team.others.find((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2)) {
                    newRoom.team.others.splice(newRoom.team.others.findIndex((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2), 1)
                  }
                }
              });
              this.setTeamOrder(newRoom)
              newRoom.isSplit = true;
              newRoom.splitByVacation = false;
              rooms.push(newRoom)
            } else {
              room.surgeonOpenings.forEach(surgeonOpening => {
                const team = this.getTeam(room.isBP, true, [surgeonOpening], room.splitByVacation, false, room)
                surgeonOpening.team = team
                surgeonOpening.team.others = []
                room.team.others.forEach((card: profileCard) => {
                  const newCard = cloneWithoutPromise(card)
                  newCard.originalTime = card.originalTime
                  surgeonOpening.team.others.push(newCard)
                });
                surgeonOpening.team.others.forEach(card => {
                  card.scorePromise = this.getNurseScore(card,
                    room.room.hospital,
                    [surgeonOpening], true, true);
                  card.time = this.setCardTime([surgeonOpening], true)
                });
              });
              // Remove duplicate anesth
              room.team.others.forEach(card => {
                if (card.profile.position === "Anesthésiste") {
                  if (room.team.others.find((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2)) {
                    room.team.others.splice(room.team.others.findIndex((card2) => String(card.profile._id) === String(card2.profile._id) && card !== card2), 1)
                  }
                }
              });
              room.surgeonOpenings = this.mergeSameTeams(room.surgeonOpenings)
              this.setTeamOrder(room)
              rooms.push(room)
            }
          }
        }
      }
    };

    this.rooms = []
    this.rooms = [...rooms, ...alreadySplit]
    this.rooms.forEach(room => {
      if (!room.isSplit) {
        room.team.others.forEach(card => {
          // TODO: j'ai rajouté un check pour exclure les cards des anesths
          if (card.profile.position !== ANESTHETIST) {
            card.isIntersectingRoom = this.isInIntersectingRooms(card, room);
          }
        });
      }
    });
    sortRoomsByPriority(this.rooms, false)
  }

  getStartTimeCard(programs: BufferProgram[]) {
    let time = { startTime: moment.utc(programs[0].startTime).toDate(), endTime: moment.utc(programs[0].endTime).toDate() }

    programs.forEach(program => {
      let startTime = moment.utc(program.startTime).toDate();
      let endTime = moment.utc(program.endTime).toDate();

      if (startTime.getTime() < new Date(time.startTime).getTime()) {
        time.startTime = moment.utc(startTime).toDate()
      }

      if (endTime.getTime() > new Date(time.endTime).getTime()) {
        time.endTime = moment.utc(endTime).toDate()
      }
    });
    return time
  }

  formatCompareTime(time: Date) {
    const tmp = [time.getUTCHours(), time.getUTCMinutes(), time.getUTCSeconds()]

    return tmp[0] * 3600 + tmp[1] * 60 + tmp[2]
  }

  canDropIn(card: profileCard, room: roomFinalData | consultationExtracliniqueData, programIndex: number, programs: BufferProgram[] | SO[]) {
    if (this.isBlocSelected && !this.isConsultationSelected && !this.isExtracliniqueSelected) {
      if (card.profile.position === "Anesthésiste" || card.isDuplicate) {
        if ((room as roomFinalData).team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
          return false;
        }
        return true;
      }
      const dataBloc = (room as roomFinalData)
      if (dataBloc.isBP) {
        let time;
        if (!dataBloc.isSplit) {
          time = { startTime: moment.utc(dataBloc.programs[0].startTime).toDate(), endTime: moment.utc(dataBloc.programs[0].endTime).toDate() }
  
          dataBloc.programs.forEach(program => {
            let startTime = moment.utc(program.startTime).toDate();
            let endTime = moment.utc(program.endTime).toDate();
  
            if (startTime.getTime() < new Date(time.startTime).getTime()) {
              time.startTime = startTime
            }
  
            if (endTime.getTime() > new Date(time.endTime).getTime()) {
              time.endTime = endTime
            }
          });
        } else {
          time = { startTime: moment.utc(programs[programIndex].startTime).toDate(), endTime: moment.utc(programs[programIndex].endTime).toDate() }
        }


        if (this.formatCompareTime(new Date(time.startTime)) >= this.formatCompareTime(new Date(card.originalTime.startTime)) &&
          this.formatCompareTime(new Date(time.endTime)) <= this.formatCompareTime(new Date(card.originalTime.endTime))) {
          return true;
        }
      } else {
        let time = { startTime: moment.utc(dataBloc.surgeonOpenings[programIndex].startTime).toDate(), endTime: moment.utc(dataBloc.surgeonOpenings[programIndex].endTime).toDate() }

        if (!dataBloc.isSplit || (dataBloc.isSplit && !dataBloc.splitByVacation)) {
          dataBloc.surgeonOpenings.forEach(surgeonOpening => {
            let startTime = moment.utc(surgeonOpening.startTime).toDate();
            let endTime = moment.utc(surgeonOpening.endTime).toDate();

            if (startTime.getTime() < new Date(time.startTime).getTime()) {
              time.startTime = startTime
            }

            if (endTime.getTime() > new Date(time.endTime).getTime()) {
              time.endTime = endTime
            }
          });
        }

        if (this.formatCompareTime(new Date(time.startTime)) >= this.formatCompareTime(new Date(card.originalTime.startTime)) &&
          this.formatCompareTime(new Date(time.endTime)) <= this.formatCompareTime(new Date(card.originalTime.endTime))) {
          return true;
        }
      }
    } else {
      const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
      const data = (room as consultationExtracliniqueData)

      if (data.isSplit) {
        if (data.isMorning) {
          if (this.formatCompareTime(new Date(hospital.startTime)) >= this.formatCompareTime(card.originalTime.startTime) &&
            this.formatCompareTime(new Date(hospital.middleTime)) <= this.formatCompareTime(card.originalTime.endTime)) {
            return true;
          }
        } else {
          if (this.formatCompareTime(new Date(hospital.middleTime)) >= this.formatCompareTime(card.originalTime.startTime) &&
            this.formatCompareTime(new Date(hospital.endTime)) <= this.formatCompareTime(card.originalTime.endTime)) {
            return true;
          }
        }
      } else {
        if (this.formatCompareTime(new Date(hospital.startTime)) >= this.formatCompareTime(card.originalTime.startTime) &&
          this.formatCompareTime(new Date(hospital.endTime)) <= this.formatCompareTime(card.originalTime.endTime)) {
          return true;
        }
      }
    }
    return false;
  }

  openExportDialog() {
    const dialogRef = this.dialog.open(ExportDayComponent, {
      data: {
        date: this.date,
        isDayWeek: this.isDayWeek,
        comment: this.comment
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.toastService.infoToast("Le planning a été généré en pdf!");
      }
    });
  }

  isInIntersectingRooms(card: profileCard, roomCheck: roomFinalData) {
    let result = false;
    const roomsWithCard: roomFinalData[] = [];
    if (!roomCheck.programs || roomCheck.programs.length === 0) {
      return false;
    }
    let timeRoomCheck = { startTime: moment.utc(roomCheck.programs[0].startTime).toDate(), endTime: moment.utc(roomCheck.programs[0].endTime).toDate() }
  
    roomCheck.programs.forEach(program => {
      let startTime = moment.utc(program.startTime).toDate();
      let endTime = moment.utc(program.endTime).toDate();
  
      if (startTime.getTime() < new Date(timeRoomCheck.startTime).getTime()) {
        timeRoomCheck.startTime = startTime
      }
  
      if (endTime.getTime() > new Date(timeRoomCheck.endTime).getTime()) {
        timeRoomCheck.endTime = endTime
      }
    });

    for (let index = 0; index < this.rooms.length; index++) { //Get rooms with card
      const room = this.rooms[index];
      
      if (String(room.roomId) !== String(roomCheck.roomId)) {
        if (room.isSplit && !room.splitByVacation && room.morningPrograms && room.afternoonPrograms) {
          if (room.morningPrograms.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
            roomsWithCard.push(room.morningPrograms)
          }

          if (room.afternoonPrograms.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
            roomsWithCard.push(room.afternoonPrograms)
          }
        } else {
          if (room.team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
            roomsWithCard.push(room)
          }
        }
      }
    }

    for (let index = 0; index < roomsWithCard.length; index++) { // Check if the card collide with other cards. And if other cards collide with the card
      const room = roomsWithCard[index];
      let time = { startTime: moment.utc(room.programs[0].startTime).toDate(), endTime: moment.utc(room.programs[0].endTime).toDate() }
  
      room.programs.forEach(program => {
        let startTime = moment.utc(program.startTime).toDate();
        let endTime = moment.utc(program.endTime).toDate();
  
        if (startTime.getTime() < new Date(time.startTime).getTime()) {
          time.startTime = startTime
        }
  
        if (endTime.getTime() > new Date(time.endTime).getTime()) {
          time.endTime = endTime
        }
      });


      if ((this.formatCompareTime(new Date(card.time.startTime)) >= this.formatCompareTime(new Date(time.startTime)) &&
          this.formatCompareTime(new Date(card.time.startTime)) <= this.formatCompareTime(new Date(time.endTime))) &&
          (this.formatCompareTime(new Date(card.time.endTime)) <= this.formatCompareTime(new Date(time.endTime)) &&
          this.formatCompareTime(new Date(card.time.endTime)) >= this.formatCompareTime(new Date(time.startTime)))) {
          result = true;
          break;
      }

      if ((this.formatCompareTime(new Date(time.startTime)) >= this.formatCompareTime(new Date(timeRoomCheck.startTime)) &&
          this.formatCompareTime(new Date(time.startTime)) <= this.formatCompareTime(new Date(timeRoomCheck.endTime))) &&
          (this.formatCompareTime(new Date(time.endTime)) <= this.formatCompareTime(new Date(timeRoomCheck.endTime)) &&
          this.formatCompareTime(new Date(time.endTime)) >= this.formatCompareTime(new Date(timeRoomCheck.startTime)))) {
          result = true;
          break;
      }
    }

    return result;
  }

  getTeam(isBP: boolean, isSplit: boolean, programs: BufferProgram[] | SO[], splitByVacation: boolean = true, init: boolean = false, room: roomFinalData | consultationExtracliniqueData) {
    let surgeons: surgeonCard[] = []
    let others = []

    if (isSplit) {
      if (splitByVacation) {
        programs.forEach((program: BufferProgram | SO, index: number) => {
          if (isBP) {
            const programData: BufferProgram = (program as BufferProgram);
            const time = this.getStartTimeCard((programs as BufferProgram[]));
            // Surgeons
            surgeons.push({profile: program.surgeon && program.surgeon._id ? program.surgeon : this.urgenceProfile, time: {startTime: moment.utc(program.startTime).toDate(), endTime: moment.utc(program.endTime).toDate()}})
            
            // Anesthetists
            others = others.concat(programData.anesthesists.map((anesthetist) => {
              const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(anesthetist._id))
              return {
                profile: anesthetist,
                originalTime: originalProfile ? {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()} : {startTime: null, endTime: null},
                time: null,
                isGarde: originalProfile ? isGuard(originalProfile): null
              }
            }))
    
            if (programData.interne && !others.find((card) => String(card.profile._id) === String(programData.interne._id))) {
              const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(programData.interne._id))
    
              others = others.concat({
                profile: programData.interne,
                originalTime: originalProfile ? {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()} : {startTime: null, endTime: null},
                time: null,
                isGarde: originalProfile ? isGuard(originalProfile) : null
              })
            }
            if (programData.seniorJunior && !others.find((card) => String(card.profile._id) === String(programData.seniorJunior._id))) {
              const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(programData.seniorJunior._id))
    
              others = others.concat({
                profile: programData.seniorJunior,
                originalTime: originalProfile ? {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()} : {startTime: null, endTime: null},
                time: null,
                isGarde: originalProfile ? isGuard(originalProfile): null
              })
            }
    
            // Paramedical & IADE
            others = others.concat(programData.nurses.filter((nurse: {profile: Profile, role: Role}) => !IADE.includes(nurse.profile.position)).map((nurse: {profile: Profile, role: Role}) => {
              const originalProfile = this.allNurses.find((nurse2) => String(nurse2._id) === String(nurse.profile._id))
              let card = {
                profile: nurse.profile,
                role: nurse.role,
                time,
                originalTime: originalProfile ? {startTime: moment.utc(originalProfile.startTime).toDate(), endTime: moment.utc(originalProfile.endTime).toDate()} : {startTime: null, endTime: null},
                invalidTime: null,
                isIntersectingRoom: false
              }
              card.invalidTime = !this.canDropIn(card, room, index, programs)
              card.isIntersectingRoom = this.isInIntersectingRooms(card, (room as roomFinalData));
              
              return card
            }))
    
            others = others.concat(programData.nurses.filter((nurse: {profile: Profile, role: Role}) => IADE.includes(nurse.profile.position)).map((nurse: {profile: Profile, role: Role}) => {
              const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(nurse.profile._id))
              let card = {
                profile: nurse.profile,
                role: nurse.role,
                time,
                originalTime: originalProfile ? {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()} : {startTime: null, endTime: null},
                invalidTime: null,
                isIntersectingRoom: false
              }
              card.invalidTime = !this.canDropIn(card, room, index, programs)
              card.isIntersectingRoom = this.isInIntersectingRooms(card, (room as roomFinalData));
              return card
            }))
          } else {
            const programData: SO = (program as SO);
    
            // Surgeons
            surgeons.push({profile: programData.surgeon && programData.surgeon._id ? programData.surgeon : this.urgenceProfile, time: {startTime: moment.utc(programData.startTime).toDate(), endTime: moment.utc(programData.endTime).toDate()}})
          }
        });
      } else {
        if (isBP) {
          const programData: BufferProgram = (programs[0] as BufferProgram);
          const time = this.getStartTimeCard((programs as BufferProgram[]));
          // Surgeons
          (programs as BufferProgram[]).forEach((program: BufferProgram) => {
            surgeons.push({profile: program.surgeon && program.surgeon._id ? program.surgeon : this.urgenceProfile, time: {startTime: moment.utc(program.startTime).toDate(), endTime: moment.utc(program.endTime).toDate()}})
          });

          // Anesthetists
          others = others.concat(programData.anesthesists.map((anesthetist) => {
            const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(anesthetist._id))

            return {
              profile: anesthetist,
              originalTime: originalProfile ? {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()} : {startTime: null, endTime: null},
              time: null,
              isGarde: originalProfile ? isGuard(originalProfile): null
            }
          }))

          if (programData.interne && !others.find((card) => String(card.profile._id) === String(programData.interne._id))) {
            const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(programData.interne._id))
  
            others = others.concat({
              profile: programData.interne,
              originalTime: originalProfile ? {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()} : {startTime: null, endTime: null},
              time: null,
              isGarde: originalProfile ? isGuard(originalProfile) : null
            })
          }
          if (programData.seniorJunior && !others.find((card) => String(card.profile._id) === String(programData.seniorJunior._id))) {
            const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(programData.seniorJunior._id))
  
            others = others.concat({
              profile: programData.seniorJunior,
              originalTime: originalProfile ? {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()} : {startTime: null, endTime: null},
              time: null,
              isGarde: originalProfile ? isGuard(originalProfile): null
            })
          }
  
          // Paramedical & IADE
          others = others.concat(programData.nurses.filter((nurse: {profile: Profile, role: Role}) => !IADE.includes(nurse.profile.position)).map((nurse: {profile: Profile, role: Role}) => {
            const originalProfile = this.allNurses.find((nurse2) => String(nurse2._id) === String(nurse.profile._id))
            let card = {
              profile: nurse.profile,
              role: nurse.role,
              time,
              originalTime: originalProfile ? {startTime: moment.utc(originalProfile.startTime).toDate(), endTime: moment.utc(originalProfile.endTime).toDate()} : {startTime: null, endTime: null},
              invalidTime: null,
              isIntersectingRoom: false
            }
            card.invalidTime = !this.canDropIn(card, room, 0, programs)
            card.isIntersectingRoom = this.isInIntersectingRooms(card, (room as roomFinalData));
            return card
          }))
  
          others = others.concat(programData.nurses.filter((nurse: {profile: Profile, role: Role}) => IADE.includes(nurse.profile.position)).map((nurse: {profile: Profile, role: Role}) => {
            const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(nurse.profile._id))
            let card = {
              profile: nurse.profile,
              role: nurse.role,
              time,
              originalTime: originalProfile ? {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()} : {startTime: null, endTime: null},
              invalidTime: null,
              isIntersectingRoom: false
            }
            card.invalidTime = !this.canDropIn(card, room, 0, programs)
            card.isIntersectingRoom = this.isInIntersectingRooms(card, (room as roomFinalData));
            return card
          }))
        } else {
          // Surgeons
          (programs as SO[]).forEach((program: SO) => {
            surgeons.push({profile: program.surgeon && program.surgeon._id ? program.surgeon : this.urgenceProfile, time: {startTime: moment.utc(program.startTime).toDate(), endTime: moment.utc(program.endTime).toDate()}})
          });
        }
      }
    } else {
      const program: BufferProgram | SO = programs[0]
      if (isBP) {
        const programData: BufferProgram = (program as BufferProgram);
        const time = this.getStartTimeCard((programs as BufferProgram[]));

        // Surgeons
        programs.forEach((program: BufferProgram | SO) => {
          surgeons.push({profile: program.surgeon && program.surgeon._id ? program.surgeon : this.urgenceProfile, time: {startTime: moment.utc(program.startTime).toDate(), endTime: moment.utc(program.endTime).toDate()}})
        })
        // Anesthetists
        others = others.concat(programData.anesthesists.map((anesthetist) => {
          const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(anesthetist._id))
          return {
            profile: anesthetist,
            originalTime: originalProfile ? {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()} : {startTime: null, endTime: null},
            time: null,
            isGarde: originalProfile ? isGuard(originalProfile) : null
          }
        }))

        if (programData.interne && !others.find((card) => String(card.profile._id) === String(programData.interne._id))) {
          const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(programData.interne._id))

          others = others.concat({
            profile: programData.interne,
            originalTime: originalProfile ? {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()} : {startTime: null, endTime: null},
            time: null,
            isGarde: originalProfile ? isGuard(originalProfile) : null
          })
        }
        if (programData.seniorJunior && !others.find((card) => String(card.profile._id) === String(programData.seniorJunior._id))) {
          const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(programData.seniorJunior._id))
          
          others = others.concat({
            profile: programData.seniorJunior,
            originalTime: originalProfile ? {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()} : {startTime: null, endTime: null},
            time: null,
            isGarde: originalProfile ? isGuard(originalProfile) : null
          })
        }

        // Paramedical & IADE
        others = others.concat(programData.nurses.filter((nurse: {profile: Profile, role: Role}) => !IADE.includes(nurse.profile.position)).map((nurse: {profile: Profile, role: Role}) => {
          const originalProfile = this.allNurses.find((nurse2) => String(nurse2._id) === String(nurse.profile._id))
          let card = {
            profile: nurse.profile,
            role: nurse.role,
            time,
            originalTime: originalProfile ? {startTime: moment.utc(originalProfile.startTime).toDate(), endTime: moment.utc(originalProfile.endTime).toDate()} : {startTime: null, endTime: null},
            invalidTime: null,
            isIntersectingRoom: false
          }
          card.invalidTime = !this.canDropIn(card, room, 0, programs)
          card.isIntersectingRoom = this.isInIntersectingRooms(card, (room as roomFinalData));
          return card
        }))
        
        others = others.concat(programData.nurses.filter((nurse: {profile: Profile, role: Role}) => IADE.includes(nurse.profile.position)).map((nurse: {profile: Profile, role: Role}) => {
          const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(nurse.profile._id))
          let card = {
            profile: nurse.profile,
            role: nurse.role,
            time,
            originalTime: originalProfile ? {startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate()} : {startTime: null, endTime: null},
            invalidTime: null,
            isIntersectingRoom: false
          }
          card.invalidTime = !this.canDropIn(card, room, 0, programs)
          card.isIntersectingRoom = this.isInIntersectingRooms(card, (room as roomFinalData));
          return card
        }))

      } else {
        // Surgeons
        programs.forEach((program: BufferProgram | SO) => {
          const programData: SO = (program as SO);
          surgeons.push({profile: programData.surgeon && programData.surgeon._id ? programData.surgeon : this.urgenceProfile, time: {startTime: moment.utc(programData.startTime).toDate(), endTime: moment.utc(programData.endTime).toDate()}})
        })
      }
    }

    const anesths = others.filter((card) => card.profile.position === "Anesthésiste")
    const iades = others.filter((card) => IADE.includes(card.profile.position))
    let team: profileCard[];
    if (init) {
      team = others.filter((card) => NURSE_TYPES.includes(card.profile.position)).sort((a: profileCard, b: profileCard) => {
        if (a && a.role && a.role.priority && b && b.role && b.role.priority) {
          return a.role.priority - b.role.priority
        } else {
          return 0
        }
      })

      let sameSO = true;

      if ((room as roomFinalData).surgeonOpenings.length > 1) {
        let lastSOId = (room as roomFinalData).surgeonOpenings[0]._id;
        for (let index = 0; index < (room as roomFinalData).surgeonOpenings.length; index++) {
          const surgeonOpening = (room as roomFinalData).surgeonOpenings[index];
          
          if (surgeonOpening && lastSOId !== surgeonOpening._id) {
            sameSO = false;
            break;
          }
        }
      }

      if (sameSO && surgeons.length > 1) {
        if (!(room as roomFinalData).surgeonOpenings[0]){
          return;
        }
        let time = { startTime: moment.utc((room as roomFinalData).surgeonOpenings[0].startTime).toDate(), endTime: moment.utc((room as roomFinalData).surgeonOpenings[0].endTime).toDate() };

        (room as roomFinalData).surgeonOpenings.forEach(program => {
          let startTime = moment.utc(program.startTime).toDate();
          let endTime = moment.utc(program.endTime).toDate();

          if (startTime.getTime() < new Date(time.startTime).getTime()) {
            time.startTime = moment.utc(startTime).toDate()
          }

          if (endTime.getTime() > new Date(time.endTime).getTime()) {
            time.endTime = moment.utc(endTime).toDate()
          }
        });

        const profile: Profile = JSON.parse(JSON.stringify(surgeons[0].profile))
        surgeons = [{profile, time}]
      }

    } else {
      team = others.filter((card) => NURSE_TYPES.includes(card.profile.position))
    }

    others = anesths.concat(iades, team)

    return {surgeons, others};
  }
  
  getProgramCommentary() {

    return new Promise<void>((resolve, reject) => {
      if (this.programCommentarySubscription) {
        this.programCommentarySubscription.unsubscribe();
      }
  
      this.programCommentarySubscription = this.programCommentaryService.getProgramCommentary(this.date).subscribe(
        (res) => {
          if (res && res.length !== 0) {
            this.programCommentary = res[0];
            this.comment = res[0].comment;
          }
          resolve();
        },
        (error) => {
          this.errorService.handleError(error);
          reject();
        }
      );
    })
  }

  addProgramCommentary() {
    const body: ProgramCommentary = {
      date: this.date,
      comment: this.comment,
      hospital: getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    };
    this.programCommentaryService.addProgramCommentary(body).subscribe(
      (res) => {
        if (res) {
          this.programCommentary = res;
          this.comment = res.comment;
        }
      },
      (error) => {
        this.errorService.handleError(error);
      }
    );
  }

  updateProgramCommentary() {
    const body: ProgramCommentary = {
      date: this.date,
      comment: this.comment,
      hospital: getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    };
    this.programCommentaryService
      .updateProgramCommentary(body, this.programCommentary._id)
      .subscribe(
        (res) => {
          if (res && this.comment) {
            this.programCommentary.comment = this.comment;
          }
        },
        (error) => {
          this.errorService.handleError(error);
        }
      );
  }

  saveComment() {
    if (this.comment !== null) {
      if (this.programCommentary !== null) {
        this.updateProgramCommentary();
      } else {
        this.addProgramCommentary();
      }
    }
  }

  getSplitSurgeonOpenings(surgeonOpenings: SO[], programs: BufferProgram[]) {
    if (programs.length === 2 && surgeonOpenings.length === 1) {
      const SO1 = JSON.parse(JSON.stringify(surgeonOpenings[0]))
      SO1.startTime = new Date(programs[0].startTime)
      SO1.endTime = new Date(programs[0].endTime)
      const SO2 = JSON.parse(JSON.stringify(surgeonOpenings[0]))
      SO2.startTime = new Date(programs[1].startTime)
      SO2.endTime = new Date(programs[1].endTime)

      return [].concat(SO1, SO2)
    } else {
      return surgeonOpenings;
    }
  }

  formatRoomFinal(isBP: boolean, room: roomBufferPrograms | roomWithSOAndSOH, roomSurgeonOpening: roomWithSOAndSOH, index?: number): roomFinalData {
    const surgeons = isBP ? [] : this.getSurgeons((room as roomWithSOAndSOH), this.typeAttributeArray[index]);
    let roomFinalData = {
      roomId: isBP ? (room as roomBufferPrograms)._id : (room as roomWithSOAndSOH).room._id,
      roomNumber: isBP ? (room as roomBufferPrograms).roomNumber : (room as roomWithSOAndSOH).room.roomNumber,
      room: roomSurgeonOpening.room,
      isEmpty: isBP ? false : !(surgeons && surgeons.length > 0),
      modified: false,
      isSplit: isBP ? !this.utilisService.isBPsHaveSameTeam((room as roomBufferPrograms)) : false,
      programs: isBP ? (room as roomBufferPrograms).programs : null,
      originalPrograms: isBP ? (room as roomBufferPrograms).programs : null,
      surgeonOpenings: isBP ? this.getSplitSurgeonOpenings(roomSurgeonOpening.surgeonopening, (room as roomBufferPrograms).programs) : (room as roomWithSOAndSOH)[this.typeAttributeArray[index]],
      isBP,
      originalSO: isBP ? null : JSON.parse(JSON.stringify((room as roomWithSOAndSOH)[this.typeAttributeArray[index]])),
      team: null,
      splitByVacation: null
    }
    if (roomFinalData.programs && (!roomFinalData.surgeonOpenings || roomFinalData.surgeonOpenings.length === 0)) {
      return null;
    }

    roomFinalData.team = this.getTeam(isBP, isBP ? !this.utilisService.isBPsHaveSameTeam((room as roomBufferPrograms)) : false, isBP ? (room as roomBufferPrograms).programs : (room as roomWithSOAndSOH)[this.typeAttributeArray[index]], true, true, roomFinalData)

    return roomFinalData
  }

  mergeRoomsSurgonOpeningsWithRoomsBufferPrograms(
    roomsSurgeonOpenings: roomWithSOAndSOH[],
    roomsBufferPrograms: roomBufferPrograms[]
  ) {
    this.rooms = [];
    const fullRoomsIds = roomsBufferPrograms.map((room) => room._id);
    this.rooms = roomsSurgeonOpenings.map((roomSurgeonOpenings, i) => {
      const roomId = roomSurgeonOpenings.room._id;
      const index = fullRoomsIds.findIndex(
        (fullRoomId) => fullRoomId == roomId
      );

      let roomBufferPrograms: roomBufferPrograms;

      if (index >= 0) {
        roomBufferPrograms = roomsBufferPrograms[index];

        // Get anesth details profiles
        roomBufferPrograms.programs.forEach((program) => {
          if (this.hospitalHaveAnesthDetailsOption) {
            if (program.anesthesists) {
              program.seniorJunior = program.anesthesists.find((profile) => [ANESTHETIST].includes(profile.position) && ['Senior', 'Junior'].includes(profile.seniority));
              program.interne = program.anesthesists.find((profile) => [ANESTHETIST].includes(profile.position) && ['Interne'].includes(profile.seniority));
            }
          }
        });
      }

      if (index >= 0) {
        return this.formatRoomFinal(true, roomBufferPrograms, roomSurgeonOpenings)
      } else {
        return this.formatRoomFinal(false, roomSurgeonOpenings, roomSurgeonOpenings, i)
      }
    });

    this.rooms = this.rooms.filter((room) => room !== null)


    // Unwind all Splited rooms for html wrap
    this.unwindFinalDataSplit(true);
    this.sortPrograms(this.rooms)
    this.setFillingSurgeons();
    this.listIdsBloc = this.getDragDropListIdsBloc();

    // Sort surgeonOpenings by date
    for (let i: number = 0; i < this.rooms.length; i++) {
      if (this.rooms[i].surgeonOpenings) {
        this.rooms[i].surgeonOpenings = this.rooms[i]
        .surgeonOpenings.sort(function (a, b) {
          let dateA = new Date(a.startTime).getUTCHours();
          let dateB = new Date(b.startTime).getUTCHours();
          return dateA - dateB;
        });
      }
    }

    this.isLoadingRooms = false;
  }

  setFillingSurgeons() {
    this.rooms.forEach(room => {
      const roomTime = getRoomHoursBySelectedDay(room.room, new Date(this.date))

      if (room && room.team && room.team.surgeons) {
        room.team.surgeons = room.team.surgeons.filter((surgeon) => !surgeon.isFillingSurgeon)
        let tmpRoom = JSON.parse(JSON.stringify(room.team.surgeons))
        let added = 0;
        room.team.surgeons.forEach((surgeon, index) => {
          if (index === 0) { // Check first surgeon with hospital startTime
            if (this.formatCompareTime(new Date(surgeon.time.startTime)) - 10800 > this.formatCompareTime(new Date(roomTime.startTime))) {
              tmpRoom.splice(index + added, 0, {time: {startTime: new Date(roomTime.startTime), endTime: new Date(surgeon.time.endTime)}, isFillingSurgeon: true})
              added++;
            }
            if (room.team.surgeons.length === 1) {
              if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(roomTime.endTime))) {
                tmpRoom.splice(1 + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(roomTime.endTime)}, isFillingSurgeon: true})
                added++;
              }
            }
            if (room.team.surgeons.length === 2) {
              if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(room.team.surgeons[1].time.startTime))) {
                tmpRoom.splice(1 + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(room.team.surgeons[1].time.startTime)}, isFillingSurgeon: true})
                added++;
              }
            }
          } else if (index === room.team.surgeons.length - 1) {
            if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(roomTime.endTime))) {
              tmpRoom.splice(index + added + 1, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(roomTime.endTime)}, isFillingSurgeon: true})
              added++;
            }
          } else {
            if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(room.team.surgeons[index + 1].time.startTime))) {
              tmpRoom.splice(index + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(room.team.surgeons[index + 1].time.startTime)}, isFillingSurgeon: true})
              added++;
            }
          }
        });
        room.team.surgeons = tmpRoom;
      }

      if (room.programs) {
        room.programs.forEach(program => {
          if (program && program.team && program.team.surgeons) {
            program.team.surgeons = program.team.surgeons.filter((surgeon) => !surgeon.isFillingSurgeon)
            let tmpProgram = JSON.parse(JSON.stringify(program.team.surgeons))
            let added = 0;
            program.team.surgeons.forEach((surgeon, index) => {
              if (index === 0) { // Check first surgeon with hospital startTime
                if (this.formatCompareTime(new Date(surgeon.time.startTime)) - 10800 > this.formatCompareTime(new Date(roomTime.startTime))) {
                  tmpProgram.splice(index + added, 0, {time: {startTime: new Date(roomTime.startTime), endTime: new Date(surgeon.time.endTime)}, isFillingSurgeon: true})
                  added++;
                }
                if (program.team.surgeons.length === 1) {
                  if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(roomTime.endTime))) {
                    tmpProgram.splice(1 + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(roomTime.endTime)}, isFillingSurgeon: true})
                    added++;
                  }
                }
                if (program.team.surgeons.length === 2) {
                  if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(program.team.surgeons[1].time.startTime))) {
                    tmpProgram.splice(1 + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(program.team.surgeons[1].time.startTime)}, isFillingSurgeon: true})
                    added++;
                  }
                }
              } else if (index === program.team.surgeons.length - 1) {
                if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(roomTime.endTime))) {
                  tmpProgram.splice(index + added + 1, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(roomTime.endTime)}, isFillingSurgeon: true})
                  added++;
                }
              } else {
                if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(program.team.surgeons[index + 1].time.startTime))) {
                  tmpProgram.splice(index + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(program.team.surgeons[index + 1].time.startTime)}, isFillingSurgeon: true})
                  added++;
                }
              }
            });
            program.team.surgeons = tmpProgram
          }
        });
      }

      if (room.surgeonOpenings) {
        room.surgeonOpenings.forEach(surgeonOpening => {
          if (surgeonOpening && surgeonOpening.team && surgeonOpening.team.surgeons) {
            surgeonOpening.team.surgeons = surgeonOpening.team.surgeons.filter((surgeon) => !surgeon.isFillingSurgeon)
            let tmpSurgeonOpening = JSON.parse(JSON.stringify(surgeonOpening.team.surgeons))
            let added = 0;
            surgeonOpening.team.surgeons.forEach((surgeon, index) => {
              if (index === 0) { // Check first surgeon with hospital startTime
                if (this.formatCompareTime(new Date(surgeon.time.startTime)) - 10800 > this.formatCompareTime(new Date(roomTime.startTime))) {
                  tmpSurgeonOpening.splice(index + added, 0, {time: {startTime: new Date(roomTime.startTime), endTime: new Date(surgeon.time.endTime)}, isFillingSurgeon: true})
                  added++;
                }
                if (surgeonOpening.team.surgeons.length === 1) {
                  if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(roomTime.endTime))) {
                    tmpSurgeonOpening.splice(1 + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(roomTime.endTime)}, isFillingSurgeon: true})
                    added++;
                  }
                }
                if (surgeonOpening.team.surgeons.length === 2) {
                  if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(surgeonOpening.team.surgeons[1].time.startTime))) {
                    tmpSurgeonOpening.splice(1 + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(surgeonOpening.team.surgeons[1].time.startTime)}, isFillingSurgeon: true})
                    added++;
                  }
                }
              } else if (index === surgeonOpening.team.surgeons.length - 1) {
                if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(roomTime.endTime))) {
                  tmpSurgeonOpening.splice(index + added + 1, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(roomTime.endTime)}, isFillingSurgeon: true})
                  added++;
                }
              } else {
                if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(surgeonOpening.team.surgeons[index + 1].time.startTime))) {
                  tmpSurgeonOpening.splice(index + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(surgeonOpening.team.surgeons[index + 1].time.startTime)}, isFillingSurgeon: true})
                  added++;
                }
              }
            });
            surgeonOpening.team.surgeons = tmpSurgeonOpening
          }
        });
      }

      if (room.morningPrograms && room.morningPrograms.team && room.morningPrograms.team.surgeons) {
        room.morningPrograms.team.surgeons = room.morningPrograms.team.surgeons.filter((surgeon) => !surgeon.isFillingSurgeon)
        let tmpMorningPrograms = JSON.parse(JSON.stringify(room.morningPrograms.team.surgeons))
        let added = 0;
        room.morningPrograms.team.surgeons.forEach((surgeon, index) => {
          if (index === 0) { // Check first surgeon with hospital startTime
            if (this.formatCompareTime(new Date(surgeon.time.startTime)) - 10800 > this.formatCompareTime(new Date(roomTime.startTime))) {
              tmpMorningPrograms.splice(index + added, 0, {time: {startTime: new Date(roomTime.startTime), endTime: new Date(surgeon.time.endTime)}, isFillingSurgeon: true})
              added++;
            }
            if (room.morningPrograms.team.surgeons.length === 1) {
              if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(roomTime.endTime))) {
                tmpMorningPrograms.splice(1 + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(roomTime.endTime)}, isFillingSurgeon: true})
                added++;
              }
            }
            if (room.morningPrograms.team.surgeons.length === 2) {
              if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(room.morningPrograms.team.surgeons[1].time.startTime))) {
                tmpMorningPrograms.splice(1 + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(room.morningPrograms.team.surgeons[1].time.startTime)}, isFillingSurgeon: true})
                added++;
              }
            }
          } else if (index === room.morningPrograms.team.surgeons.length - 1) {
            if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(roomTime.endTime))) {
              tmpMorningPrograms.splice(index + added + 1, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(roomTime.endTime)}, isFillingSurgeon: true})
              added++;
            }
          } else {
            if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(room.morningPrograms.team.surgeons[index + 1].time.startTime))) {
              tmpMorningPrograms.splice(index + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(room.morningPrograms.team.surgeons[index + 1].time.startTime)}, isFillingSurgeon: true})
              added++;
            }
          }
        });
        room.morningPrograms.team.surgeons = tmpMorningPrograms
      }

      if (room.afternoonPrograms && room.afternoonPrograms.team && room.morningPrograms.team.surgeons) {
        room.afternoonPrograms.team.surgeons = room.afternoonPrograms.team.surgeons.filter((surgeon) => !surgeon.isFillingSurgeon)
        let tmpAfternoonPrograms = JSON.parse(JSON.stringify(room.afternoonPrograms.team.surgeons))
        let added = 0;
        room.afternoonPrograms.team.surgeons.forEach((surgeon, index) => {
          if (index === 0) { // Check first surgeon with hospital startTime
            if (this.formatCompareTime(new Date(surgeon.time.startTime)) - 10800 > this.formatCompareTime(new Date(roomTime.startTime))) {
              tmpAfternoonPrograms.splice(index + added, 0, {time: {startTime: new Date(roomTime.startTime), endTime: new Date(surgeon.time.endTime)}, isFillingSurgeon: true})
              added++;
            }
            if (room.afternoonPrograms.team.surgeons.length === 1) {
              if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(roomTime.endTime))) {
                tmpAfternoonPrograms.splice(1 + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(roomTime.endTime)}, isFillingSurgeon: true})
                added++;
              }
            }
            if (room.afternoonPrograms.team.surgeons.length === 2) {
              if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(room.afternoonPrograms.team.surgeons[1].time.startTime))) {
                tmpAfternoonPrograms.splice(1 + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(room.afternoonPrograms.team.surgeons[1].time.startTime)}, isFillingSurgeon: true})
                added++;
              }
            }
          } else if (index === room.afternoonPrograms.team.surgeons.length - 1) {
            if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(roomTime.endTime))) {
              tmpAfternoonPrograms.splice(index + added + 1, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(roomTime.endTime)}, isFillingSurgeon: true})
              added++;
            }
          } else {
            if (this.formatCompareTime(new Date(surgeon.time.endTime)) + 10800 < this.formatCompareTime(new Date(room.afternoonPrograms.team.surgeons[index + 1].time.startTime))) {
              tmpAfternoonPrograms.splice(index + added, 0, {time: {startTime: new Date(surgeon.time.endTime), endTime: new Date(room.afternoonPrograms.team.surgeons[index + 1].time.startTime)}, isFillingSurgeon: true})
              added++;
            }
          }
        });
        room.afternoonPrograms.team.surgeons = tmpAfternoonPrograms;
      }
    });
  }

  getRoomsWithBufferPrograms() {
    return new Promise<void>((resolve, reject) => {
      if (this.getBufferProgramsSubscription) {
        this.getBufferProgramsSubscription.unsubscribe();
      }

      this.getBufferProgramsSubscription = this.bufferProgramService.getAllRoomsBufferPrograms(this.date).subscribe(async (rooms) => {
        this.roomsBufferPrograms = rooms;
        // Get consult data when we get BP data
        if (this.hospitalHaveAnesthDetailsOption) {
          this.isLoadingConsultExtra = true;
          await this.getConsultExtraData();
        }
        const sortedRoomsBP = this.sortPrograms(rooms)
        this.mergeRoomsSurgonOpeningsWithRoomsBufferPrograms(this.roomsSurgeonOpenings, sortedRoomsBP)
        resolve();
      }, (error) => {
        this.errorService.handleError(error);
        reject();
      })
    })
  }

  getSOwithBP() {
    return new Promise<void>((resolve, reject) => {
      if (this.getSOSubscription) {
        this.getSOSubscription.unsubscribe();
      }

      this.getSOSubscription = this.roomsService.getHospitalRoomsDaysSurgeonOpenings(this.date).subscribe(async (rooms) => {
        this.roomsSurgeonOpenings = rooms
        sortRoomsByPriority(this.roomsSurgeonOpenings, false)
        this.typeAttributeArray = setTypeAttributeArray(
          this.roomsSurgeonOpenings,
          false
        );
        this.getOnlyOpenedSurgeonOpeningsOfRooms();
        if (!this.anesthetistsCalendars) {
          await this.getAnesthetistsCalendars()
        }
        await this.getRoomsWithBufferPrograms()
        resolve();
      }, (error) => {
        this.errorService.handleError(error);
        reject();
      })
    })
  }

  getOnlyOpenedSurgeonOpeningsOfRoom(roomSurgeonOpenings: SO[]) {
    return roomSurgeonOpenings.filter(
      (roomSurgeonOpening) =>
        !("opening" in roomSurgeonOpening) || roomSurgeonOpening.opening
    );
  }

  getOnlyOpenedSurgeonOpeningsOfRooms() {
    this.roomsSurgeonOpenings = this.roomsSurgeonOpenings.map(
      (roomSurgeonOpenings) => {
        return {
          ...roomSurgeonOpenings,
          surgeonopeninghistory: roomSurgeonOpenings.surgeonopeninghistory,
          surgeonopening: this.getOnlyOpenedSurgeonOpeningsOfRoom(roomSurgeonOpenings.surgeonopening),
        };
      }
    );
  }

  getDynamicMarginRight(room: roomFinalData) {
    let nbPrograms = 1;
    let isSplit = false;

    if (room.isSplit && room.splitByVacation && !this.isAnesthRes) {
      isSplit = true;
      if (room.isBP) {
        nbPrograms = room.programs.length
      } else {
        nbPrograms = room.surgeonOpenings.length
      }
    } else if (room.isSplit && !room.splitByVacation && !this.isAnesthRes) {
      isSplit = true;
      nbPrograms = 2;
    }

    if (this.isHome) {
      let calc = 20 * nbPrograms;
      if (isSplit) {
        calc -= (nbPrograms * 2)
      }
      return calc + "px"
    } else {
      let calc = (5 * nbPrograms) * OPPOSITE_ZOOM_VALUE;
      if (isSplit) {
        calc -= (nbPrograms * 2)
      }
      return calc + "px"
    }
  }

  onHeaderClick(selectedHeader: string) {
    if (selectedHeader !== this.selectedHeader) {
      this.selectedHeader = selectedHeader;
      switch (this.selectedHeader) {
        case this.headers[0]:
          this.isBlocSelected = true;
          this.isConsultationSelected = false;
          this.isExtracliniqueSelected = false;
          break;
        case this.headers[1]:
          this.isBlocSelected = false;
          this.isConsultationSelected = true;
          this.isExtracliniqueSelected = false;
          break;
        case this.headers[2]:
          this.isBlocSelected = false;
          this.isConsultationSelected = false;
          this.isExtracliniqueSelected = true;
          break;
      
        default:
          this.isBlocSelected = true;
          this.isConsultationSelected = false;
          this.isExtracliniqueSelected = false;
          break;
      }
    }
    this.roomsService.highlightedProfileSmartPlanning.next(undefined)
  }

  prepareConsultationBufferProgramsToSave(): BufferProgram[] {
    const response = [];

    const date = new Date(moment(this.day).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0)
    const dateString = date.toISOString();
    const hospitalId = this.userService.getSelectedHospitals()[0]

    this.consultationSpecialties.forEach(consultationSpecialty => {
        consultationSpecialty.team.forEach(anesth => {
          response.push({
            hospital: hospitalId,
            date: dateString,
            anesthesists: [anesth.profile._id],
            startTime: new Date(dateWithTime(dateString, anesth.time.startTime)),
            endTime: new Date(dateWithTime(dateString, anesth.time.endTime)),
            type: "consultation",
            specialty: consultationSpecialty.specialty._id
          })
        });
    });

    return response
  }

  prepareExtracliniqueBufferProgramsToSave(): BufferProgram[] {
    const response = [];

    const date = new Date(moment(this.day).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0)
    const dateString = date.toISOString();
    const hospitalId = this.userService.getSelectedHospitals()[0]

    this.extracliniqueSpecialty.forEach(extracliniqueSpecialty => {
      extracliniqueSpecialty.team.forEach(anesth => {
          response.push({
            hospital: hospitalId,
            date: dateString,
            anesthesists: [anesth.profile._id],
            startTime: new Date(dateWithTime(dateString, anesth.time.startTime)),
            endTime: new Date(dateWithTime(dateString, anesth.time.endTime)),
            type: "bloc",
            specialty: extracliniqueSpecialty.specialty._id
          })
        });
    });

    return response
  }

  generateBufferFromSurgeonOpenings() {
    let soRooms = this.rooms.filter((room) => !room.isBP)
    let surgeonOpenings = []

    soRooms.forEach(soRoom => {
      if (!soRoom.isSplit && soRoom.team.others.length > 0) {
        surgeonOpenings.push(soRoom)
      } else {
        if (soRoom.isSplit && soRoom.splitByVacation) {
          let notEmpty = false;

          soRoom.surgeonOpenings.forEach(surgeonOpening => {
            if (surgeonOpening.team.others.length > 0) {
              notEmpty = true;
            }
          });

          if (notEmpty) {
            surgeonOpenings.push(soRoom)
          }
        } else if (soRoom.isSplit && !soRoom.splitByVacation) {
          if (soRoom.morningPrograms.team.others.length > 0 ||
              soRoom.afternoonPrograms.team.others.length > 0
          ) {
            surgeonOpenings.push(soRoom)
          }
        }
      }
    });

    const result = []
    const date = new Date(moment(this.day).format("YYYY-MM-DD").toString());
    date.setUTCHours(0, 0, 0, 0)
    const dateString = date.toISOString();
    const hospitalId = this.userService.getSelectedHospitals()[0]

    surgeonOpenings.forEach(surgeonOpening => {
      if (!result.find((resultBP) => String(resultBP.room) === String(surgeonOpening.roomId) &&
      new Date(resultBP.startTime).getTime() === new Date(surgeonOpening.surgeonOpenings[0].startTime).getTime() &&
      new Date(resultBP.endTime).getTime() === new Date(surgeonOpening.surgeonOpenings[0].endTime).getTime())) {
        if (surgeonOpening.isSplit) {
          if (surgeonOpening.splitByVacation) {
            surgeonOpening.surgeonOpenings.forEach((surgeonOpening2) => {
              if (surgeonOpening2.mergedPrograms && surgeonOpening2.mergedPrograms.length > 1) {
                surgeonOpening2.mergedPrograms.forEach(mergedProgram => {
                  result.push({
                    anesthesists: surgeonOpening2.team.others.filter((card) => card.profile.position === "Anesthésiste").map((card) => card.profile),
                    date: dateString,
                    endTime: mergedProgram.endTime,
                    startTime: mergedProgram.startTime,
                    hospital: hospitalId,
                    nurses: surgeonOpening2.team.others.filter((card) => card.profile.position !== "Anesthésiste").map((card) => ({profile: card.profile, role: card.role})),
                    room: surgeonOpening.roomId,
                    surgeon: surgeonOpening2.surgeon._id,
                    type: "bloc"
                  })
                });
              } else {
                result.push({
                  anesthesists: surgeonOpening2.team.others.filter((card) => card.profile.position === "Anesthésiste").map((card) => card.profile),
                  date: dateString,
                  endTime: surgeonOpening2.endTime,
                  startTime: surgeonOpening2.startTime,
                  hospital: hospitalId,
                  nurses: surgeonOpening2.team.others.filter((card) => card.profile.position !== "Anesthésiste").map((card) => ({profile: card.profile, role: card.role})),
                  room: surgeonOpening.roomId,
                  surgeon: surgeonOpening2.surgeon._id,
                  type: "bloc"
                })
              }
            })
          } else {
            surgeonOpening.morningPrograms.surgeonOpenings.forEach((surgeonOpening2) => {
              result.push({
                anesthesists: surgeonOpening.morningPrograms.team.others.filter((card) => card.profile.position === "Anesthésiste").map((card) => card.profile),
                date: dateString,
                endTime: surgeonOpening2.endTime,
                startTime: surgeonOpening2.startTime,
                hospital: hospitalId,
                nurses: surgeonOpening.morningPrograms.team.others.filter((card) => card.profile.position !== "Anesthésiste").map((card) => ({profile: card.profile, role: card.role})),
                room: surgeonOpening.roomId,
                surgeon: surgeonOpening2.surgeon._id,
                type: "bloc"
              })
            })

            surgeonOpening.afternoonPrograms.surgeonOpenings.forEach((surgeonOpening2) => {
              result.push({
                anesthesists: surgeonOpening.afternoonPrograms.team.others.filter((card) => card.profile.position === "Anesthésiste").map((card) => card.profile),
                date: dateString,
                endTime: surgeonOpening2.endTime,
                startTime: surgeonOpening2.startTime,
                hospital: hospitalId,
                nurses: surgeonOpening.afternoonPrograms.team.others.filter((card) => card.profile.position !== "Anesthésiste").map((card) => ({profile: card.profile, role: card.role})),
                room: surgeonOpening.roomId,
                surgeon: surgeonOpening2.surgeon._id,
                type: "bloc"
              })
            })
          }
        } else {
          surgeonOpening.surgeonOpenings.forEach((surgeonOpening2, index) => {
            result.push({
              anesthesists: surgeonOpening.team.others.filter((card) => card.profile.position === "Anesthésiste").map((card) => card.profile),
              date: dateString,
              endTime: surgeonOpening2.endTime,
              startTime: surgeonOpening2.startTime,
              hospital: hospitalId,
              nurses: surgeonOpening.team.others.filter((card) => card.profile.position !== "Anesthésiste").map((card) => ({profile: card.profile, role: card.role})),
              room: surgeonOpening.roomId,
              surgeon: surgeonOpening2.surgeon._id,
              type: "bloc"
            })
          });
        }
      }
    });
   
    return result
  }

  save() {
    // return;
    return new Promise<void>((resolve, reject) => {
      if (this.updateBufferProgramSubscription) {
        this.updateBufferProgramSubscription.unsubscribe();
      }
      this.isLoading = true;
      let consultationBufferProgramsToSave: BufferProgram[] = [], extracliniqueBufferProgramsToSave: BufferProgram[] = [];
      if (this.hospitalHaveAnesthDetailsOption) {
        consultationBufferProgramsToSave = this.prepareConsultationBufferProgramsToSave();
        extracliniqueBufferProgramsToSave = this.prepareExtracliniqueBufferProgramsToSave();
      }

      this.updateBufferProgramSubscription = this.bufferProgramService
        .updateManyBufferProgram(
          this.date,
          this.roomsWithPrograms,
          [].concat(this.generateBufferFromSurgeonOpenings(), consultationBufferProgramsToSave, extracliniqueBufferProgramsToSave),
          this.urgenceProfile,
        )
        .subscribe(
          () => {
            this.toastService.infoToast("L'opération a été prise en charge");
            this.reload();
            resolve();
          },
          (error) => {
            this.isLoading = false;
            this.errorService.handleError(error);
            reject(error);
          }
        );
    })
  }

  onProfileClick(profile: Profile) {
    if (!this.redirectOnClickOnProfile) {
      return;
    }

    if (profile.position === SURGEON) {
      // Redirect to needs page is currentUser is anesth
      if (this.currentUser.profile.position === ANESTHETIST) {
        this.navigationService.navigateTo(
          pages.parSallesBesoins,
          null,
          { date: this.date },
          false
        );
      } else { // Else redirect to by room page
        this.navigationService.navigateTo(
          pages.parSalles,
          null,
          { date: this.date },
          false
        );
      }
    } else { // If nurse redirect to profile page
      this.navigationService.navigateTo(
        pages.profiles + "/" + profile._id,
        null,
        null,
        false
      );
    }
  }

  ngOnDestroy(): void {
    this.saveDate();
    this.roomsService.highlightedProfileSmartPlanning.next(undefined);

    if (this.getSOSubscription) {
      this.getSOSubscription.unsubscribe();
    }

    if (this.getBufferProgramsSubscription) {
      this.getBufferProgramsSubscription.unsubscribe();
    }

    if (this.getRolesSubscription) {
      this.getRolesSubscription.unsubscribe();
    }

    if (this.getParamedicalsSubscription) {
      this.getParamedicalsSubscription.unsubscribe();
    }

    if (this.getFormationsSubscription) {
      this.getFormationsSubscription.unsubscribe();
    }

    if (this.getRoomServiceReloadSubscription) {
      this.getRoomServiceReloadSubscription.unsubscribe();
    }

    if (this.getRoomServiceSplitRoomSubscription) {
      this.getRoomServiceSplitRoomSubscription.unsubscribe();
    }

    if (this.getRoomServiceEmptyRoomSubscription) {
      this.getRoomServiceEmptyRoomSubscription.unsubscribe();
    }

    if (this.getSpecialtiesSubscription) {
      this.getSpecialtiesSubscription.unsubscribe();
    }

    if (this.allNursesSubscription) {
      this.allNursesSubscription.unsubscribe();
    }

    if (this.availableAnesthetistsSubscription) {
      this.availableAnesthetistsSubscription.unsubscribe();
    }

    if (this.dailyNeedsSubscription) {
      this.dailyNeedsSubscription.unsubscribe();
    }
    
    if (this.interventionsSubscription) {
      this.interventionsSubscription.unsubscribe();
    }

    if (this.getNursesRankingsSubscription) {
      this.getNursesRankingsSubscription.unsubscribe();
    }

    if (this.getAnesthsRankingsSubscription) {
      this.getAnesthsRankingsSubscription.unsubscribe();
    }

    if (this.updateBufferProgramSubscription) {
      this.updateBufferProgramSubscription.unsubscribe();
    }

    if (this.getUrgenceProfileSubscription) {
      this.getUrgenceProfileSubscription.unsubscribe();
    }

    if (this.programCommentarySubscription) {
      this.programCommentarySubscription.unsubscribe();
    }
  }
}