import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {PopupManagerService} from './popup-manager.service';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<any> {
  constructor(private dialog: MatDialog, private popupManagerService: PopupManagerService ) {}

  canDeactivate(component: any): Observable<boolean> {
    console.log("canDeactivate")
    if(component.isChangesUnsaved()) {
      return this.popupManagerService.openUnsavedChangesPopup(component.home).afterClosed().pipe(switchMap(result => {
        switch (result) {
          case 'save':
            return from(component.save()).pipe(
              map(() => true),
              catchError(() => of(false))
            );
          case 'cancel':
            return of(false);
          case 'continue':
            return of(true);
        }
      }));
    } else {
      return new Observable(sub => sub.next(true));
    }
  }
}
