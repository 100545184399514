import { Injectable } from '@angular/core';
// To save files
import { saveAs } from 'file-saver';

// pdfmake
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from '../../../themes/font/vfs_fonts_inter';

// Excel
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { UtilisService } from './utilis.service';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(private utilisService: UtilisService) {
    (<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
      Inter: {
        normal: 'Inter-Regular.ttf',
        bold: 'Inter-Bold.ttf',
        italics: 'Inter-Italic.ttf',
      }   
    };
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
  }

  public exportCSV(data: string, fileName: string): void {
    this.saveAsFile(data, `${fileName}.csv`, 'text/csv');
  }

  public exportPDF(docDefinition: any, fileName: string): void {
    pdfMake.createPdf(docDefinition).download(fileName);
  }

  async exportExcel(rows: string[][], fileName: string): Promise<void> {
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet(
      'Effectifs', 
      { properties: { showGridLines: false }, 
      views:[
        { state: 'frozen', ySplit: 6, activeCell: 'A1', showGridLines:false }
      ]});

    // Start of logo
    const logoBase64 = await this.utilisService.getBase64ImageFromURL('assets/images/logos/logo2.svg');
    const logo = workbook.addImage({
      base64: logoBase64.toString(),
      extension: 'png'
    });
    worksheet.addImage(logo, {
      tl: { col: rows[0].length / 2 - 1, row: rows.length + 5 },
      ext: { width: 257.3, height: 80}
    });
    // The next 3 rows are to leave space for our logo
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    const logoStartCell = 'A1';
    const logoEndCell = this.calculateColumnNameFromNumber(rows[0].length) + '4';
    worksheet.mergeCells(`${logoStartCell}:${logoEndCell}`);
    // End of logo
    // Start of availability header
    const availabilityHeader = worksheet.addRow(rows[0]);
    const availableStartCell = 'C5';
    let availableEndCell = this.calculateColumnNameFromNumber(rows[0].indexOf('Absence')) + '5';
    if (this.checkIfCell1IsBeforeCell2InTheSameLine(availableEndCell, availableStartCell)) {
      availableEndCell = availableStartCell;
    }
    const absentStartCell = this.calculateColumnNameFromNumber(this.calculateColumnNumberFromName(availableEndCell.split(/\d/)[0]) + 1) + '5';
    let absentEndCell = this.calculateColumnNameFromNumber(rows[0].length) + '5';
    if (this.checkIfCell1IsBeforeCell2InTheSameLine(absentEndCell, absentStartCell)) {
      absentEndCell = absentStartCell;
    }
    worksheet.mergeCells(`${availableStartCell}:${availableEndCell}`);
    worksheet.mergeCells(`${absentStartCell}:${absentEndCell}`);
    worksheet.getCell(availableStartCell).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFB2D9B2' }
    };
    worksheet.getCell(absentEndCell).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFBB1B1' }
    };
    // End of availability header
    // Start of titles header
    const titlesHeader = worksheet.addRow(rows[1]);
    worksheet.mergeCells('A5:A6');
    worksheet.mergeCells('B5:B6');
    const dayCell = worksheet.getCell('A5');
    dayCell.value = 'Jour';
    dayCell.alignment = { vertical: 'middle', horizontal: 'center' };
    const dateCell = worksheet.getCell('B5');
    dateCell.value = 'Date';
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };
    for (let i = 1; i <= rows[0].length; i += 1) {
      worksheet.getColumn(i).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      worksheet.getColumn(i).width = rows[1][i-1].length < 10 ? 10 : rows[1][i-1].length;
    }
    worksheet.getColumn(1).width = rows[1][0].length < 10 ? 10 : rows[1][0].length;
    worksheet.getColumn(2).width = rows[1][1].length < 10 ? 10 : rows[1][1].length;
    // End of titles header
    worksheet.addRows(rows.slice(2));
    for (let i = 5; i < rows.length + 5; i += 1) {
      for (let j = 1; j <= rows[0].length; j += 1) {
        if ([5, 6].indexOf(i) >= 0) {
          worksheet.getCell(i, j).font = {
            bold: true
          };
        }
        worksheet.getCell(i, j).border = {
          top: {style:'thin'},
          left: {style:'thin'},
          bottom: {style:'thin'},
          right: {style:'thin'}
        };
      }
    }
    worksheet.getCell('A1').border = {
      top: {style:'thin'},
      left: {style:'thin'},
      bottom: {style:'thin'},
      right: {style:'thin'}
    };
    workbook.xlsx.writeBuffer().then((data) => {
      this.saveAsFile(data, `${fileName}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    });
  }

  public calculateColumnNameFromNumber(number: number): string {
    let a: number, b: number, result: string;
    a = number;
    result = "";
    while (number > 0) {
        a = Math.floor((number - 1) / 26);
        b = (number - 1) % 26;
        result = `${String.fromCharCode(b + 'A'.charCodeAt(0))}${result}`;
        number = a;
    }
    return result;
  }

  public checkIfCell1IsBeforeCell2InTheSameLine(cell1: string, cell2: string): boolean {
    const cell1Column = cell1.split(/\d/)[0];
    const cell2Column = cell2.split(/\d/)[0];

    if (this.calculateColumnNumberFromName(cell1Column) <= this.calculateColumnNumberFromName(cell2Column)) {
      return true;
    }

    return false;
  }

  public calculateColumnNumberFromName(column: string): number {
    let result = 0;
    for (let i = 0; i < column.length; i++)
    {
      result *= 26;
      result += column[i].charCodeAt(0) - 'A'.charCodeAt(0) + 1;
    }
    return result;
  }
}