import { NgModule } from "@angular/core";
import { CommonModule, DatePipe, TitleCasePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule, LayoutGapStyleBuilder } from "@angular/flex-layout";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import {
  MatProgressSpinnerModule,
  MatDialogModule,
  MatRadioModule,
  MatPaginatorIntl,
  MatNativeDateModule,
} from "@angular/material";

import { AvatarModule } from "ngx-avatar";
import { NgxPrintModule } from "ngx-print";

import { MaterialModule } from "../material.module";
import { AppOverlayModule } from "./over-lay/over-lay.module";
import {
  ProgressSpinnerComponent,
  ProgressSpinnerModule,
} from "./components/progress-spinner/progress-spinner.module";

import { ProfileService } from "./services/profile.service";
import { anesthNeedsService } from "./services/anesthNeeds.service";
import { SpecialtyService } from "./services/specialty.service";
import { HospitalService } from "./services/hospital.service";
import { OptionService } from "./services/option.service";
import { MaterialService } from "./services/material.service";
import { PatientService } from "./services/patient.service";
import { RoomService } from "./services/room.service";
import { IbodeGraphsService } from "./services/ibode-graphs.service";
import { UserService } from "./services/user.service";
import { ErrorService } from "./services/error.service";
import { ToastService } from "./services/toast.service";
import { HttpInterceptorService } from "../core/services/http-interceptor.service";
import { BufferProgramService } from "./services/buffer-program.service";
import { RealProgramService } from "./services/real-program.service";
import { StatopService } from "./services/statop.service";
import { InterventionService } from "./services/intervention.service";
import { ParamedicalService } from "./services/paramedical.service";

import { PasswordValidator } from "./utils/password.validator";

import { ProfileDal } from "./dal/profile.dal";
import { anesthNeedsDAL } from "./dal/anesth-needs.dal";
import { HospitalDal } from "./dal/hospital.dal";
import { OptionDal } from "./dal/option.dal";
import { MaterialDal } from "./dal/material.dal";
import { PatientDal } from "./dal/patient.dal";
import { RoomDal } from "./dal/room.dal";
import { UserDal } from "./dal/user.dal";
import { BufferProgramDal } from "./dal/buffer-program.dal";
import { RealProgramDal } from "./dal/real-program.dal";
import { SpecialtyDal } from "./dal/specialty.dal";
import { IndicatorDal } from "./dal/indicator.dal";
import { IbodeGraphsDal } from "./dal/ibode-graphs.dal";
import { InterventionDal } from "./dal/intervention.dal";
import { SkillDal } from "./dal/skill.dal";
import { StatopDal } from "./dal/statop.dal";
import { ParamedicalDal } from "./dal/paramedical.dal";

import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { ProfileCardComponent } from "./components/profile-card/profile-card.component";
import { SmartOpAvatarComponent } from "./components/smart-op-avatar/smart-op-avatar.component";
import { DayAvailabilityComponent } from "./components/day-availability/day-availability.component";
import { DisponibilityDialogComponent } from "./components/disponibility-dialog/disponibility-dialog.component";
import { WeekAvailabilitiesComponent } from "./components/week-availabilities/week-availabilities.component";
import { CustomDatePickerSmallComponent } from "./components/custom-date-picker-small/custom-date-picker-small.component";
import { CostumDatePickerWeekComponent } from "./components/costum-date-picker-week/costum-date-picker-week.component";
import { EditPersonalDataComponent } from "./components/edit-personal-data/edit-personal-data.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { CustomDatePickerNormalComponent } from "./components/custom-date-picker-normal/custom-date-picker-normal.component";
import { SurgeonFeedAvatarComponent } from "./components/surgeon-feed-avatar/surgeon-feed-avatar.component";
import { RoomDoctorAgendaComponent } from "./components/room-doctor-agenda/room-doctor-agenda.component";
import { MostFrequentComponent } from "./components/most-frequent/most-frequent.component";
import { DayProgramRecapComponent } from "./components/day-program-recap/day-program-recap.component";
import { AvailabilityLegendsComponent } from "./components/availability-legends/availability-legends.component";
import { ObjectifStatusComponent } from "./components/objectif-status/objectif-status.component";
import { InterventionPopupComponent } from "./components/intervention-popup/intervention-popup.component";
import { ResetPasswordFormComponent } from "./components/reset-password-form/reset-password-form.component";
import { AddProfileDialogComponent } from "./components/add-profile-dialog/add-profile-dialog.component";
import { AddProfileComponent } from "./components/add-profile/add-profile.component";
import { IntializeProfileSkillsComponent } from "./components/intialize-profile-skills/intialize-profile-skills.component";
import { DaDeleteSelectComponent } from "./components/da-delete-select/da-delete-select.component";
import { SearchBarComponent } from "./components/search-bar/search-bar.component";
import { BasicProfileComponent } from "./components/basic-profile/basic-profile.component";

import { SortArrayPipe } from "./pipes/sort-array.pipe";
import { SafeUrlPipe } from "./pipes/safeUrl.pipe";
import { TestIsNullPipe } from "./pipes/test-is-null.pipe";
import { TranslatePipe } from "./pipes/translate.pipe";
import { SkillService } from "./services/skill.service";

import { TransitionGroupComponent, TransitionGroupItemDirective } from './directives/transition-group.directive';
import { MatButtonModule } from '@angular/material/button';
import { AvatarsTooltipComponent } from './components/avatars-tooltip/avatars-tooltip.component';
import { SurgeonsDistributionTooltipComponent } from './components/surgeons-distribution-tooltip/surgeons-distribution-tooltip.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropperPopupComponent } from './components/edit-personal-data/cropper-popup/cropper-popup.component';
import { AddSurgeonDialogComponent } from './components/add-surgeon-dialog/add-surgeon-dialog.component';
import { AddSurgeonComponent } from './components/add-surgeon/add-surgeon.component';
import { SurgeonOpeningComponent } from './components/surgeon-opening/surgeon-opening.component';
import { WeekAvailabilitesOfSurgeonComponent } from './components/week-availabilites-of-surgeon/week-availabilites-of-surgeon.component';
import { DayAvailabilityOfSurgeonComponent } from './components/day-availability-of-surgeon/day-availability-of-surgeon.component';
import { SurgeonOpeningsDal } from './dal/surgeon-openings.dal';
import { FeedAllRoomsVueComponent } from './components/feed-all-rooms-vue/feed-all-rooms-vue.component';
import { AddVacationPopupComponent } from './components/add-vacation-popup/add-vacation-popup.component';
import { CycleDalService } from './dal/cycle.dal.service';
import { EventDal } from './components/event/service/event.dal.service';
import { EventService } from './components/event/service/event.service';
import { DayEventComponent } from './components/day-event/day-event.component';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { FormatHourMinPipe } from './pipes/format-hour-min.pipe';
import { ProfilesPickerDialogComponent } from './components/profiles-picker-dialog/profiles-picker-dialog.component';
import { RefuseReasonDialogComponent } from './components/refuse-reason-dialog/refuse-reason-dialog.component';
import { MessageComponent } from './components/message/message.component';
import { QuillModule } from 'ngx-quill';
import { AddChannelPopupComponent } from './components/add-channel-popup/add-channel-popup.component';
import { MessagingService } from './services/messaging.service';
import { MessagingDal } from './dal/messaging.dal';
import { PaginationComponent } from './components/pagination/pagination.component';
import { getFrenshPaginatorIntl } from './utils/frensh-paginator-intl';
import { UnSavedChangesPopUpComponent } from './components/un-saved-changes-pop-up/un-saved-changes-pop-up.component';
import { FormatMessageConfirmationPopupPipe } from './pipes/format-message-confirmation-popup.pipe';
import { AnnuraireComponent } from './components/annuraire/annuraire.component';
import { AnnuraireIconComponent } from './components/annuraire-icon/annuraire-icon.component';
import { CommissionRegulationBttonComponent } from './components/commission-regulation-btton/commission-regulation-btton.component';
import { HeaderWithTitleComponent } from './components/header-with-title/header-with-title.component';
import { SecondaryHeaderComponent } from './components/secondary-header/secondary-header.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DatePickerShowWeekComponent } from './components/date-picker-show-week/date-picker-show-week.component';
import { ShowWeekCountHeaderComponent } from "./components/date-picker-show-week/show-week-count-header/show-week-count-header.component";
import { deactivateComponentService } from './services/desactivate.service';
import { addVacationHrsuitePopupComponent } from './components/add-vacation-hrsuite/add-vacation-hrsuite-popup.component';
import { VacationDal } from './dal/vacation.dal';
import { VacationService } from './services/vacation.service';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { ProfilesListComponent } from './components/profiles-list/profiles-list.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { ButtonCloseComponent } from './components/button-close/button-close.component';
import { FormatNamePipe } from './pipes/format-name.pipe';
import { UsersListComponent } from './components/users-list/users-list.component';
import { RelaunchAvailabilityComponent } from './components/relaunch-availability/relaunch-availability.component';
import { PopupAvailalabilityRequestInfosComponent } from './components/popup-availalability-request-infos/popup-availalability-request-infos.component';
import { VacatairesSummaryComponent } from './components/vacataires-summary/vacataires-summary.component';
import { NotificationsFeedComponent } from './components/notifications-feed/notifications-feed.component';
import { NotificationDal } from './dal/notification.dal';
import { ReasonDal } from './dal/reason.dal';
import { ReasonService } from './services/reason.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddReasonDialogComponent } from './components/add-reason-dialog/add-reason-dialog.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { DefaultReasonDal } from './dal/defaultReason.dal';
import { DefaultReasonService } from './services/defaultReason.service';
import { RuleDal } from './dal/rule.dal';
import { RuleService } from './services/rule.service';
import { AddRuleDialogComponent } from './components/add-rule-dialog/add-rule-dialog.component';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
// import { EditSpecialtyComponent } from './components/edit-specialty/edit-specialty.component';
import { FeedRoomDialogComponent } from './components/feed-room-dialog/feed-room-dialog.component';
import { RoomContainerComponent } from '../hrsuite/surgeon/room-container/room-container.component';
import { AllInterventionService } from './services/all-intervention.service';
import { AllInterventionDal } from './dal/all-intervention.dal';
import { PasswordChecker } from './components/password-checker/password-checker.component';
import { VimeoComponent } from './components/vimeo/vimeo.component';
import { PrintService } from '../smart-planning/program-recap/print-service/print-service.service';
import { ExportDayComponent } from './components/export-day-popup/export-day-popup.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { DocumentReaderPopup } from './components/document-reader-popup/document-reader-popup.component';
import { GroupedRhValidationDialogComponent } from './components/grouped-rh-validation-dialog/grouped-rh-validation-dialog.component';
import { ProfilesListPopupComponent } from './components/profiles-list-popup/profiles-list-popup.component';
import { ExportService } from './services/export.service';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import {AddValidateRulesDialogComponent} from './components/add-validate-rules-dialog/add-validate-rules-dialog.component';
import {ValidateRulesDal} from "./dal/validate-rules.dal";
import { toIADEPipe } from './pipes/toIADE.pipe';
import { InterventionInfDialogComponent } from './components/interventions-inf-dialog/intervention-inf-dialog.component';
import { AutofillingComponent } from './components/autofilling-popup/autofilling-popup.component';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';
import {ProgramCommentaryService} from "./services/program-commentary.service";
import {ProgramCommentaryDal} from "./dal/program-commentary.dal";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import { ButtonSaveComponent } from './components/ui/button/button.component';
import { ButtonComponent } from './components/button/button.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { HoursInputComponent } from './components/hours-input/hours-input.component';
import { PopupWithInputsTemplateComponent } from './components/popup-with-inputs-template/popup-with-inputs-template.component';
import { ImagePickerComponent } from './components/image-picker/image-picker.component';
import {DefaultPopupComponent} from "./components/default-popup/default-popup.component";
import {PopupManagerService} from "./services/popup-manager.service";
import { RadioButtonGroupComponent } from './components/radio-button-group/radio-button-group.component';
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component';
import { WeeklyProgramConfigurationPopupComponent } from './components/weekly-program-configuration-popup/weekly-program-configuration-popup.component';
import { HeaderComponent } from './components/header/app-header.component';
import { ToggleComponent } from './components/toggle/app-toggle.component';
import { SpecialtyIconComponent } from './components/specialty-icon/specialty-icon.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { SearchActBarComponent } from './components/search-act-bar/search-act-bar.component';
import {InterventionDetailsPopupComponent} from './components/intervention-details-popup/intervention-details-popup.component';
import { DeleteIconComponent } from './components/delete-icon/delete-icon.component';
import { DoctorAgendaElementComponent } from './components/doctor-agenda-element/doctor-agenda-element.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { AddEditSpecialtyComponent } from './components/add-edit-specialty/add-edit-specialty.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import {AnnuairePopupComponent} from './components/annuaire-popup/annuaire-popup.component';
import { LegendComponent } from './components/legend/legend.component';
import {ReadInPopupComponent} from './components/read-in-popup/read-in-popup.component';
import { ProfilesSuggestionsComponent } from './components/profiles-suggestions/profiles-suggestions.component';
import { HoursPopupComponent } from './components/feed-all-rooms-vue/hours-popup/hours-popup.component';
import { MoveInterventionPopupComponent } from './components/feed-all-rooms-vue/move-intervention-popup/move-intervention-popup.component';
import { FormatSeniorityPipe } from './pipes/format-seniority.pipe';
import { FichePosteComponent } from './components/fiche-poste/fiche-poste.component';
import { ExclusionDal } from './dal/exclusion.dal';
import { WishDal } from './dal/wish.dal';
import { EditWishPopupComponent } from './components/fiche-poste/edit-wish-popup/edit-wish-popup.component';
import { AddWishExclusionPopupComponent } from './components/fiche-poste/add-wish-exclusion-popup/add-wish-exclusion-popup.component';
import { RecurrenceDal } from './dal/recurrence.dal';
import { AddEditRecurrenceComponent } from './components/fiche-poste/add-edit-recurrence/add-edit-recurrence.component';
import { FilterPopupComponent } from './components/filter-popup/filter-popup.component';
import { ExchangePopupComponent } from './components/exchange-popup/exchange-popup.component';
import { BoxExchangeComponent } from './components/box-exchange/box-exchange.component';
import { PersonToExchangeComponent } from './components/person-to-exchange/person-to-exchange.component';
import { SearchBarExtracliniqueComponent } from './components/search-bar-extraclinique/search-bar-extraclinique.component';
import { PopupSwitchRequestInfosComponent } from './components/popup-switch-request-infos/popup-switch-request-infos.component';
import { AutofillingService } from './services/autofilling.service';
import { AutofillingDal } from './dal/autofilling.dal';
import { BlocProgramComponent } from './components/bloc-program/bloc-program.component';
import { BufferTableComponent } from './components/buffer-table/buffer-table.component';
import { isNaNPipe } from './pipes/isNaN.pipe';
import { FilterSpecialties } from './pipes/filterSpecialties.pipe';
import { DoghnutChartComponent } from './components/doghnut-chart/doghnut-chart.component';
import { GardeTableComponent } from './components/garde-table/garde-table.component';
import { RoundPipe } from './pipes/math-round.pipe';
import { SliderInputComponent } from './components/slider-input/slider-input.component';
import { PopupSelectHospitalComponent } from './components/popup-select-hospital/popup-select-hospital.component';
import { SpecialtiesHoverComponent } from './components/specialties-hover/specialties-hover.component';
import { ImportProfileDialogComponent } from './components/import-profile-dialog/import-profile-dialog.component';
import { HospitalsHoverComponent } from './components/hospitals-hover/hospitals-hover.component';
import { ExitDialogComponent } from './components/exit-dialog/exit-dialog.component';
import { ConfirmationPopupComponent } from "./components/confirmation-popup/confirmation-popup.component";
import { SelectRoomsPopupComponent } from "./components/select-rooms/select-rooms-popup.component";

import { ImportDoctorAgendasPopupComponent } from "./components/popup-import-doctor-agendas/popup-import-doctor-agendas.component";
import { ImportRealProgramsPopupComponent } from "./components/popup-import-real-programs/popup-import-real-programs.component";

import { TimeAlertDal } from "./dal/timeAlerts.dal";
import { TimeAlertService } from "./services/timeAlert.service";
import { AddTimeAlertDialogComponent } from "./components/add-time-alert-popup/add-time-alert-dialog.component";
import { PythonAPIDal } from "./dal/pythonAPI.dal";
import { PythonAPIService } from "./services/pythonApi.service";
import { ImportCalendarsPopupComponent } from "./components/popup-import-calendars/popup-import-calendars.component";
import { NeedsPopupComponent } from "./components/needs-popup/needs-popup.component";
import { LevelUpRequestDAL } from "./dal/levelUpRequest.dal";
import { LevelUpRequestService } from "./services/levelUpRequest.service";
import { EffectiveNeedsComponent } from './components/effective-needs/effective-needs.component';
import { EffectiveNeedsDetailsPopupComponent } from './components/effective-needs/effective-needs-details-popup/effective-needs-details-popup.component';
import { DataColumnComponent } from "./components/day-program-recap/data-column/data-column.component";
import { AvailableProfilesComponent } from "./components/day-program-recap/available-profiles/available-profiles.component";
import { DataDetailPopupComponent } from "./components/day-program-recap/data-detail-popup/data-detail-popup.component";
import { TeamCardsComponent } from "./components/day-program-recap/team-cards/team-cards.component";
import { AnesthWeekPopupComponent } from "./components/day-program-recap/anesth-week-popup/anesth-week-popup.component";
import { DayProgramRecapOldComponent } from "./components/day-program-recap.old/day-program-recap-old.component";
import { DatePickerShowWeekService } from "./components/date-picker-show-week/date-picker-show-week.service";
import { RetryInterceptor } from "../core/services/retry-interceptor.service";
import { UserConfigurationDal } from "./dal/userConfiguration.dal";
import { UserConfigurationService } from "./services/userConfiguration.service";
import { MyProgramHebdoComponent } from "../smart-planning/my-program/my-program-hebdo/my-program-hebdo.component";
import { PortalModule } from "@angular/cdk/portal";
import { MyProgramPopupComponent } from "../smart-planning/my-program/my-program-popup/my-program-popup.component";
import { WeekVuePopupComponent } from "../smart-planning/week-vue/week-vue-popup/week-vue-popup.component";

import { DateToTimePipe } from "./pipes/date-to-time.pipe";
import { ReasonMapDal } from "./dal/reasonMap.dal";
import { ReasonMapService } from "./services/reasonMap.service";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { CustomLayoutGapStyleBuilder } from "./utils/custom-gap-builder";
//import { ExchangePopupComponent } from './components/exchange-popup/exchange-popup.component';

@NgModule({
  declarations: [
    DayProgramRecapOldComponent,
    MoveInterventionPopupComponent,
    ImportDoctorAgendasPopupComponent,
    ImportCalendarsPopupComponent,
    ImportRealProgramsPopupComponent,
    HoursPopupComponent,
    ProfilesSuggestionsComponent,
    ReadInPopupComponent,
    AnnuairePopupComponent,
    DefaultPopupComponent,
    ConfirmationPopupComponent,
    SelectRoomsPopupComponent,
    RoomContainerComponent,
    SurgeonOpeningComponent,
    WeekAvailabilitesOfSurgeonComponent,
    DayAvailabilityOfSurgeonComponent,
    ConfirmDialogComponent,
    FeedRoomDialogComponent,
    AddValidateRulesDialogComponent,
    EditPersonalDataComponent,
    ProfileCardComponent,
    SmartOpAvatarComponent,
    DayAvailabilityComponent,
    DisponibilityDialogComponent,
    WeekAvailabilitiesComponent,
    CustomDatePickerSmallComponent,
    CostumDatePickerWeekComponent,
    ChangePasswordComponent,
    CustomDatePickerNormalComponent,
    SurgeonFeedAvatarComponent,
    RoomDoctorAgendaComponent,
    CostumDatePickerWeekComponent,
    DayProgramRecapComponent,
    DataColumnComponent,
    DataDetailPopupComponent,
    AnesthWeekPopupComponent,
    AvailableProfilesComponent,
    MostFrequentComponent,
    AvailabilityLegendsComponent,
    ObjectifStatusComponent,
    InterventionPopupComponent,
    NeedsPopupComponent,
    ResetPasswordFormComponent,
    TransitionGroupItemDirective,
    TransitionGroupComponent,
    AddProfileComponent,
    AddProfileDialogComponent,
    IntializeProfileSkillsComponent,
    DaDeleteSelectComponent,
    AvatarsTooltipComponent,
    SurgeonsDistributionTooltipComponent,
    AddSurgeonDialogComponent,
    AddSurgeonComponent,
    SearchBarComponent,
    CropperPopupComponent,
    BasicProfileComponent,
    FeedAllRoomsVueComponent,
    AddVacationPopupComponent,
    DayEventComponent,
    ProfilesPickerDialogComponent,
    RefuseReasonDialogComponent,
    MessageComponent,
    AddChannelPopupComponent,
    PaginationComponent,
    UnSavedChangesPopUpComponent,
    AnnuraireIconComponent,
    AnnuraireComponent,
    CommissionRegulationBttonComponent,
    HeaderWithTitleComponent,
    SecondaryHeaderComponent,
    DatePickerComponent,
    DatePickerShowWeekComponent,
    ShowWeekCountHeaderComponent,
    ButtonSaveComponent,
    addVacationHrsuitePopupComponent,
    InterventionDetailsPopupComponent,
    ProfilesListComponent,
    AddUserComponent,
    ButtonCloseComponent,
    UsersListComponent,
    RelaunchAvailabilityComponent,
    PopupAvailalabilityRequestInfosComponent,
    TeamCardsComponent,
    PopupSwitchRequestInfosComponent,
    VacatairesSummaryComponent,
    NotificationsFeedComponent,
    AddReasonDialogComponent,
    AddRuleDialogComponent,
    AddTimeAlertDialogComponent,
    // EditSpecialtyComponent,
    PasswordChecker,
    VimeoComponent,
    ExportDayComponent,
    //ExchangePopupComponent,
    AutofillingComponent,
    DocumentReaderPopup,
    GroupedRhValidationDialogComponent,
    ProfilesListPopupComponent,
    InterventionInfDialogComponent,
    ButtonComponent,
    TextInputComponent,
    HeaderComponent,
    ToggleComponent,
    FormatMessageConfirmationPopupPipe,
    SortArrayPipe,
    TranslatePipe,
    TestIsNullPipe,
    CapitalizePipe,
    SafeUrlPipe,
    FormatTimePipe,
    FormatHourMinPipe,
    CapitalizePipe,
    FormatNamePipe,
    toIADEPipe,
    RoundPipe,
    DateInputComponent,
    HoursInputComponent,
    PopupWithInputsTemplateComponent,
    ImagePickerComponent,
    RadioButtonGroupComponent,
    CheckboxGroupComponent,
    WeeklyProgramConfigurationPopupComponent,
    SpecialtyIconComponent,
    TextareaComponent,
    SearchActBarComponent,
    DeleteIconComponent,
    DoctorAgendaElementComponent,
    ToggleSwitchComponent,
    AddEditSpecialtyComponent,
    ColorPickerComponent,
    LegendComponent,
    FormatSeniorityPipe,
    FichePosteComponent,
    EditWishPopupComponent,
    AddWishExclusionPopupComponent,
    AddEditRecurrenceComponent,
    FilterPopupComponent,
    ExchangePopupComponent,
    BoxExchangeComponent,
    PersonToExchangeComponent,
    SearchBarExtracliniqueComponent,
    PopupSwitchRequestInfosComponent,
    BlocProgramComponent,
    BufferTableComponent,
    isNaNPipe,
    FilterSpecialties,
    DoghnutChartComponent,
    GardeTableComponent,
    SliderInputComponent,
    PopupSelectHospitalComponent,
    SpecialtiesHoverComponent,
    ImportProfileDialogComponent,
    HospitalsHoverComponent,
    ExitDialogComponent,
    EffectiveNeedsComponent,
    EffectiveNeedsDetailsPopupComponent,
    MyProgramHebdoComponent,
    MyProgramPopupComponent,
    DateToTimePipe,
    WeekVuePopupComponent,
    TruncatePipe
  ],
  imports: [
    NgxSkeletonLoaderModule,
    MatDialogModule,
    ImageCropperModule,
    NgxMaterialTimepickerModule,
    MatRadioModule,
    CommonModule,
    MaterialModule,
    RouterModule,
    DragDropModule,
    FlexLayoutModule,
    AvatarModule,
    AppOverlayModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    NgxMatFileInputModule,
    PdfJsViewerModule,
    NgbModule,
    ColorPickerModule,
    QuillModule.forRoot({
      placeholder: "",
    }),
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    OverlayscrollbarsModule,
    PortalModule,
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getFrenshPaginatorIntl() },
    { provide: LayoutGapStyleBuilder, useClass: CustomLayoutGapStyleBuilder },
    // SpecialtyColorService,
    TitleCasePipe,
    EventService,
    TimeAlertDal,
    TimeAlertService,
    EventDal,
    ValidateRulesDal,
    CapitalizePipe,
    SurgeonOpeningsDal,
    NotificationDal,
    HospitalDal,
    OptionDal,
    MaterialDal,
    PatientDal,
    ProfileDal,
    anesthNeedsDAL,
    CycleDalService,
    RoomDal,
    UserDal,
    RealProgramDal,
    IbodeGraphsDal,
    SkillDal,
    SpecialtyDal,
    IndicatorDal,
    StatopDal,
    ParamedicalDal,
    UserConfigurationDal,
    InterventionDal,
    DefaultReasonDal,
    DefaultReasonService,
    InterventionService,
    ParamedicalService,
    UserConfigurationService,
    AllInterventionDal,
    AllInterventionService,
    SkillService,
    HospitalService,
    OptionService,
    MaterialService,
    PatientService,
    ProfileService,
    anesthNeedsService,
    RoomService,
    UserService,
    IbodeGraphsService,
    BufferProgramService,
    BufferProgramDal,
    ProgramCommentaryService,
    ProgramCommentaryDal,
    RealProgramService,
    SpecialtyService,
    StatopService,
    ToastService,
    deactivateComponentService,
    ErrorService,
    MessagingService,
    MessagingDal,
    VacationDal,
    VacationService,
    ExportService,
    PrintService,
    ReasonDal,
    ReasonService,
    RuleDal,
    RuleService,
    PopupManagerService,
    DatePipe,
    FormatMessageConfirmationPopupPipe,
    TranslatePipe,
    CapitalizePipe,
    toIADEPipe,
    FormatSeniorityPipe,
    ExclusionDal,
    WishDal,
    RecurrenceDal,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    PasswordValidator,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    ExportService,
    AutofillingService,
    AutofillingDal,
    isNaNPipe,
    FilterSpecialties,
    PythonAPIDal,
    PythonAPIService,
    LevelUpRequestDAL,
    LevelUpRequestService,
    DatePickerShowWeekService,
    ReasonMapDal,
    ReasonMapService,
    TruncatePipe
  ],
  entryComponents: [
    MoveInterventionPopupComponent,
    ImportDoctorAgendasPopupComponent,
    ImportCalendarsPopupComponent,
    ImportRealProgramsPopupComponent,
    HoursPopupComponent,
    ProfilesSuggestionsComponent,
    ReadInPopupComponent,
    AnnuairePopupComponent,
    InterventionDetailsPopupComponent,
    AddValidateRulesDialogComponent,
    NotificationsFeedComponent,
    RelaunchAvailabilityComponent,
    UsersListComponent,
    AddUserComponent,
    ProfilesListComponent,
    UnSavedChangesPopUpComponent,
    ExitDialogComponent,
    ProfilesPickerDialogComponent,
    RefuseReasonDialogComponent,
    SurgeonOpeningComponent,
    CropperPopupComponent,
    ConfirmDialogComponent,
    FeedRoomDialogComponent,
    ProgressSpinnerComponent,
    DisponibilityDialogComponent,
    InterventionPopupComponent,
    NeedsPopupComponent,
    AddProfileDialogComponent,
    DaDeleteSelectComponent,
    AvatarsTooltipComponent,
    SurgeonsDistributionTooltipComponent,
    AddSurgeonDialogComponent,
    AddVacationPopupComponent,
    AddChannelPopupComponent,
    addVacationHrsuitePopupComponent,
    // EditSpecialtyComponent,
    ExportDayComponent,
    ExchangePopupComponent,
    AutofillingComponent,
    DefaultPopupComponent,
    ConfirmationPopupComponent,
    SelectRoomsPopupComponent,
    RoomContainerComponent,
    DocumentReaderPopup,
    GroupedRhValidationDialogComponent,
    AddReasonDialogComponent,
    AddRuleDialogComponent,
    AddTimeAlertDialogComponent,
    ProfilesListPopupComponent,
    InterventionInfDialogComponent,
    PopupWithInputsTemplateComponent,
    ImagePickerComponent,
    AddEditSpecialtyComponent,
    EditWishPopupComponent,
    AddWishExclusionPopupComponent,
    AddEditRecurrenceComponent,
    FilterPopupComponent,
    PopupSelectHospitalComponent,
    SpecialtiesHoverComponent,
    ImportProfileDialogComponent,
    HospitalsHoverComponent,
    EffectiveNeedsDetailsPopupComponent,
    DataDetailPopupComponent,
    AnesthWeekPopupComponent,
    ShowWeekCountHeaderComponent,
    TextInputComponent,
    WeeklyProgramConfigurationPopupComponent
  ],
  exports: [
    DayProgramRecapOldComponent,
    RoomContainerComponent,
    TeamCardsComponent,
    PopupAvailalabilityRequestInfosComponent,
    PopupSwitchRequestInfosComponent,
    ButtonCloseComponent,
    ButtonSaveComponent,
    DatePickerComponent,
    DatePickerShowWeekComponent,
    ShowWeekCountHeaderComponent,
    SecondaryHeaderComponent,
    HeaderWithTitleComponent,
    CommissionRegulationBttonComponent,
    VacatairesSummaryComponent,
    AnnuraireComponent,
    AnnuraireIconComponent,
    UnSavedChangesPopUpComponent,
    DayEventComponent,
    WeekAvailabilitesOfSurgeonComponent,
    AddSurgeonDialogComponent,
    AddSurgeonComponent,
    PaginationComponent,
    NgxMaterialTimepickerModule,
    NgxMatFileInputModule,
    CropperPopupComponent,
    MatDialogModule,
    MatRadioModule,
    MatButtonModule,
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AvatarModule,
    QuillModule,
    NgbModule,
    MostFrequentComponent,
    EditPersonalDataComponent,
    DayProgramRecapComponent,
    AvailableProfilesComponent,
    DataColumnComponent,
    DataDetailPopupComponent,
    AnesthWeekPopupComponent,
    ConfirmDialogComponent,
    FeedRoomDialogComponent,
    ProfileCardComponent,
    SmartOpAvatarComponent,
    ProgressSpinnerModule,
    DayAvailabilityComponent,
    DisponibilityDialogComponent,
    WeekAvailabilitiesComponent,
    CustomDatePickerSmallComponent,
    CostumDatePickerWeekComponent,
    ChangePasswordComponent,
    CustomDatePickerNormalComponent,
    SurgeonFeedAvatarComponent,
    RoomDoctorAgendaComponent,
    CostumDatePickerWeekComponent,
    AvailabilityLegendsComponent,
    ObjectifStatusComponent,
    AvailabilityLegendsComponent,
    ObjectifStatusComponent,
    ResetPasswordFormComponent,
    TransitionGroupItemDirective,
    TransitionGroupComponent,
    AddProfileComponent,
    AddProfileDialogComponent,
    SearchBarComponent,
    BasicProfileComponent,
    FeedAllRoomsVueComponent,
    MessageComponent,
    AddChannelPopupComponent,
    addVacationHrsuitePopupComponent,
    VimeoComponent,
    ExportDayComponent,
    ExchangePopupComponent,
    AutofillingComponent,
    PdfJsViewerModule,
    DocumentReaderPopup,
    AddReasonDialogComponent,
    AddRuleDialogComponent,
    AddTimeAlertDialogComponent,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    InterventionInfDialogComponent,
    NgxMatMomentModule,
    PasswordChecker,
    OverlayscrollbarsModule,
    ButtonComponent,
    TextInputComponent,
    HeaderComponent,
    ToggleComponent,
    FormatNamePipe,
    SortArrayPipe,
    TestIsNullPipe,
    SafeUrlPipe,
    SortArrayPipe,
    TranslatePipe,
    CapitalizePipe,
    toIADEPipe,
    FormatTimePipe,
    FormatHourMinPipe,
    DateInputComponent,
    HoursInputComponent,
    ImagePickerComponent,
    RadioButtonGroupComponent,
    CheckboxGroupComponent,
    WeeklyProgramConfigurationPopupComponent,
    SpecialtyIconComponent,
    TextareaComponent,
    NgxPrintModule,
    SearchActBarComponent,
    DeleteIconComponent,
    DoctorAgendaElementComponent,
    ToggleSwitchComponent,
    ColorPickerComponent,
    LegendComponent,
    NgxSkeletonLoaderModule,
    FormatSeniorityPipe,
    FichePosteComponent,
    FilterPopupComponent,
    BufferTableComponent,
    GardeTableComponent,
    isNaNPipe,
    FilterSpecialties,
    DoghnutChartComponent,
    RoundPipe,
    SliderInputComponent,
    PopupSelectHospitalComponent,
    SpecialtiesHoverComponent,
    ImportProfileDialogComponent,
    HospitalsHoverComponent,
    EffectiveNeedsComponent,
    MyProgramHebdoComponent,
    PortalModule,
    MyProgramPopupComponent,
    DateToTimePipe,
    WeekVuePopupComponent,
    TruncatePipe
  ]
})
export class SharedModule {}
