import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SurgeonOpening } from '../models/surgeonOpenings.model';
import { urlEndPoints } from '../config/end-points';
import { Profile } from '../models/profile.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurgeonOpeningsDal {

  constructor(private httpClient: HttpClient) {
  }
  getAllSOByWeek(startDate: string, endDate: string): Observable<Profile[]> {
    return this.httpClient.get<any>(`${urlEndPoints.openings}getByWeek/?startDate=${startDate}&endDate=${endDate}`);
  }

  getSurgeons(_id: string, date: string): Observable<Profile[]> {
    return this.httpClient.get<any>(`${urlEndPoints.openings}room/${_id}?date=${date}`);
  }

  getSurRHRec(profileId: string, startDate: string, endDate: string): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.openings}openingRecap?surgeonId=${profileId}&fromDate=${startDate}&toDate=${endDate}`);
  }

  getNeeds(date: string): Observable<any> {
    return this.httpClient.get<any>(`${urlEndPoints.openings}needsByOpenings?date=${date}`);
  }

  getWeekOpenings(surgeonId: string, date: string): Observable<SurgeonOpening[]> {
    return this.httpClient.get<SurgeonOpening[]>(`${urlEndPoints.openings}surgeon/${surgeonId}?date=${date}`);
  }

  updateManySO(profile: Profile, fromDate: string, toDate: string, body: any): Observable<SurgeonOpening[]> {
    return this.httpClient.post<SurgeonOpening[]>(`${urlEndPoints.openings}createOrUpdateMany?surgeonId=${profile._id}&fromDate=${fromDate}&toDate=${toDate}`, body);
  }

  modifySurgeonOpeningNeed(surgeonOpeningId: string, needs: any): Observable<any> {
    return this.httpClient.post<any>(`${urlEndPoints.openings}modifySurgeonOpeningNeeds`, {surgeonOpeningId, needs});
  }

  checkExistence(startDate: string, endDate: string, concernedRooms): Observable<any> {
    return this.httpClient.post<any>(`${urlEndPoints.openings}checkExistence`, {startDate, endDate, concernedRooms});
  }
}
