import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { MatPaginator } from "@angular/material";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  @Input() pageSize: number;
  @Input() isOutputNotArray: boolean = false;
  @Input() allItems: any[] = [];
  @Input() page: number = 0;
  @Input() length : number = 1;
  @Output() onSelectPage: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;

  constructor() {}

  ngOnInit(): void {    
    this.getItemPage();
  }

  getItemPage(event?) {
    if (event) {
      this.page = event.pageIndex;
      this.onSelectPage.emit(this.page);
    }

    if(this.isOutputNotArray === false)
      this.sendArray();
  }

  sendArray(){
    const firstIndex = this.page * this.pageSize;
    const lastIndex = this.pageSize * (this.page + 1) - 1;
    let arr = [];
    for (var i = firstIndex; i <= lastIndex && i < this.allItems.length; i++) {
      arr.push(this.allItems[i]);
    }

    arr = arr.filter((item) => item);

    this.onSelectPage.emit(arr);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.page && changes.page.currentValue === -1)
    {
      if (this.paginator)
        this.paginator.pageIndex = 0;
    }
    if (
      (changes.pageSize &&
        changes.pageSize.currentValue != changes.pageSize.previousValue) ||
      (changes.allItems &&
        JSON.stringify(changes.allItems.currentValue) !=
          JSON.stringify(changes.allItems.previousValue))
    ) {
      let event;
      const maxPage = Math.ceil(this.isOutputNotArray ? this.length : this.allItems.length / this.pageSize) - 1;
      if (this.page > maxPage) {
        event = { pageIndex: maxPage };
        if (this.paginator) {
          this.paginator.pageIndex = maxPage;
        }
      }
      this.getItemPage(event);
    }
  }
}
