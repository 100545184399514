import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { Subscription } from "rxjs";
import * as moment from "moment";

import { ProfileService } from "src/app/shared/services/profile.service";
import { DisponibilityDialogComponent } from "../disponibility-dialog/disponibility-dialog.component";
import { isNurse, Profile } from "../../models/profile.model";
import { Calendar, isPartiallyAvailable, isAvailable } from "../../models/calendar.model";
import { ErrorService } from "../../services/error.service";
import { ANESTHESIST_WITH_ACCENT, ANESTHETIST, IADE, NURSE, NURSE_TYPES, OPPOSITE_ZOOM_VALUE } from "../../const/glabals.const";
import { CycleService } from "../../services/cycle.service";
import { cycle } from "../../models/cycle.model";
import { UserService } from "../../services/user.service";
import { User } from "../../models/user.model";
import { UtilisService } from "../../services/utilis.service";
import { TranslatePipe } from "./../../pipes/translate.pipe";
import { pages } from "../../config/pages";
import { Router } from "@angular/router";
import { ShareCalendarRequestService } from "../../services/share-calendar-request.service";
import { Reason } from "../../models/reason.model";
import { Rule } from "../../models/rule.model";
import { HrsuiteService } from "src/app/hrsuite/hrsuite.service";
import { element } from "protractor";
import { HospitalService } from "../../services/hospital.service";
import { StorageService } from "src/app/core/services/storage.service";
import { CalendarService } from "../../services/calendar.service";
import { getFirstHospitalSelectedData, getSelectedHospitalData } from "../../utils/cross-functions";
import { ReasonMap } from "../../models/reasonMap.model";

@Component({
  selector: "app-week-availabilities",
  templateUrl: "./week-availabilities.component.html",
  styleUrls: ["./week-availabilities.component.scss"],
})
export class WeekAvailabilitiesComponent
  implements OnInit, OnDestroy, OnChanges {
  @Input() cycle: cycle;
  @Input() isCycleConfig: boolean;
  @Input() weekNumber: number;
  @Input() lineIndex: number;
  @Input() day: any[];
  @Input() hoverDate: Date;
  @Input() selectedPeriod: Date[];
  @Input() enableSelection: boolean;
  @Input() weekDays: any[];
  @Input() profile: Profile;
  @Input() position: string;
  @Input() view: string;
  @Input() isAccountView: boolean;
  @Input() isNight: boolean;
  @Input() weekIndex: string;
  @Input() reasons: any[];
  @Input() reasonsMaps: ReasonMap[];
  @Input() rules: Rule[];
  @Output() daySelected = new EventEmitter();
  @Output() dayHover = new EventEmitter();
  @Output() notificationRequestAvailabilityHover = new EventEmitter();
  @Output() popupClosed = new EventEmitter();
  @Output() dataChange = new EventEmitter();
  @Output() actualiseChange = new EventEmitter();
  @Output() onSwitch = new EventEmitter();
  @Output() onClearSelection = new EventEmitter();

  @Output() getNextWeek = new EventEmitter();

  shortDates;

  public weekCalendars: any[];
  public rawWeekCalendars: any[];
  public selectedDays: number[];

  private weekCalendarsSubscription: Subscription;
  private afterDialogCloseSubscription: Subscription;
  private weekCalendarsOfCycleSubscription: Subscription;
  private anesthesistesMessagesSubscription: Subscription;
  currentUser: User;
  oldIsOver: any;
  public doesHospitalHaveDayNightOption: boolean;
  public isMultiHospital: boolean = false;

  constructor(
    private cycleService: CycleService,
    private shareCalendarRequestService: ShareCalendarRequestService,
    private elRef: ElementRef,
    private router: Router,
    private profileService: ProfileService,
    private translatePipe: TranslatePipe,
    private utilService: UtilisService,
    private userService: UserService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private hrsuiteService: HrsuiteService,
    private hospitalService: HospitalService,
    private storageService: StorageService,
    private calendarService: CalendarService
  ) {
    this.selectedDays = [];
    this.doesHospitalHaveDayNightOption = this.hospitalService.doesHospitalHaveDayNightOption();
    this.anesthesistesMessagesSubscription = this.hrsuiteService.anesthesistesMessages.subscribe((data) => {
      this.receiveMessage(data);
    });
  }

  receiveMessage(data: any): void {
    if (data.isExchange && data.profile2 && data.profile2.profile && this.profile) {
      if (data.profile2.profile._id == this.profile._id) {
        this.refillProfilesWeekCalendars();
      }
    }
  }

  get isNurse(): boolean {
    return isNurse(this.profile);
  }

  ngOnInit() {
    this.isMultiHospital = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.currentUser = this.userService.getCurrentUser();
    if (this.isCycleConfig) {
      this.getCalendarsOfWeek(this.cycle, this.weekNumber);
    }
    this.shortDates = this.getShortDates(this.weekDays);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.day && changes.day.previousValue) || (changes.isNight && changes.isNight.isFirstChange())) {
      this.refillProfilesWeekCalendars();
    } else {
      this.refreshDataAfterDayNightChange();
    }
    
    this.shortDates = this.getShortDates(this.weekDays);
  }

  getShortDate(date: string): string {
    let momentDate = moment(date);
    return momentDate.format("DD/MM");
  }

  getShortDates(dates: string[]): string[] {
    let shortDates: string[] = [];
    for (let date of dates) {
      let shortDate = this.getShortDate(date);
      shortDates.push(shortDate);
    }
    return shortDates;
  }

  getProfileWeekAvailabilities(info?: any) {
    if (this.weekCalendarsSubscription) {
      this.weekCalendarsSubscription.unsubscribe();
    }
    this.weekCalendarsSubscription = this.profileService
      .getProfileWeekAvailability(
        this.profile._id,
        moment(this.day).toISOString()
      )
      .subscribe(
        (weekCalendars) => {
          if (info) {
            this.dataChange.emit(info);
          }
          this.rawWeekCalendars = weekCalendars;
          this.refreshDataAfterDayNightChange();
        },
        (error) => this.errorService.handleError(error)
      );
  }

  refreshDataAfterDayNightChange(): void {
    if (this.rawWeekCalendars) {
      if (this.isNight) {
        this.weekCalendars = this.rawWeekCalendars.map((date) => date.nightCalendar);
      } else {
        this.weekCalendars = this.rawWeekCalendars.map((date) => date.dayCalendar);
      }
    }
  }

  getCalendarsOfWeek(cycle: cycle, weekNumber: number) {
    if (cycle) {
      if (this.weekCalendarsOfCycleSubscription) {
        this.weekCalendarsOfCycleSubscription.unsubscribe();
      }
      this.weekCalendarsOfCycleSubscription = this.cycleService
        .getCalendarsOfCycle(this.profile._id, cycle, weekNumber)
        .subscribe(
          (weekCalendars) => {
            this.weekCalendars = weekCalendars;
            //We move sundayCalendar to last position
            this.utilService.moveFirstItemToLastPosition(this.weekCalendars);
          },
          (error) => this.errorService.handleError(error)
        );
    } else {
      this.weekCalendars = [null, null, null, null, null];
    }
  }

  refillProfilesWeekCalendars(info: any = null) {
    this.weekCalendars = null;
    this.getProfileWeekAvailabilities(info);
  }

  openDialog(dayIndex: number): void {

    if (!this.isCycleConfig && this.checkIfProfileHaveCalendarsInOtherHospitals(dayIndex)) {
      this.errorService.simpleErrorMsg("Ce profil a une disponibilité associée à un autre service ce jour là.");
      this.onClearSelection.emit();
      return;
    }
    
    const data = this.getDataToSent(dayIndex)
    var classpanel = ["white", 'disponibility-dialog']
    if (this.isNight)
      classpanel = ["dark", 'disponibility-dialog']

    const dialogRef = this.dialog.open(DisponibilityDialogComponent, {
      width: '400px',
      panelClass: classpanel,
      data,
    });
    if (this.afterDialogCloseSubscription) {
      this.afterDialogCloseSubscription.unsubscribe();
    }
    this.afterDialogCloseSubscription = dialogRef
      .afterClosed()
      .subscribe((result) => {
        this.onSwitch.emit()
        this.popupClosed.emit(result);
        if (result) {
          if (this.isCycleConfig) {
            this.getCalendarsOfWeek(this.cycle, this.weekNumber);
            this.dataChange.emit();
          } else {
            if (result.refreshNextWeek) {
              this.getNextWeek.emit(this.weekIndex);
            }

            if (result.isExchange) {
              // We refresh effectif in the header using the subject hrsuiteService.anesthesistesMessages
              this.refillProfilesWeekCalendars();
            } else {
              // We refresh effectif in the header directly
              this.refillProfilesWeekCalendars(result);
            }
          }
        }
      });
  }

  checkIfProfileHaveCalendarsInOtherHospitals(dayIndex: number): boolean {
    const datesObjet = [this.rawWeekCalendars[dayIndex]];
    const doesProfileHaveCalendarInOtheHospital = datesObjet.some((item) => this.isNight ? item.haveCalendarInOtherHospitalsAtNight : item.haveCalendarInOtherHospitalsAtDay);
    return doesProfileHaveCalendarInOtheHospital;
  }

  getDataToSent(dayIndex: number) {
    if (this.isCycleConfig) {
      return {
        profile: this.profile,
        weekNumber: this.weekNumber,
        dayIndex: dayIndex,
        isCycleConfig: true,
        cycle: this.cycle,
        cycleCalendar: this.weekCalendars[dayIndex],
        reasons: this.reasons,
        reasonsMaps: this.reasonsMaps,
        rules: this.rules,
        weekDays: this.weekDays
      };
    } else {
      let date = this.weekDays[dayIndex];
      return {
        profile: this.profile,
        weekCalendars: this.weekCalendars,
        dayIndex: dayIndex,
        date: date,
        isCycleConfig: false,
        view: this.view,
        reasons: this.reasons,
        reasonsMaps: this.reasonsMaps,
        rules: this.rules,
        isNight: this.isNight,
        weekDays: this.weekDays
      };
    }
  }

  isCalendarRequest(calendar: any): boolean {
    if (calendar && calendar.requestOnHold && calendar.requestOnHold.reason && calendar.requestOnHold.reason.period && calendar.requestOnHold.reason.period == (this.isNight? "NIGHT" : "DAY")) {
      return true;
    } else {
      return false;
    }
  }

  isSwitchRequest(calendar: any): boolean {
    if (calendar && calendar.switchRequest1 && calendar.switchRequest1.calendar1 && calendar.switchRequest1.calendar1.profile)
      return true;
    else if (calendar && calendar.switchRequest2 && calendar.switchRequest2.calendar1 && calendar.switchRequest2.calendar1.profile)
      return true
    return false
  }

  isUserHasEdit(): boolean {
    const user = this.userService.getCurrentUser();

    if (this.profile._id == user.profile._id && this.isAccountView) {
      return true;
    }
    if (user.profile.position == ANESTHETIST && user.levelOfAccess <= 2) {
      return false;
    }

    /*
    if (this.userService.isInternResponsible() && this.profile.seniority !== 'Interne') {
      return false;
    }
    */

    return true;
  }

  selectDay(index: number): void {
    if (this.isUserHasEdit()) {
      if (this.weekCalendars && (this.isCalendarRequest(this.weekCalendars[index]) || this.isSwitchRequest(this.weekCalendars[index]))) {
        return;
      }
      if (this.enableSelection) {
        if (
          this.selectedDays.length === 1 &&
          this.selectedDays.includes(index) &&
          this.selectedPeriod.length < 2
        ) {
          this.openDialog(index);
          this.initSelection();
        } else {
          this.selectedDays.push(index);
          this.daySelected.emit(this.weekDays[index]);
        }
      } else {
        this.openDialog(index);
      }
    }
  }

  isSelected(index: number): boolean {
    return this.enableSelection && this.selectedDays.includes(index);
  }

  isInPeriod(index: number): boolean {
    if (
      this.enableSelection &&
      this.selectedPeriod &&
      this.selectedPeriod.length > 0
    ) {
      const day = this.weekDays[index];
      const period = this.selectedPeriod.sort((a, b) => (a > b ? 1 : -1));
      if (this.selectedPeriod.length === 2) {
        return period[0] < day && period[1] > day;
      } else if (this.hoverDate) {
        return (
          (period[0] < day && this.hoverDate > day) ||
          (period[0] > day && this.hoverDate < day)
        );
      }
    }
    return false;
  }

  initSelection(): void {
    this.selectedDays = [];
  }

  emitDayHover(index: number): void {
    if (this.enableSelection) {
      this.dayHover.emit(this.weekDays[index]);
    }
  }

  getServices(dayCalendars: any) {
    if (dayCalendars) {
      if (dayCalendars.hospital.name && dayCalendars.hospital.name.toString().length > 25) {
        return dayCalendars.hospital.name.toString().substring(0, 25) + '...';
    }
      return dayCalendars.hospital.name
    } else {
      return ""
    }
  }

  getToolTip(dayCalendars: any) {

    if (dayCalendars) {
      if (dayCalendars.reason && (<Reason>dayCalendars.reason).isGarde) {
        let nbGarde = 0;

        this.reasons.forEach((hospitalReasons) => {
          hospitalReasons.reasons.forEach((reason) => {
            if (reason.isGarde) {
              if (!isNurse(this.profile) && (reason.postes.includes(ANESTHETIST) || reason.postes.includes('IADE')))
                nbGarde ++;
              else if (isNurse(this.profile) && (reason.postes.includes(NURSE) || reason.postes.includes('IDE')  || reason.postes.includes('AS') || reason.postes.includes('IBODE'))) {
                nbGarde ++;
              }
            }
          });
        });

        if (nbGarde > 1) {
          return (<Reason>dayCalendars.reason).title   
        }
        else {
          return ""
        }
      } else if (dayCalendars.consultation && dayCalendars.consultation.specialty.name !== 'Anesthésie-réanimation') {
        return dayCalendars.consultation.specialty.name
      } else if (isAvailable(dayCalendars)) {
        const startDate: Date = new Date(dayCalendars.morningStartTime);
        const endDate: Date = new Date(dayCalendars.afternoonEndTime);
          return dayCalendars.morningStartTime? (
            moment.utc(startDate).format("HH:mm") +
            " - " +
            moment.utc(endDate).format("HH:mm")
          ) : '';
      } else if (dayCalendars.reason && ((<Reason>dayCalendars.reason)).availability === "ABSENT" && !((<Reason>dayCalendars.reason)).icon) {
        const description = (<Reason>dayCalendars.reason).freeComment ? dayCalendars.customDescription : (<Reason>dayCalendars.reason).title;

        let reasonMap = null;
        if (this.reasonsMaps && this.reasonsMaps.length > 0 && dayCalendars.reasonMap) {
          reasonMap = this.reasonsMaps.find((reasonMap) => reasonMap._id === dayCalendars.reasonMap);
        }

        return reasonMap && reasonMap.code === description && (<Reason>dayCalendars.reason).availability === 'AVAILABLE'? "" : description;
      }
      /*
      else if (dayCalendars.reason && ((<Reason>dayCalendars.reason)).availability === "AVAILABLE" && (<Reason>dayCalendars.reason).title !== "BLOC" && !(<Reason>dayCalendars.reason).icon) {
        return  (<Reason>dayCalendars.reason).title;
      }
      */
    }
  }

  isDescriptionWithoutIcon(dayCalendars: Calendar): boolean {
    if (dayCalendars) {
      const description = dayCalendars.description;
      return (
        description != "TRAINING" &&  dayCalendars.availability === "ABSENT"
      );
    }
    return false;
  }

  formatDate(str) {
    const arr = str.split("-");
    return arr[2] + "-" + arr[1] + "-" + arr[0];
  }

  goToCalendarRequest(calendar: any) {

    if (this.userService.getLevelAccess() >= 3) {
      this.emitIconAvailabilityRequestHover(null, null, null);
      if (NURSE_TYPES.includes(calendar.requestOnHold.profile.position)) {
        this.router
          .navigateByUrl(`${pages.demandeRhValidation}/${NURSE}`, {
            replaceUrl: true,
          })
          .then(() => {
            this.router.navigate([
              `${pages.demandeRhValidation}/${NURSE}/${moment(calendar.date).format(
                "YYYY-MM-DD"
              )}`,
            ]);
          });
      } else if ([ANESTHETIST, IADE].includes(calendar.requestOnHold.profile.position)) {
        this.router
          .navigateByUrl(`${pages.demandeRhValidation}/${ANESTHESIST_WITH_ACCENT}`, {
            replaceUrl: true,
          })
          .then(() => {
            this.router.navigate([
              `${pages.demandeRhValidation}/${ANESTHESIST_WITH_ACCENT}/${moment(calendar.date).format(
                "YYYY-MM-DD"
              )}`,
            ]);
          });
      }
    }
  }

  goToCalendarSwitchRequest(calendar: any) {

    if (this.userService.getLevelAccess() >= 3) {
      this.emitIconAvailabilityRequestHover(null, null, null);
      if (NURSE_TYPES.includes(calendar.profile.position)) {
        this.router
          .navigateByUrl(`${pages.demandeRhSwitch}/${NURSE}/${moment(calendar.date).format(
            "YYYY-MM-DD"
          )}`, {
            replaceUrl: true,
          })
          .then(() => {
            this.router.navigate([
              `${pages.demandeRhSwitch}/${NURSE}/${moment(calendar.date).format(
                "YYYY-MM-DD"
              )}`,
            ]);
          });
      } else if ([ANESTHETIST, IADE].includes(calendar.profile.position)) {
        this.router
          .navigateByUrl(`${pages.demandeRhSwitch}/${ANESTHESIST_WITH_ACCENT}/${moment(calendar.date).format(
            "YYYY-MM-DD"
          )}`, {
            replaceUrl: true,
          })
          .then(() => {
            this.router.navigate([
              `${pages.demandeRhSwitch}/${ANESTHESIST_WITH_ACCENT}/${moment(calendar.date).format(
                "YYYY-MM-DD"
              )}`,
            ]);
          });
      }
    }
  }

  emitIconAvailabilityRequestHover(weekCalendars, hoveredDay, event) {
    let requestOnHold;
    if (weekCalendars) {
      const popupXLocation = event.pageX * OPPOSITE_ZOOM_VALUE;
      const popupYLocation = (event.pageY * OPPOSITE_ZOOM_VALUE) + 20;
      weekCalendars.requestOnHold.hoveredDate = hoveredDay;
      weekCalendars.requestOnHold.profile = this.profile;
      weekCalendars.requestOnHold.popupXLocation = popupXLocation;
      weekCalendars.requestOnHold.popupYLocation = popupYLocation;
      requestOnHold = weekCalendars.requestOnHold;
      requestOnHold.isSwitchRequest = false;
    } else {
      requestOnHold = null;
    }
    this.notificationRequestAvailabilityHover.emit(requestOnHold);
  }

  emitIconAvailabilitySwitchRequestHover(weekCalendars, hoveredDay, event) {
    let calendarRequest;
    let switchCalendar = null;

    if (weekCalendars) {
      switchCalendar = weekCalendars.switchRequest1
      if (!switchCalendar || !switchCalendar.calendar1 || !switchCalendar.calendar1.profile)
        switchCalendar = weekCalendars.switchRequest2
      const popupXLocation = event.pageX * OPPOSITE_ZOOM_VALUE;
      const popupYLocation = (event.pageY * OPPOSITE_ZOOM_VALUE) + 20;
      weekCalendars.profile =  switchCalendar.calendar1.profile;
      weekCalendars.switchCalendar = switchCalendar
      weekCalendars.switchCalendar.popupXLocation = popupXLocation;
      weekCalendars.switchCalendar.popupYLocation = popupYLocation;
      calendarRequest = weekCalendars.switchCalendar;
      calendarRequest.isSwitchRequest = true;
    } else {
      calendarRequest = null;
    }
    this.notificationRequestAvailabilityHover.emit(calendarRequest);
  }



  getDayClass(date) {
    const today = moment().format('DD MM YYYY');
    const day = moment(date).format('DD MM YYYY');
    if (today !== day) {
      return 'day-header';
    }
    return 'today-header';
  }

  ngOnDestroy(): void {
    if (this.weekCalendarsSubscription) {
      this.weekCalendarsSubscription.unsubscribe();
    }

    if (this.weekCalendarsOfCycleSubscription) {
      this.weekCalendarsOfCycleSubscription.unsubscribe();
    }

    if (this.afterDialogCloseSubscription) {
      this.afterDialogCloseSubscription.unsubscribe();
    }

    if (this.anesthesistesMessagesSubscription) {
      this.anesthesistesMessagesSubscription.unsubscribe();
    }
  }
}
