import {Component, Input, SimpleChanges} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { HrsuiteService } from 'src/app/hrsuite/hrsuite.service';
import {
  Calendar,
  isAbsent,
  isAvailable,
  isCongress,
  isConsultation,
  isFormation,
  isGuard,
  isPartiallyAvailable,
  isVacation
} from 'src/app/shared/models/calendar.model';
import { themeStylingColors } from 'src/themes/common/principal-theme';
import { Hospital } from '../../models/hospital.model';
import { Reason } from '../../models/reason.model';
import { ReasonMap } from '../../models/reasonMap.model';
import { getBrightness } from '../../utils/cross-functions';

@Component({
  selector: 'app-day-availability',
  templateUrl: './day-availability.component.html',
  styleUrls: ['./day-availability.component.scss']
})
export class DayAvailabilityComponent {

  @Input() calendar: any;
  @Input() reasonsMaps: ReasonMap[];

  public reasonMap: ReasonMap = null;

  color: Observable<string>;
  isGuard;
  isIcon;
  iconSize;
  public colorDisplayed: string;
  isAbsence;
  nightToggleSubscription: Subscription;
  isNight: boolean = false
  public rhCodeColor: string;

  constructor() {}

  ngOnInit() {
    this.isGuard = this.getIsGuard;
    this.isIcon = this.getIsIcon;
    this.isAbsence = isAbsent(this.calendar);
    this.iconSize = this.getIconSize;
    
    if (this.calendar && this.calendar.consultation && !this.isGuard) {
      this.isIcon = "assets/icons/stethoscope.svg"   
      this.iconSize = "30"
    }
    
    if (!this.isGuard && !this.isIcon) {
      this.initColor();
    }

    if (!this.isAbsence && this.reasonsMaps && this.reasonsMaps.length > 0 && this.calendar && this.calendar.reasonMap) {
      this.reasonMap = this.reasonsMaps.find((reasonMap) => reasonMap._id === this.calendar.reasonMap);
    }
  }



  ngOnChanges(changes: SimpleChanges) {
    this.isGuard = this.getIsGuard;
    this.isIcon = this.getIsIcon;
    this.iconSize = this.getIconSize;
    this.isAbsence = isAbsent(this.calendar);

    if (this.calendar && this.calendar.consultation) {
      this.isIcon = "assets/icons/stethoscope.svg"
      this.iconSize = "30"  
    }

    if (!this.isGuard && !this.isIcon) {
      this.initColor();
    }
  }

  private initColor(): void {
    if (this.isAbsence) {
      this.colorDisplayed = themeStylingColors.danger_base;
    } else {
      if (this.calendar.isExtraclinique)
        this.colorDisplayed = "#37AEEB"
      else
        this.colorDisplayed = (<Reason>this.calendar.reason).color;
    }
    if (getBrightness(this.colorDisplayed) < 128) {
      this.rhCodeColor = "#FFFFFF";
    } else {
      this.rhCodeColor = "#000000";
    }
  }



  get getIsGuard() {
    return isGuard(this.calendar);
  }

  get getIsIcon(){
    if(this.calendar && this.calendar.reason && (<Reason>this.calendar.reason).icon && !(<Reason>this.calendar.reason).isGarde){
      return `assets/icons/${(<Reason>this.calendar.reason).icon}.svg`
    }else{
      return false
    }
  }

  get getIconSize(){
    let size = 40;
    if(this.calendar && this.calendar.reason && (<Reason>this.calendar.reason).icon === "stethoscope"){
      size = 30
    }
    return size
  }

  ngOnDestroy(): void {
  }
}
