import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { RoomService } from "../../../services/room.service";
import { UserService } from "../../../services/user.service";
import { User } from "../../../models/user.model";
import { BufferProgram } from "../../../models/buffer-program.model";
import { DataDetailPopupComponent } from "../data-detail-popup/data-detail-popup.component";
import { Role } from "src/app/shared/models/role.model";
import { Paramedical } from "src/app/shared/models/paramedical.model";
import { Profile } from "src/app/shared/models/profile.model";
import { IADE, NURSE_TYPES, OPPOSITE_ZOOM_VALUE, WEIGHT } from "src/app/shared/const/glabals.const";
import { PopupManagerService } from "src/app/shared/services/popup-manager.service";
import { EffectiveByRole, TimeRange, consultationExtracliniqueData, profileCard, roomFinalData, surgeonCard } from "../day-program-interfaces";
import { CdkDrag, CdkDragDrop, CdkDragMove, CdkDragStart, CdkDropList, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { Subscription } from "rxjs";
import { cloneWithoutPromise, getFirstHospitalSelectedData, getHospitalsBySelectedHospitals, getHoursAndMinutes, getWorkForce } from "src/app/shared/utils/cross-functions";
import { ProfilesSuggestionsComponent } from "../../profiles-suggestions/profiles-suggestions.component";
import { allAvailableNurses } from "src/app/shared/interfaces/profile.interfaces";
import { Calendar, isGuard } from "src/app/shared/models/calendar.model";
import { SpecialtyColorService } from "src/app/specialty-color.service";
import { Formation } from "src/app/shared/models/formation.model";
import { MatMenuTrigger } from "@angular/material";
import { DefaultPopupComponent } from "../../default-popup/default-popup.component";
import { Need } from "../../effective-needs/interfaces";
import { Effective } from "../../effective-needs/interfaces";
import { SO } from "src/app/shared/models/surgeonOpenings.model";
import { SkillService } from "src/app/shared/services/skill.service";
import { RoleLevel } from "src/app/shared/models/skill.model";
import { Hospital } from "src/app/shared/models/hospital.model";
import { HospitalService } from "src/app/shared/services/hospital.service";
import { dailyNeeds } from "src/app/shared/models/anesthNeed.model";
import { anesthsRanks, nursesRanking, rankingAnesthData } from "src/app/shared/interfaces/pythonAPI.interfaces";
import { Reason } from "src/app/shared/models/reason.model";
import { roomWithDoctorAgendas } from "src/app/shared/interfaces/room.interfaces";

@Component({
  selector: "app-data-column",
  templateUrl: "./data-column.component.html",
  styleUrls: ["./data-column.component.scss"],
})
export class DataColumnComponent implements OnInit, OnDestroy, OnChanges {
  @Input() title: string = ""
  @Input() isBloc: boolean = true;
  @Input() extraCliniqueScroll: boolean = false;
  @Input() day: Date;
  @Input() redirectOnClickOnProfile: boolean = false;
  @Input() data: roomFinalData | consultationExtracliniqueData;
  @Input() roles: Role[];
  @Input() paramedicalsData: Paramedical[];
  @Input() formations: Formation[];
  @Input() isBP: boolean = false;
  @Input() programIndex: number = 0;
  @Input() readOnly: boolean = false;
  @Input() listIds: string[] = []
  @Input() listId: string = ""
  @Input() nursesRanks: nursesRanking[];
  @Input() nursesRanksLoaded: boolean = false;
  @Input() anesthsRanks: anesthsRanks;
  @Input() anesthsRanksLoaded: boolean = false;
  @Input() blocNeeds: dailyNeeds[];
  @Input() consultationNeeds: dailyNeeds[];
  @Input() interventions: roomWithDoctorAgendas[];
  @Input() rooms: roomFinalData[]
  @Input() consultExtraData: consultationExtracliniqueData[]
  @Input() extracliniqueSpecialty: consultationExtracliniqueData[];
  @Input() consultationSpecialties: consultationExtracliniqueData[];
  @Input() allNurses: allAvailableNurses[]
  @Input() anesthetistsCalendars: Calendar[]
  @Input() isMorningPrograms: boolean = false;
  @Input() isHome: boolean = false;
  @Input() reasonMapColors: Map<string, string>; // key : profileID   value : color in hexadecimal
  @Input() showRoles: boolean = true;

  // Right click menu
  public menuTopLeftPosition = { x: '0', y: '0' }
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  @ViewChild('divMenu', { static: true }) divMenu: ElementRef;
  @ViewChild('card', null) card: ElementRef;
  @ViewChild('page', null) page: ElementRef;

  // Header
  public backgroundColor: string = "white"
  public objectifStatus: string;
  public subtitle: string = ""

  public isLoading: boolean = true;

  // User data
  public currentUser: User;
  public levelOfAccess: number = 1;
  public isRespIade: boolean = false;
  public isRespAnesth: boolean = false;
  public isInternRes: boolean;
  public isAnesth: boolean;
  public isIadeRes: boolean;
  public isCadre: boolean;
  public hospitalHaveAnesthDetailsOption: boolean = false;
  public isMultiHospitals: boolean = false;
  public hospitalData: Hospital;

  public notSplitButHalfDay: boolean = false;
  public notSplitButHalfDayMorning: boolean = false;

  private removeCardSubscription: Subscription;
  private reloadObjectifStatusSubscription: Subscription;

  get isEmptyRoom() {
    if (this.isBloc) {
      const roomData = (this.data as roomFinalData)

      if (roomData.team.others.length > 0) {
        return false;
      }
    } else {
      const roomData = (this.data as consultationExtracliniqueData)

      if (roomData.team.length > 0) {
        return false;
      }
    }
    return true;
  }

  get date() {
    return moment(this.day).format("YYYY-MM-DD").toString();
  }

  get canOpenSmartranking() {
    if (this.readOnly) {
      return false;
    }

    if (this.hospitalHaveAnesthDetailsOption) {
      if ((this.isBloc && this.isRespIade) || !this.isRespIade) {
        return true;
      }
    } else {
      if (!this.isRespIade && !this.isRespAnesth && this.levelOfAccess > 2) {
        return true;
      }
    }
    return false;
  }

  get canSplitRoom() {
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    if (!hospital) {
      return false;
    }
    const middleTime = new Date(hospital.middleTime)
    const middleTimeModified = new Date("1970-01-01");
    middleTimeModified.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())

    if ((this.data as roomFinalData).isSplit) {
      return false;
    } else {
      if ((this.data as roomFinalData).isBP) {
        if ((this.data as roomFinalData).programs.length === 1) {
          const startTime = new Date("1970-01-01")
          startTime.setUTCHours(new Date((this.data as roomFinalData).programs[0].startTime).getUTCHours(), new Date((this.data as roomFinalData).programs[0].startTime).getUTCMinutes(), new Date((this.data as roomFinalData).programs[0].startTime).getUTCSeconds(), new Date((this.data as roomFinalData).programs[0].startTime).getUTCMilliseconds())
          const endTime = new Date("1970-01-01")
          endTime.setUTCHours(new Date((this.data as roomFinalData).programs[0].endTime).getUTCHours(), new Date((this.data as roomFinalData).programs[0].endTime).getUTCMinutes(), new Date((this.data as roomFinalData).programs[0].endTime).getUTCSeconds(), new Date((this.data as roomFinalData).programs[0].endTime).getUTCMilliseconds())
        
          if (startTime.getTime() < (middleTimeModified.getTime() + 3600000) && endTime.getTime() > (middleTimeModified.getTime() - 3600000)) {
            return true;
          }

          if (startTime.getTime() < middleTimeModified.getTime() && endTime.getTime() <= (middleTimeModified.getTime() + 3600000)) {
            return false;
          }

          if (endTime.getTime() > middleTimeModified.getTime() && endTime.getTime() >= (middleTimeModified.getTime() - 3600000)) {
            return false;
          }
        }
      } else {
        if ((this.data as roomFinalData).surgeonOpenings.length === 1) {
          const startTime = new Date("1970-01-01")
          startTime.setUTCHours(new Date((this.data as roomFinalData).surgeonOpenings[0].startTime).getUTCHours(), new Date((this.data as roomFinalData).surgeonOpenings[0].startTime).getUTCMinutes(), new Date((this.data as roomFinalData).surgeonOpenings[0].startTime).getUTCSeconds(), new Date((this.data as roomFinalData).surgeonOpenings[0].startTime).getUTCMilliseconds())
          const endTime = new Date("1970-01-01")
          endTime.setUTCHours(new Date((this.data as roomFinalData).surgeonOpenings[0].endTime).getUTCHours(), new Date((this.data as roomFinalData).surgeonOpenings[0].endTime).getUTCMinutes(), new Date((this.data as roomFinalData).surgeonOpenings[0].endTime).getUTCSeconds(), new Date((this.data as roomFinalData).surgeonOpenings[0].endTime).getUTCMilliseconds())
          
          if (startTime.getTime() < middleTimeModified.getTime() && endTime.getTime() > middleTimeModified.getTime()) {
            return true;
          }

          if (startTime.getTime() < middleTimeModified.getTime() && endTime.getTime() <= (middleTimeModified.getTime() + 3600000)) {
            return false;
          }

          if (endTime.getTime() > middleTimeModified.getTime() && endTime.getTime() >= (middleTimeModified.getTime() - 3600000)) {
            return false;
          }
        }
      }
    }

    return true;
  }

  constructor(
    private hospitalService: HospitalService,
    private roomsService: RoomService,
    private skillService: SkillService,
    private userService: UserService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private popupManagerService: PopupManagerService,
    private matDialog: MatDialog,
    private specialtyColorService: SpecialtyColorService,
    public elem: ElementRef
  ) {
    this.isMultiHospitals = this.userService.isCurrentUserHasMultipleHospitalsSelected()
    this.currentUser = this.userService.getCurrentUser();
    this.levelOfAccess = this.currentUser.levelOfAccess;
    this.isRespIade = this.userService.isIadRes()
    this.isRespAnesth = this.userService.isAnesthResponsable()
    this.isInternRes = this.userService.isInternResponsible();
    this.isAnesth = this.userService.isAnesWithLowLevelAccess();
    this.isCadre = this.userService.isCadreBloc();
    this.isIadeRes = this.userService.isIadRes();
    this.hospitalHaveAnesthDetailsOption = this.hospitalService.doesHospitalHaveAnesthDetailsOption()

    this.subscribeRemoveCard();
    this.subscribeReloadObjectifStatus();
  }

  subscribeReloadObjectifStatus() {
    if (this.reloadObjectifStatusSubscription) {
      this.reloadObjectifStatusSubscription.unsubscribe();
    }

    this.reloadObjectifStatusSubscription = this.roomsService.reloadObjectifStatusSmartPlanning.subscribe(() => {
      this.objectifStatus = this.setObjectifStatus()
    })
  }

  subscribeRemoveCard() {
    if (this.removeCardSubscription) {
      this.removeCardSubscription.unsubscribe();
    }

    this.removeCardSubscription = this.roomsService.removeCardSmartPlanning.subscribe((dataI) => {
      this.data.modified = true;
      const card = dataI.profile
      card.invalidTime = false
      card.partTime = false
      if (this.isBloc) {
        if (((dataI.profile.profile.position === "Anesthésiste" || dataI.profile.profile.position === "Iade") && String((this.data as roomFinalData).roomId) !== String(dataI.room.roomId)) ||
            ((dataI.profile.profile.position !== "Anesthésiste" && dataI.profile.profile.position !== "Iade") && this.data !== dataI.room)) {
          return;
        }
        const data = (this.data as roomFinalData)
        let cardCheck: profileCard;
        if (!data.isSplit || (data.isSplit && !data.splitByVacation)) {
          cardCheck = data.team.others.find((teamCard) => String(teamCard.profile._id) === String(card.profile._id))
        } else if (data.splitByVacation) {
          if (data.isBP) {
            cardCheck = data.programs[this.programIndex].team.others.find((teamCard) => String(teamCard.profile._id) === String(card.profile._id))
          } else {
            cardCheck = data.surgeonOpenings[this.programIndex].team.others.find((teamCard) => String(teamCard.profile._id) === String(card.profile._id))
          }
        }

        if (cardCheck) {
          if (this.isBP) {
            data.programs.forEach(program => {
              if (card.profile.position !== "Anesthésiste") {
                if (new Date(program.startTime).getTime() === new Date(card.time.startTime).getTime() &&
                  new Date(program.endTime).getTime() === new Date(card.time.endTime).getTime()) {
                  let index = program.nurses.findIndex((nurse) => String(nurse.profile._id) === String(card.profile._id))
                  if (index !== -1) {
                    program.nurses.splice(index, 1);
                  }
                  if (program.team) {
                    index = program.team.others.findIndex((nurse) => String(nurse.profile._id) === String(card.profile._id))
                    if (index !== -1) {
                      program.team.others.splice(index, 1);
                    }
                  }
                }
              } else {
                let index = program.anesthesists.findIndex((card2) => String(card2._id) === String(card.profile._id))
                if (index !== -1) {
                  program.anesthesists.splice(index, 1);
                }
                if (program.team) {
                  index = program.team.others.findIndex((card2) => String(card2.profile._id) === String(card.profile._id))
                  if (index !== -1) {
                    program.team.others.splice(index, 1);
                  }
                }
              }
            });
          }
          data.team.others.splice(data.team.others.findIndex((teamCard) => String(teamCard.profile._id) === String(card.profile._id)), 1);
          if (data.splitByVacation) {
            if (data.isBP) {
              data.programs[this.programIndex].team.others.splice(data.programs[this.programIndex].team.others.findIndex((teamCard) => String(teamCard.profile._id) === String(card.profile._id)), 1);
            } else {
              data.surgeonOpenings[this.programIndex].team.others.splice(data.surgeonOpenings[this.programIndex].team.others.findIndex((teamCard) => String(teamCard.profile._id) === String(card.profile._id)), 1);
            }
          }
          if (!IADE.includes(card.profile.position)) {
            if (this.hospitalHaveAnesthDetailsOption) {
              card.onlyConsultExtra = this.isProfileAssignedToConsultationProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
              card.onlyExtra = this.isProfileAssignedToExtracliniqueProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
            }
            this.roomsService.refreshAvailableProfilesSmartPlanning.next({card, removing: true});
          }
        }
      } else {
        const data = (this.data as consultationExtracliniqueData)
        if (data.team.find((teamCard) => teamCard === card)) {
          data.team.splice(data.team.findIndex((teamCard) => teamCard === card), 1);
        }
        if (data.dayPrograms.find((card2) => String(card2.anesth._id) === String(card.profile._id) && ((card2.currentStartTime.getTime() === card.time.startTime.getTime()) && (card2.currentEndTime.getTime() === card.time.endTime.getTime())))) {
          data.dayPrograms.splice(data.dayPrograms.findIndex((card2) => String(card2.anesth._id) === String(card.profile._id) && ((card2.currentStartTime.getTime() === card.time.startTime.getTime()) && (card2.currentEndTime.getTime() === card.time.endTime.getTime()))), 1);
        }

        if (data.morningPrograms.find((card2) => String(card2.anesth._id) === String(card.profile._id) && ((card2.currentStartTime.getTime() === card.time.startTime.getTime()) && (card2.currentEndTime.getTime() === card.time.endTime.getTime())))) {
          data.morningPrograms.splice(data.morningPrograms.findIndex((card2) => String(card2.anesth._id) === String(card.profile._id) && ((card2.currentStartTime.getTime() === card.time.startTime.getTime()) && (card2.currentEndTime.getTime() === card.time.endTime.getTime()))), 1);
        }

        if (data.afternoonPrograms.find((card2) => String(card2.anesth._id) === String(card.profile._id) && ((card2.currentStartTime.getTime() === card.time.startTime.getTime()) && (card2.currentEndTime.getTime() === card.time.endTime.getTime())))) {
          data.afternoonPrograms.splice(data.afternoonPrograms.findIndex((card2) => String(card2.anesth._id) === String(card.profile._id) && ((card2.currentStartTime.getTime() === card.time.startTime.getTime()) && (card2.currentEndTime.getTime() === card.time.endTime.getTime()))), 1);
        }
        this.roomsService.refreshAvailableProfilesSmartPlanning.next({card: null, removing: true});
      }
      if (this.hospitalHaveAnesthDetailsOption) {
        card.onlyConsultExtra = this.isProfileAssignedToConsultationProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
        card.onlyExtra = this.isProfileAssignedToExtracliniqueProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
      }

      this.objectifStatus = this.setObjectifStatus()
    })
  }

  onDragMove(event: CdkDragMove<profileCard>, card: profileCard): void {
    const nodeMovePreview = new ElementRef<HTMLElement>(document.getElementById(card.profile._id + 'preview'));
    const xPos = event.pointerPosition.x - 140;
    const yPos = event.pointerPosition.y - 28;
    if (nodeMovePreview && nodeMovePreview.nativeElement) {
        nodeMovePreview.nativeElement.style.transform = `translate3d(${xPos}px, ${yPos}px, 0)`;
    }
  }

  bodyElement: HTMLElement = document.body;

  dragStart(event: CdkDragStart<profileCard>) {
    this.bodyElement.classList.add('inheritCursors');
    this.bodyElement.style.cursor = 'grabbing'; 
  }

  setTitle() {
    const originalTitle = (' ' + this.title).slice(1);
    let tmp = this.title.substring(0, this.title.indexOf(" | "))
    const tmp2 = this.title.substring(this.title.indexOf(" | ") + 2, this.title.length)
    if (tmp.length > 15) {
      if (this.title.indexOf("Salle") !== -1) {
        this.title = this.title.substring(this.title.indexOf("Salle") + 6, this.title.indexOf(" | "));
        if (this.title.length > 15) {
          this.title = this.title.substring(0, 14) + "."
        }
      } else {
        this.title = this.title.substring(0, 14) + "."
      }
    } else {
      if (originalTitle.includes(" | ")) {
        this.title = tmp
      }
    }
    if (originalTitle.includes(" | ")) {
      this.subtitle = " |" + tmp2;
    }
  }

  getHospitalMiddleTime() {
    return getHoursAndMinutes((this.data as roomFinalData).room.hospital.middleTime);
  }

  isMorningOpening(opening: SO): boolean {
    const hospitalMiddleTime = this.getHospitalMiddleTime();
    const startDate = getHoursAndMinutes(opening.startTime)
    return startDate < hospitalMiddleTime && opening.opening;
  }

  isAfternonOpening(opening: SO): boolean {
    const hospitalMiddleTime = this.getHospitalMiddleTime();
    const endDate = getHoursAndMinutes(opening.endTime);
    return endDate > hospitalMiddleTime && opening.opening;
  }

  getNurseNeedsOfRoomByRole(openings: SO[]): Need[] {
    const nursesNeedsByRole: any = {};

    if (!openings || openings.length === 0) {
      return []
    }

    // Get all morning SO from the room
    let morningOpenings = openings.filter((opening) => opening && this.isMorningOpening(opening));

    // Get all afternoon SO from the room
    let afternoonOpenings = openings.filter((opening) => opening &&  this.isAfternonOpening(opening));

    // Iterate over each SO for the room and get needs
    for (const opening of morningOpenings) {
      for (const need of opening.needs) {
        const role = need.role && need.role._id ? need.role._id : need.role;
        if (nursesNeedsByRole[String(role)]) {
          const currentNeed = nursesNeedsByRole[String(role)].morningNursesNeeds;
          nursesNeedsByRole[String(role)].morningNursesNeeds = currentNeed > need.need ? currentNeed : need.need;
        } else {
          nursesNeedsByRole[String(role)] = {
            morningNursesNeeds: need.need,
            afternoonNursesNeeds: 0
          }
        }
      }
    }

    for (const opening of afternoonOpenings) {
      for (const need of opening.needs) {
        const role = need.role && need.role._id ? need.role._id : need.role;
        if (nursesNeedsByRole[String(role)]) {
          const currentNeed = nursesNeedsByRole[String(role)].afternoonNursesNeeds;
          nursesNeedsByRole[String(role)].afternoonNursesNeeds = currentNeed > need.need ? currentNeed : need.need;
        } else {
          nursesNeedsByRole[String(role)] = {
            morningNursesNeeds: 0,
            afternoonNursesNeeds: need.need
          }
        }
      }
    }

    // Prepare response
    const response: Need[] = [];
    for (const [key, value] of Object.entries(nursesNeedsByRole)) {
      const v = value as any;
      if (this.roles.find((val: Role) => { return val._id === key })) {
        const tmp: Need = {
          role: this.roles.find((val: Role) => { return val._id === key }),
          morningNursesNeeds: v.morningNursesNeeds,
          afternoonNursesNeeds: v.afternoonNursesNeeds
        };
  
        response.push(tmp);
      }
    }

    return response;
  }

  checkNurses(nursesWorkforce: EffectiveByRole, nursesNeeds: Need[]) {
    let result = true;

    for (let index = 0; index < nursesNeeds.length; index++) {
      const nursesNeed = nursesNeeds[index];
      if (nursesNeed && nursesNeed.role) {
        let morningNurseEffectif = 0
        let afternoonNurseEffectif = 0
  
        for (let index = 0; index < nursesWorkforce.morningNurseEffectif.length; index++) {
          const morningNurseEffectifData = nursesWorkforce.morningNurseEffectif[index];
          if (String(morningNurseEffectifData.role._id) === String(nursesNeed.role._id)) {
            morningNurseEffectif += morningNurseEffectifData.morningNurses
          }
        }
  
        for (let index = 0; index < nursesWorkforce.afternoonNurseEffectif.length; index++) {
          const afternoonNurseEffectifData = nursesWorkforce.afternoonNurseEffectif[index];
          if (String(afternoonNurseEffectifData.role._id) === String(nursesNeed.role._id)) {
            afternoonNurseEffectif += afternoonNurseEffectifData.afternoonNurses
          }
        }

        if (morningNurseEffectif < nursesNeed.morningNursesNeeds || afternoonNurseEffectif < nursesNeed.afternoonNursesNeeds) {
          result = false;
          break;
        }
      }
    }

    return result;
  }

  getWorkForceByRole(nurses: profileCard[], middleTime: string): EffectiveByRole {
    const workforce: EffectiveByRole = { morningNurseEffectif: [], afternoonNurseEffectif: [] };
  
    nurses.forEach(nurse => {
      if (nurse && nurse.time && nurse.role) {
        if (getHoursAndMinutes(nurse.time.startTime) < getHoursAndMinutes(middleTime)) {
          const foundRole = workforce.morningNurseEffectif.find((morning) => String(morning.role._id) === String(nurse.role._id))
  
          if (foundRole) {
            foundRole.morningNurses++;
          } else {
            workforce.morningNurseEffectif.push({
              role: nurse.role,
              morningNurses: 1
            })
          }
        }
  
        if (getHoursAndMinutes(nurse.time.endTime) > getHoursAndMinutes(middleTime)) {
          const foundRole = workforce.afternoonNurseEffectif.find((afternoon) => String(afternoon.role._id) === String(nurse.role._id))
  
          if (foundRole) {
            foundRole.afternoonNurses++;
          } else {
            workforce.afternoonNurseEffectif.push({
              role: nurse.role,
              afternoonNurses: 1
            })
          }
        }
      }
    });

    return workforce
  }

  setObjectifStatus(): string {
    let result = "";

    let anesthWorkforce = 0;
    let iadeWorkforce = 0;
    let nursesWorkforce: EffectiveByRole;

    let anesthNeeds = 0;
    let iadeNeeds = 0;
    let nursesNeeds: Need[];
    let data;

    if ((this.data as roomFinalData).isSplit && (this.data as roomFinalData).splitByVacation) {
      if ((this.data as roomFinalData).isBP) {
        data = (this.data as roomFinalData).programs[this.programIndex]
      } else {
        data = (this.data as roomFinalData).surgeonOpenings[this.programIndex]
      }
    } else {
      data = (this.data as roomFinalData)
    }

    // Set effectifs
    if (this.isBloc) {
      // Anesths
      nursesWorkforce = this.getWorkForceByRole(data.team.others.filter((card) => card.profile.position !== "Anesthésiste"), (this.data as roomFinalData).room.hospital.middleTime);
      data.team.others.forEach((card: profileCard) => {
        switch (card.profile.position) {
          case 'Iade':
            iadeWorkforce++;
            break;
          case 'Anesthésiste':
            if (this.isInternRes && card.profile.seniority !== 'Interne')
              break;
            anesthWorkforce++;
            break;
        }
      })
    } else {
      (this.data as consultationExtracliniqueData).team.forEach(element => {
        if (element.profile.position == 'Anesthésiste' && element.profile.seniority != 'intern') {
          if (element.profile.seniority == 'Interne')
            anesthWorkforce += WEIGHT.INTERN;
          else if (element.profile.seniority == 'Junior')
            anesthWorkforce += WEIGHT.JUNIOR;
          else if (element.profile.seniority == 'Senior')
            anesthWorkforce += WEIGHT.SENIOR;
          else
            anesthWorkforce++;
        }
      })
    }

    // Set needs
    if (this.isBloc) {
      if (this.isAnesth || this.isRespAnesth) {
        this.blocNeeds.forEach(element => {
          if (String(element.room._id) === String((this.data as roomFinalData).roomId)) {
            anesthNeeds += element.internNeeds * WEIGHT.INTERN;
            anesthNeeds += element.juniorNeeds * WEIGHT.JUNIOR;
            anesthNeeds += element.seniorNeeds * WEIGHT.SENIOR;
            anesthNeeds += element.anesthNeeds;
          }
        });
      }
      if (this.isAnesth || this.isRespAnesth || this.isIadeRes || this.isInternRes) {
        this.blocNeeds.forEach(element => {
          if (String(element.room._id) === String((this.data as roomFinalData).roomId)) {
            iadeNeeds += element.iadeNeeds;
          }
        });
      }
      if (this.isCadre) {
        if ((this.data as roomFinalData).isSplit) {
          if ((this.data as roomFinalData).splitByVacation) {
            nursesNeeds = this.getNurseNeedsOfRoomByRole([(this.data as roomFinalData).surgeonOpenings[this.programIndex]]);
          } else {
            nursesNeeds = this.getNurseNeedsOfRoomByRole((this.data as roomFinalData).surgeonOpenings);
          }
        } else {
          nursesNeeds = this.getNurseNeedsOfRoomByRole((this.data as roomFinalData).surgeonOpenings);
        }
      }
      if (this.isInternRes) {
        anesthNeeds = 0;
        this.blocNeeds.forEach(element => {
          if (String(element.room._id) === String((this.data as roomFinalData).roomId)) {
            anesthNeeds += element.internNeeds;
          }
        });
      }
    } else {
      anesthNeeds = (this.data as consultationExtracliniqueData).needs
    }

    const anesthDiff = anesthWorkforce - anesthNeeds;
    const iadeDiff = iadeWorkforce - iadeNeeds;
    // Result
    if (this.isBloc) {
      if (this.isAnesth || this.isRespAnesth || this.isIadeRes || this.isInternRes) {
        if (this.isIadeRes) {
          iadeDiff >= 0 ? result = "OK" : result = "ERROR";
        } else if (this.isInternRes) {
          anesthDiff >= 0 ? result = "OK" : result = "ERROR";
        } else {
          iadeDiff >= 0 && anesthDiff >= 0 ? result = "OK" : result = "ERROR";
        }
      } else if (this.isCadre) {
        anesthDiff >= 0 && iadeDiff >= 0 && this.checkNurses(nursesWorkforce, nursesNeeds) ? result = "OK" : result = "ERROR";
      }
    } else {
      anesthDiff >= 0 ? result = "OK" : result = "ERROR";
    }

    return result
  }

  checkIfNotSplitButHalfDay() {
    if (!(this.data as roomFinalData).isSplit && !(this.data as roomFinalData).isEmpty) {
      const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
      const middleTime = new Date(hospital.middleTime)
      const middleTimeModified = new Date("1970-01-01");
      middleTimeModified.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())
      let time;

      if (this.isBP) {
        time = { startTime: moment.utc((this.data as roomFinalData).programs[0].startTime).toDate(), endTime: moment.utc((this.data as roomFinalData).programs[0].endTime).toDate() };

        (this.data as roomFinalData).programs.forEach(program => {
          let startTime = moment.utc(program.startTime).toDate();
          let endTime = moment.utc(program.endTime).toDate();

          if (startTime.getTime() < new Date(time.startTime).getTime()) {
            time.startTime = moment.utc(startTime).toDate()
          }

          if (endTime.getTime() > new Date(time.endTime).getTime()) {
            time.endTime = moment.utc(endTime).toDate()
          }
        });
      } else {
        time = { startTime: moment.utc((this.data as roomFinalData).surgeonOpenings[0].startTime).toDate(), endTime: moment.utc((this.data as roomFinalData).surgeonOpenings[0].endTime).toDate() };

        (this.data as roomFinalData).surgeonOpenings.forEach(program => {
          let startTime = moment.utc(program.startTime).toDate();
          let endTime = moment.utc(program.endTime).toDate();

          if (startTime.getTime() < new Date(time.startTime).getTime()) {
            time.startTime = moment.utc(startTime).toDate()
          }

          if (endTime.getTime() > new Date(time.endTime).getTime()) {
            time.endTime = moment.utc(endTime).toDate()
          }
        });
      }

      const tmpStartTime = new Date(time.startTime)
      time.startTime = new Date("1970-01-01")
      time.startTime.setUTCHours(tmpStartTime.getUTCHours(), tmpStartTime.getUTCMinutes(), tmpStartTime.getUTCSeconds(), tmpStartTime.getUTCMilliseconds())
      
      const tmpEndTime = new Date(time.endTime)
      time.endTime = new Date("1970-01-01")
      time.endTime.setUTCHours(tmpEndTime.getUTCHours(), tmpEndTime.getUTCMinutes(), tmpEndTime.getUTCSeconds(), tmpEndTime.getUTCMilliseconds())

      if (time.endTime.getTime() <= (middleTimeModified.getTime() + 3600000)) {
        this.notSplitButHalfDay = true;
        this.notSplitButHalfDayMorning = true;
      } else {
        if (time.startTime.getTime() >= (middleTimeModified.getTime() - 3600000)) {
          this.notSplitButHalfDay = true;
        }
      }
    }
  }

  isDragDisabled(card: profileCard) {
    if (this.readOnly) {
      return true;
    }

    if (this.isRespIade && !this.isInternRes && card.profile.position !== 'Iade') {
      return true;
    }

    if (this.isRespAnesth && !this.isInternRes && (card.profile.position !== 'Iade' && card.profile.position !== 'Anesthésiste')) {
      return true;
    }

    if (this.isInternRes && (card.profile.position !== 'Anesthésiste' || (card.profile.position === 'Anesthésiste' && card.profile.seniority !== 'Interne'))) {
      return true;
    }

    if (this.isCadre && this.levelOfAccess === 4 && (card.profile.position === 'Anesthésiste' || card.profile.position === 'Iade')) {
      return true;
    }

    return false;
  }

  async ngOnInit() {
    if (this.isBloc) {
      this.hospitalData = (this.data as roomFinalData).room.hospital
      this.checkIfNotSplitButHalfDay();
    } else {
      const hospitals = getHospitalsBySelectedHospitals(this.userService.getCurrentUser().profile, this.userService.getSelectedHospitals())
      const hospital = hospitals.find((hospital_) => String(hospital_._id) === String((this.data as consultationExtracliniqueData).specialty.hospital))
      this.hospitalData = hospital
    }
    
    this.listIds.push("available-profiles")
    this.setTitle();
    if (!this.isBloc) {
      this.backgroundColor = this.specialtyColorService.getColorForSpecialty((this.data as consultationExtracliniqueData).specialty.name) + '4d'
    }
    this.objectifStatus = this.setObjectifStatus()

    this.setAnesthBadge();
    if (!this.isHome) {
      if (this.isBloc) {
        let data = this.data as roomFinalData;
        if (!data.isSplit || (data.isSplit && !data.splitByVacation)) {
          for (let nurse of data.team.others) {
            if (nurse.profile.position === 'Anesthésiste')
              continue;
            if (!nurse.scorePromise) {
              let promise = this.getNurseScore(nurse, data.room.hospital, data.surgeonOpenings);
              nurse.scorePromise = promise;
            }
          }
        }
        if (data.splitByVacation) {
          if (data.isBP) {
            for (let nurse of data.programs[this.programIndex].team.others) {
              if (nurse.profile.position === 'Anesthésiste')
                continue;
              if (!nurse.scorePromise) {
                let promise = this.getNurseScore(nurse, data.room.hospital, data.surgeonOpenings);
                nurse.scorePromise = promise;
              }
            }
          } else {
            for (let nurse of data.surgeonOpenings[this.programIndex].team.others) {
              if (nurse.profile.position === 'Anesthésiste')
                continue;
              if (!nurse.scorePromise) {
                let promise = this.getNurseScore(nurse, data.room.hospital, data.surgeonOpenings);
                nurse.scorePromise = promise;
              }
            }
          }
        }
      }
      if (this.title === 'Anesthésie-réanimation') {
        const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
        const data = this.data as consultationExtracliniqueData;
        data.team.forEach(element => {
          if (getHoursAndMinutes(element.time.startTime) >= getHoursAndMinutes(hospital.middleTime))
            element.timeRange = TimeRange.afternoon;
          else if (getHoursAndMinutes(element.time.endTime) <= getHoursAndMinutes(hospital.middleTime))
            element.timeRange = TimeRange.morning;
          else
            element.timeRange = TimeRange.day;
  
        });
      }
    }
    this.isLoading = false;
  }

  setAnesthBadge() {
    if (!this.anesthsRanks || !this.anesthsRanks.seniorsJuniorsRanks || !this.anesthsRanks.internesRanks) {
      return;
    }
    if (this.isBloc) {
      const seniorsJuniorsObjects = this.anesthsRanks.seniorsJuniorsRanks.find((elt) => elt.type === 'bloc' && elt.rooms && elt.rooms.includes((this.data as roomFinalData).roomId));
      const internObjects = this.anesthsRanks.internesRanks.find((elt) => elt.type === 'bloc' && elt.rooms && elt.rooms.includes((this.data as roomFinalData).roomId));
      if (seniorsJuniorsObjects && internObjects) {
        if (!(this.data as roomFinalData).isSplit || ((this.data as roomFinalData).isSplit && !(this.data as roomFinalData).splitByVacation)) {
          (this.data as roomFinalData).team.others.forEach((card: profileCard) => {
            if (card.profile.position === "Anesthésiste") {
              if (['Senior', 'Junior'].includes(card.profile.seniority)) {
                const profile = seniorsJuniorsObjects.profiles.filter((elt) => elt.calendar && (elt.calendar.reason as Reason).availability === 'AVAILABLE').find((profile2) => String(profile2.profile._id) === String(card.profile._id))
                if (profile) {
                  if (profile.score >= 99999) {
                    card.substitution = true;
                  } else if (profile.score <= 5000) {
                    card.priority = true;
                  } else {
                    card.priority = false;
                  }
                } else {
                  card.exclusion = true;
                }
              } else if (card.profile.seniority === "Interne") {
                const profile = internObjects.profiles.filter((elt) => elt.calendar && (elt.calendar.reason as Reason).availability === 'AVAILABLE').find((profile2) => String(profile2.profile._id) === String(card.profile._id))
                if (profile) {
                  if (profile.score >= 99999) {
                    card.substitution = true;
                  } else if (profile.score <= 5000) {
                    card.priority = true;
                  } else {
                    card.priority = false;
                  }
                } else {
                  card.exclusion = true;
                }
              }
              card.badgeLoaded = true;
            }
          });
        } else {
          if ((this.data as roomFinalData).splitByVacation) {
            if ((this.data as roomFinalData).isBP) {
              ((this.data as roomFinalData).programs).forEach(program => {
                program.team.others.forEach((card: profileCard) => {
                  if (card.profile.position === "Anesthésiste") {
                    if (['Senior', 'Junior'].includes(card.profile.seniority)) {
                      const profile = seniorsJuniorsObjects.profiles.filter((elt) => elt.calendar && (elt.calendar.reason as Reason).availability === 'AVAILABLE').find((profile2) => String(profile2.profile._id) === String(card.profile._id))
                      if (profile) {
                        if (profile.score >= 99999) {
                          card.substitution = true;
                        } else if (profile.score <= 5000) {
                          card.priority = true;
                        } else {
                          card.priority = false;
                        }
                      } else {
                        card.exclusion = true;
                      }
                    } else if (card.profile.seniority === "Interne") {
                      const profile = internObjects.profiles.filter((elt) => elt.calendar && (elt.calendar.reason as Reason).availability === 'AVAILABLE').find((profile2) => String(profile2.profile._id) === String(card.profile._id))
                      if (profile) {
                        if (profile.score >= 99999) {
                          card.substitution = true;
                        } else if (profile.score <= 5000) {
                          card.priority = true;
                        } else {
                          card.priority = false;
                        }
                      } else {
                        card.exclusion = true;
                      }
                    }
                    card.badgeLoaded = true;
                  }
                });
              });
            } else {
              ((this.data as roomFinalData).surgeonOpenings).forEach(surgeonOpening => {
                surgeonOpening.team.others.forEach((card: profileCard) => {
                  if (card.profile.position === "Anesthésiste") {
                    if (['Senior', 'Junior'].includes(card.profile.seniority)) {
                      const profile = seniorsJuniorsObjects.profiles.filter((elt) => elt.calendar && (elt.calendar.reason as Reason).availability === 'AVAILABLE').find((profile2) => String(profile2.profile._id) === String(card.profile._id))
                      if (profile) {
                        if (profile.score >= 99999) {
                          card.substitution = true;
                        } else if (profile.score <= 5000) {
                          card.priority = true;
                        } else {
                          card.priority = false;
                        }
                      } else {
                        card.exclusion = true;
                      }
                    } else if (card.profile.seniority === "Interne") {
                      const profile = internObjects.profiles.filter((elt) => elt.calendar && (elt.calendar.reason as Reason).availability === 'AVAILABLE').find((profile2) => String(profile2.profile._id) === String(card.profile._id))
                      if (profile) {
                        if (profile.score >= 99999) {
                          card.substitution = true;
                        } else if (profile.score <= 5000) {
                          card.priority = true;
                        } else {
                          card.priority = false;
                        }
                      } else {
                        card.exclusion = true;
                      }
                    }
                    card.badgeLoaded = true;
                  }
                });
              });
            }
          }
        }
      }
    } else {
      let seniorsJuniorsObjects: rankingAnesthData;
      let internObjects: rankingAnesthData;

      if ((this.data as consultationExtracliniqueData).specialty.name === "Extraclinique") {
        seniorsJuniorsObjects = this.anesthsRanks.seniorsJuniorsRanks.find((elt) => elt.type === 'bloc' && elt.specialties && elt.specialties.some((e) => e.specialty === (this.data as consultationExtracliniqueData).specialty._id));
        internObjects = this.anesthsRanks.internesRanks.find((elt) => elt.type === 'bloc' && elt.specialties && elt.specialties.some((e) => e.specialty === (this.data as consultationExtracliniqueData).specialty._id));
      } else {
        seniorsJuniorsObjects = this.anesthsRanks.seniorsJuniorsRanks.find((elt) => elt.type === 'consultation' && elt.specialties && elt.specialties.some((e) => e.specialty === (this.data as consultationExtracliniqueData).specialty._id));
        internObjects = this.anesthsRanks.internesRanks.find((elt) => elt.type === 'consultation' && elt.specialties && elt.specialties.some((e) => e.specialty === (this.data as consultationExtracliniqueData).specialty._id));
      }
      (this.data as consultationExtracliniqueData).team.forEach((card: profileCard) => {
        if (['Senior', 'Junior'].includes(card.profile.seniority)) {
          const profile = seniorsJuniorsObjects.profiles.filter((elt) => elt.calendar && (elt.calendar.reason as Reason).availability === 'AVAILABLE').find((profile2) => String(profile2.profile._id) === String(card.profile._id))
          if (profile) {
            if (profile.score >= 99999) {
              card.substitution = true;
            } else if (profile.score <= 5000) {
              card.priority = true;
            } else {
              card.priority = false;
            }
          } else {
            card.exclusion = true;
          }
        } else if (card.profile.seniority === "Interne") {
          const profile = internObjects.profiles.filter((elt) => elt.calendar && (elt.calendar.reason as Reason).availability === 'AVAILABLE').find((profile2) => String(profile2.profile._id) === String(card.profile._id))
          if (profile) {
            if (profile.score >= 99999) {
              card.substitution = true;
            } else if (profile.score <= 5000) {
              card.priority = true;
            } else {
              card.priority = false;
            }
          } else {
            card.exclusion = true;
          }
        }
        card.badgeLoaded = true;
      });
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.anesthsRanks && changes.anesthsRanks) {
      this.setAnesthBadge();
    }
  }

  // Close menu on click somewhere else
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: MouseEvent): void {
    if (this.matMenuTrigger && this.matMenuTrigger.menuOpen) {
      if (!this.divMenu.nativeElement.contains(event.target)) {
        this.matMenuTrigger.closeMenu();
      }
    }
  }

  duplicateCard(card: profileCard) {
    const newCard = JSON.parse(JSON.stringify(card))
    newCard.isDuplicate = true;
    (this.data as roomFinalData).team.others.push(newCard);
    const anesths = (this.data as roomFinalData).team.others.filter((card) => card.profile.position === "Anesthésiste");
    const iades = (this.data as roomFinalData).team.others.filter((card) => IADE.includes(card.profile.position));
    const team = (this.data as roomFinalData).team.others.filter((card) => NURSE_TYPES.includes(card.profile.position));

    (this.data as roomFinalData).team.others = anesths.concat(iades, team);
  }

  // openMenu(event: MouseEvent, card: profileCard) {
  //   if (this.readOnly || (card.profile.position !== 'Iade' && this.isRespIade)) {
  //     return;
  //   }
  //   // this.duplicateCard(card)
  //   if (this.matMenuTrigger.menuOpen) {
  //     this.matMenuTrigger.closeMenu();
  //   }

  //   // this.menuTopLeftPosition.x = (event.clientX + event.offsetX) + 'px'; 
  //   // this.menuTopLeftPosition.y = (event.clientY + event.offsetY) + 'px'; 
  //   // this.menuTopLeftPosition.x = event.pageX + 'px';
  //   // this.menuTopLeftPosition.y = event.pageY + 'px';

  //   this.matMenuTrigger.menuData = { card }

  //   // we open the menu 
  //   this.matMenuTrigger.openMenu();
  //   event.preventDefault();
  // }

  setTeamOrder(room?: roomFinalData | BufferProgram | SO) {
    if (!room) {
      const data = (this.data as roomFinalData)
      const anesths = data.team.others.filter((card) => card.profile.position === "Anesthésiste")
      const iades = data.team.others.filter((card) => IADE.includes(card.profile.position))
      const team = data.team.others.filter((card) => NURSE_TYPES.includes(card.profile.position))

      data.team.others = anesths.concat(iades, team)
    } else {
      const data = room
      const anesths = data.team.others.filter((card) => card.profile.position === "Anesthésiste")
      const iades = data.team.others.filter((card) => IADE.includes(card.profile.position))
      const team = data.team.others.filter((card) => NURSE_TYPES.includes(card.profile.position))

      data.team.others = anesths.concat(iades, team)
    }
  }

  setCardTime() {
    if (this.isBloc) {
      const data = (this.data as roomFinalData)
      if (this.isBP) {
        let time;
        if (data.isSplit && !data.splitByVacation) {
          time = { startTime: moment.utc(data.programs[0].startTime).toDate(), endTime: moment.utc(data.programs[0].endTime).toDate() }
        } else {
          time = { startTime: moment.utc(data.programs[this.programIndex].startTime).toDate(), endTime: moment.utc(data.programs[this.programIndex].endTime).toDate() }
        }

        if (!data.isSplit || (data.isSplit && !data.splitByVacation)) {
          data.programs.forEach(program => {
            let startTime = moment.utc(program.startTime).toDate();
            let endTime = moment.utc(program.endTime).toDate();

            if (startTime.getTime() < new Date(time.startTime).getTime()) {
              time.startTime = moment.utc(startTime).toDate()
            }

            if (endTime.getTime() > new Date(time.endTime).getTime()) {
              time.endTime = moment.utc(endTime).toDate()
            }
          });
        }

        return time;
      } else {
        let time;
        if (data.isSplit && !data.splitByVacation) {
          time = { startTime: moment.utc(data.surgeonOpenings[0].startTime).toDate(), endTime: moment.utc(data.surgeonOpenings[0].endTime).toDate() }
        } else {
          time = { startTime: moment.utc(data.surgeonOpenings[this.programIndex].startTime).toDate(), endTime: moment.utc(data.surgeonOpenings[this.programIndex].endTime).toDate() }
        }

        if (!data.isSplit || (data.isSplit && !data.splitByVacation)) {
          data.surgeonOpenings.forEach(surgeonOpening => {
            let startTime = moment.utc(surgeonOpening.startTime).toDate();
            let endTime = moment.utc(surgeonOpening.endTime).toDate();

            if (startTime.getTime() < new Date(time.startTime).getTime()) {
              time.startTime = moment.utc(startTime).toDate()
            }

            if (endTime.getTime() > new Date(time.endTime).getTime()) {
              time.endTime = moment.utc(endTime).toDate()
            }
          });
        }

        return time;
      }
    } else {
      const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
      const data = (this.data as consultationExtracliniqueData)
      let time = { startTime: moment.utc(hospital.startTime).toDate(), endTime: moment.utc(hospital.endTime).toDate() };
      if (data.isSplit) {
        if (data.isMorning) {
          time = { startTime: moment.utc(hospital.startTime).toDate(), endTime: moment.utc(hospital.middleTime).toDate() };
        } else {
          time = { startTime: moment.utc(hospital.middleTime).toDate(), endTime: moment.utc(hospital.endTime).toDate() };
        }
      }

      return time;
    }
  }

  canDropIn(card: profileCard) {
    if (this.isBloc) {
      if (card.profile.position === "Anesthésiste" || card.isDuplicate) {
        if ((this.data as roomFinalData).team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
          return false;
        }
        return true;
      }
      const dataBloc = (this.data as roomFinalData)
      if (this.isBP) {
        let time;
        if (dataBloc.isSplit && !dataBloc.splitByVacation) {
          time = { startTime: moment.utc(dataBloc.programs[0].startTime).toDate(), endTime: moment.utc(dataBloc.programs[0].endTime).toDate() }
        } else {
          time = { startTime: moment.utc(dataBloc.programs[this.programIndex].startTime).toDate(), endTime: moment.utc(dataBloc.programs[this.programIndex].endTime).toDate() }
        }

        if (!dataBloc.isSplit || (dataBloc.isSplit && !dataBloc.splitByVacation)) {
          dataBloc.programs.forEach(program => {
            let startTime = moment.utc(program.startTime).toDate();
            let endTime = moment.utc(program.endTime).toDate();

            if (startTime.getTime() < new Date(time.startTime).getTime()) {
              time.startTime = startTime
            }

            if (endTime.getTime() > new Date(time.endTime).getTime()) {
              time.endTime = endTime
            }
          });
        }

        if (this.formatCompareTime(new Date(time.startTime)) >= this.formatCompareTime(new Date(card.originalTime.startTime)) &&
          this.formatCompareTime(new Date(time.endTime)) <= this.formatCompareTime(new Date(card.originalTime.endTime))) {
          return true;
        }
      } else {
        let time;
        if (dataBloc.isSplit && !dataBloc.splitByVacation) {
          time = { startTime: moment.utc(dataBloc.surgeonOpenings[0].startTime).toDate(), endTime: moment.utc(dataBloc.surgeonOpenings[0].endTime).toDate() }
        } else {
          time = { startTime: moment.utc(dataBloc.surgeonOpenings[this.programIndex].startTime).toDate(), endTime: moment.utc(dataBloc.surgeonOpenings[this.programIndex].endTime).toDate() }
        }

        if (!dataBloc.isSplit || (dataBloc.isSplit && !dataBloc.splitByVacation)) {
          dataBloc.surgeonOpenings.forEach(surgeonOpening => {
            let startTime = moment.utc(surgeonOpening.startTime).toDate();
            let endTime = moment.utc(surgeonOpening.endTime).toDate();

            if (startTime.getTime() < new Date(time.startTime).getTime()) {
              time.startTime = startTime
            }

            if (endTime.getTime() > new Date(time.endTime).getTime()) {
              time.endTime = endTime
            }
          });
        }

        if (this.formatCompareTime(new Date(time.startTime)) >= this.formatCompareTime(new Date(card.originalTime.startTime)) &&
          this.formatCompareTime(new Date(time.endTime)) <= this.formatCompareTime(new Date(card.originalTime.endTime))) {
          return true;
        }
      }
    } else {
      const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
      const data = (this.data as consultationExtracliniqueData)
      if (data.isSplit) {
        if (data.isMorning) {
          if (this.formatCompareTime(new Date(hospital.startTime)) >= this.formatCompareTime(card.time.startTime) &&
            this.formatCompareTime(new Date(hospital.middleTime)) <= this.formatCompareTime(card.time.endTime)) {
            return true;
          }
        } else {
          if (this.formatCompareTime(new Date(hospital.middleTime)) >= this.formatCompareTime(card.time.startTime) &&
            this.formatCompareTime(new Date(hospital.endTime)) <= this.formatCompareTime(card.time.endTime)) {
            return true;
          }
        }
      } else {
        if (this.formatCompareTime(new Date(hospital.startTime)) >= this.formatCompareTime(new Date(card.time.startTime)) &&
          this.formatCompareTime(new Date(hospital.endTime)) <= this.formatCompareTime(new Date(card.time.endTime))) {
          return true;
        }
      }
    }
    return false;
  }

  formatCompareTime(time: Date) {
    const tmp = [time.getUTCHours(), time.getUTCMinutes(), time.getUTCSeconds()]

    return tmp[0] * 3600 + tmp[1] * 60 + tmp[2]
  }

  isProfileAssignedToConsultationOrExtracliniqueProgram(profile: Profile): boolean {
    const consultationAndExtracliniquePrograms: profileCard[] = [];
    this.consultationSpecialties.forEach((obj) => {
      consultationAndExtracliniquePrograms.push(...obj.team);
    });
    this.extracliniqueSpecialty.forEach((obj) => {
      consultationAndExtracliniquePrograms.push(...obj.team);
    });

    const elems = consultationAndExtracliniquePrograms
      .filter((team) => team.profile._id === profile._id);
    let isAssigned = elems.length != 0 ? true : false;

    if (isAssigned && elems.length == 1 && elems[0].timeRange
        && elems[0].timeRange != TimeRange.day && elems[0] && getHoursAndMinutes(elems[0].originalTime.startTime)) {
      isAssigned = false;
    }

    return isAssigned;
  }

  isProfilealreadyAssignedBloc(profile: Profile): boolean {
    let isAssigned = false;
    this.rooms.forEach(room => {
      if (room.isSplit) {
        if (room.splitByVacation) {
          if (room.isBP) {
            room.programs.forEach(program => {
              if (program.team.others.find((card) => String(card.profile._id) === String(profile._id))) {
                isAssigned = true;
              }
            });
          } else {
            room.surgeonOpenings.forEach(surgeonOpening => {
              if (surgeonOpening.team.others.find((card) => String(card.profile._id) === String(profile._id))) {
                isAssigned = true;
              }
            });
          }
        } else {
          if (room.morningPrograms.team.others.find((card) => String(card.profile._id) === String(profile._id))) {
            isAssigned = true;
          }

          if (room.afternoonPrograms.team.others.find((card) => String(card.profile._id) === String(profile._id))) {
            isAssigned = true;
          }
        }
      } else {
        if (room.team.others.find((card) => String(card.profile._id) === String(profile._id))) {
          isAssigned = true;
        }
      }
    });

    return isAssigned;
  }

  isProfileAssignedToConsultationProgram(profile: Profile): boolean {
    const consultationAndExtracliniquePrograms: profileCard[] = [];
    this.consultationSpecialties.forEach((obj) => {
      consultationAndExtracliniquePrograms.push(...obj.team);
    });

    const elems = consultationAndExtracliniquePrograms
      .filter((team) => team.profile._id === profile._id);
    let isAssigned = elems.length != 0 ? true : false;

    if (isAssigned && elems.length == 1 && elems[0].timeRange
        && elems[0].timeRange != TimeRange.day && elems[0] && getHoursAndMinutes(elems[0].originalTime.startTime)) {
      isAssigned = false;
    }

    return isAssigned;
  }

  isProfileAssignedToExtracliniqueProgram(profile: Profile): boolean {
    const consultationAndExtracliniquePrograms: profileCard[] = [];
    this.extracliniqueSpecialty.forEach((obj) => {
      consultationAndExtracliniquePrograms.push(...obj.team);
    });

    const elems = consultationAndExtracliniquePrograms
      .filter((team) => team.profile._id === profile._id);
    let isAssigned = elems.length != 0 ? true : false;

    if (isAssigned && elems.length == 1 && elems[0].timeRange
        && elems[0].timeRange != TimeRange.day && elems[0] && getHoursAndMinutes(elems[0].originalTime.startTime)) {
      isAssigned = false;
    }

    return isAssigned;
  }

  dropCard(event: CdkDragDrop<profileCard[]>) {
    this.bodyElement.classList.remove('inheritCursors');
    this.bodyElement.style.cursor = 'unset';

    if (this.isBloc && this.data.isSplit && !(this.data as roomFinalData).splitByVacation) {
      const topRoom = this.rooms.find((room) => String(room.roomId) === String((this.data as roomFinalData).roomId))

      topRoom.modified = true;
    }

    this.data.modified = true;
    const card = event.previousContainer.data[event.previousIndex]
    if (this.isBloc) {
      if (!(this.data as roomFinalData).isSplit || ((this.data as roomFinalData).isSplit && !(this.data as roomFinalData).splitByVacation)) {
        if ((this.data as roomFinalData).team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
          return;
        }
      } else {
        if ((this.data as roomFinalData).isBP) {
          if ((this.data as roomFinalData).programs[this.programIndex].team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
            return;
          }
        } else {
          if ((this.data as roomFinalData).surgeonOpenings[this.programIndex].team.others.find((card2) => String(card2.profile._id) === String(card.profile._id))) {
            return;
          }
        }
      }
    }
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())

    if (event.previousContainer === event.container) {
      if (NURSE_TYPES.includes(card.profile.position) || IADE.includes(card.profile.position)) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        if (this.isBloc) {
          this.setTeamOrder();
        }
      }
    } else {
      if (event.previousContainer.id === "available-profiles" && (card.profile.position === "Anesthésiste" || card.profile.position === 'Iade')) {
        if (!this.isBloc) {
          const newCard = JSON.parse(JSON.stringify(card));
          if (!this.isBloc) {
            if (getHoursAndMinutes(card.time.startTime) >= getHoursAndMinutes(hospital.middleTime))
              newCard.timeRange = TimeRange.afternoon;
            else if (getHoursAndMinutes(card.time.endTime) <= getHoursAndMinutes(hospital.middleTime))
              newCard.timeRange = TimeRange.morning;
            else
              newCard.timeRange = TimeRange.day;
            (this.data as consultationExtracliniqueData).team.push(newCard);
            (this.data as consultationExtracliniqueData).team[(this.data as consultationExtracliniqueData).team.length - 1].scorePromise = null;
          } else {
            (this.data as roomFinalData).team.others.push(newCard);
            (this.data as roomFinalData).team.others[(this.data as roomFinalData).team.others.length - 1].scorePromise = null;
          }

          const otherRooms = this.rooms.filter((room) => String(room.roomId) === (this.data as roomFinalData).roomId && room !== (this.data as roomFinalData))
          
          otherRooms.forEach(room => {
            room.team.others.push(newCard)
            room.team.others[room.team.others.length - 1].scorePromise = null;
            this.setTeamOrder(room);
          });
          if (this.hospitalHaveAnesthDetailsOption) {
            card.onlyConsultExtra = this.isProfileAssignedToConsultationProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
            newCard.onlyConsultExtra = this.isProfileAssignedToConsultationProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
            card.onlyExtra = this.isProfileAssignedToExtracliniqueProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
            newCard.onlyExtra = this.isProfileAssignedToExtracliniqueProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
          }
          this.setAnesthBadge(); 
        } else {
          card.invalidTime = !this.canDropIn(card);
          if (event.previousContainer.id === "available-profiles" && (card.profile.position === "Anesthésiste" || card.profile.position === 'Iade' || this.isRespAnesth)) {
            const newCard = JSON.parse(JSON.stringify(card));
            if (card.profile.position !== "Anesthésiste") {
              newCard.scorePromise = this.getNurseScore(card,
                (this.data as roomFinalData).room.hospital,
                (this.data as roomFinalData).surgeonOpenings);
            }
            (this.data as roomFinalData).team.others.push(newCard);
            if (card.profile.position === "Anesthésiste") {
              (this.data as roomFinalData).team.others[(this.data as roomFinalData).team.others.length - 1].scorePromise = null;
              this.setAnesthBadge();
            }
            if ((this.data as roomFinalData).isSplit && !(this.data as roomFinalData).splitByVacation) {
              const otherRooms = this.rooms.find((room) => String(room.roomId) === String((this.data as roomFinalData).roomId));

              if (!this.isMorningPrograms) {
                otherRooms.morningPrograms.team.others.push(newCard);
                if (card.profile.position === "Anesthésiste") {
                  otherRooms.morningPrograms.team.others[otherRooms.morningPrograms.team.others.length - 1].scorePromise = null;
                }
                this.setTeamOrder(otherRooms.morningPrograms);
              }

              if (this.isMorningPrograms) {
                otherRooms.afternoonPrograms.team.others.push(newCard);
                if (card.profile.position === "Anesthésiste") {
                  otherRooms.afternoonPrograms.team.others[otherRooms.afternoonPrograms.team.others.length - 1].scorePromise = null;
                }
                this.setTeamOrder(otherRooms.afternoonPrograms);
              }
            } else if ((this.data as roomFinalData).isSplit && (this.data as roomFinalData).splitByVacation) {
              if ((this.data as roomFinalData).isBP) {
                (this.data as roomFinalData).programs.forEach((room) => {
                  room.team.others.push(newCard)
                  if (card.profile.position === "Anesthésiste") {
                    room.team.others[room.team.others.length - 1].scorePromise = null;
                  }
                  this.setTeamOrder(room);
                });
              } else {
                (this.data as roomFinalData).surgeonOpenings.forEach((room) => {
                  room.team.others.push(newCard)
                  if (card.profile.position === "Anesthésiste") {
                    room.team.others[room.team.others.length - 1].scorePromise = null;
                  }
                  this.setTeamOrder(room);
                });
              }
            }
            if (this.hospitalHaveAnesthDetailsOption) {
              card.onlyConsultExtra = this.isProfileAssignedToConsultationProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
              newCard.onlyConsultExtra = this.isProfileAssignedToConsultationProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
              card.onlyExtra = this.isProfileAssignedToExtracliniqueProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
              newCard.onlyExtra = this.isProfileAssignedToExtracliniqueProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
            }
          }
        }
      } else {
        card.invalidTime = !this.canDropIn(card);
        if (card.profile.position !== "Anesthésiste")
          card.scorePromise = this.getNurseScore(card,
        (this.data as roomFinalData).room.hospital,
        (this.data as roomFinalData).surgeonOpenings);
        else {
          card.scorePromise = null;
        }
        if (event.previousContainer.id !== "available-profiles" && (card.profile.position === "Anesthésiste"  || card.profile.position === 'Iade')) {
          if (event.item.data && event.item.data.isSplit) {
            const room: roomFinalData = this.rooms.find((room) => String(room.roomId) === String(event.item.data.roomId))
          
            if (room.splitByVacation) {
              if (room.isBP) {
                room.programs.forEach((program) => {
                  program.team.others = program.team.others.filter((card2) => String(card2.profile._id) !== String(card.profile._id))
                })
              } else {
                room.surgeonOpenings.forEach((surgeonOpening) => {
                  surgeonOpening.team.others = surgeonOpening.team.others.filter((card2) => String(card2.profile._id) !== String(card.profile._id))
                })
              }
            } else {
              room.morningPrograms.team.others = room.morningPrograms.team.others.filter((card2) => String(card2.profile._id) !== String(card.profile._id))
              room.afternoonPrograms.team.others = room.afternoonPrograms.team.others.filter((card2) => String(card2.profile._id) !== String(card.profile._id))
            }
          }
        }
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        event.container.data[event.currentIndex].time = this.setCardTime();
        if (this.isBloc && (card.profile.position === "Anesthésiste"  || card.profile.position === 'Iade')) {
          if ((this.data as roomFinalData).isSplit) {
            if ((this.data as roomFinalData).splitByVacation) {
              if ((this.data as roomFinalData).isBP) {
                (this.data as roomFinalData).programs.forEach((program, index) => {
                  if (index !== this.programIndex) {
                    program.team.others.push(event.container.data[event.currentIndex]);
                    this.setTeamOrder(program);
                  }
                });
              } else {
                (this.data as roomFinalData).surgeonOpenings.forEach((surgeonOpening, index) => {
                  if (index !== this.programIndex) {
                    surgeonOpening.team.others.push(event.container.data[event.currentIndex]);
                    this.setTeamOrder(surgeonOpening);
                  }
                });
              }

              (this.data as roomFinalData).team.others.push(event.container.data[event.currentIndex]);
              this.setTeamOrder((this.data as roomFinalData));
            } else {
              const room = this.rooms.find((room) => String(room.roomId) === String((this.data as roomFinalData).roomId))

              if (this.isMorningPrograms) {
                room.afternoonPrograms.team.others.push(event.container.data[event.currentIndex])
                this.setTeamOrder(room.afternoonPrograms);
              } else {
                room.morningPrograms.team.others.push(event.container.data[event.currentIndex])
                this.setTeamOrder(room.morningPrograms);
              }
              room.team.others.push(event.container.data[event.currentIndex])
              this.setTeamOrder(room);
            }
          }
        }
        if (this.hospitalHaveAnesthDetailsOption) {
          event.container.data[event.currentIndex].onlyConsultExtra = this.isProfileAssignedToConsultationProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
          event.container.data[event.currentIndex].onlyExtra = this.isProfileAssignedToExtracliniqueProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
        }
        this.setAnesthBadge();
      }
      if (this.isBloc) {
        this.setTeamOrder();
        if ((this.data as roomFinalData).isSplit) {
          if ((this.data as roomFinalData).splitByVacation) {
            if ((this.data as roomFinalData).isBP) {
              this.setTeamOrder((this.data as roomFinalData).programs[this.programIndex]);
            } else {
              this.setTeamOrder((this.data as roomFinalData).surgeonOpenings[this.programIndex]);
            }
          } else {
            const room = this.rooms.find((room) => String(room.roomId) === String((this.data as roomFinalData).roomId))

            this.setTeamOrder(room);
          }
        }
      }
      if (this.hospitalHaveAnesthDetailsOption && card.profile.position === "Anesthésiste") {
        card.onlyConsultExtra = this.isProfileAssignedToConsultationProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
        card.onlyExtra = this.isProfileAssignedToExtracliniqueProgram(card.profile) && !this.isProfilealreadyAssignedBloc(card.profile)
      }
      this.roomsService.refreshAvailableProfilesSmartPlanning.next({card: event.container.data[event.currentIndex], removing: false});
      this.roomsService.reloadObjectifStatusSmartPlanning.next();
    }
  }

  getNurseScore(newCard: profileCard, hospital: Hospital, openings: SO[]): Promise<RoleLevel[]> {
    let vacIds = []

    if (!(this.data as roomFinalData).isSplit || ((this.data as roomFinalData).isSplit && !(this.data as roomFinalData).splitByVacation)) {
      vacIds = openings.map((elem) => elem._originalId ? elem._originalId : elem._id);
    } else {
      vacIds.push(openings[this.programIndex]._originalId ? openings[this.programIndex]._originalId : openings[this.programIndex]._id)
    }
    return this.skillService.getNurseRating(newCard.profile._id, hospital._id, this.date, vacIds).toPromise();
  }

  getProgramTimes(programs: BufferProgram[]) {
    let time = { startTime: moment.utc(programs[this.programIndex].startTime).toDate(), endTime: moment.utc(programs[this.programIndex].endTime).toDate() }

    programs.forEach(program => {
      const startTime = moment.utc(program.startTime).toDate();
      const endTime = moment.utc(program.endTime).toDate();
      if (startTime.getTime() < time.startTime.getTime()) {
        time.startTime = startTime
      }

      if (endTime.getTime() > time.endTime.getTime()) {
        time.endTime = endTime
      }
    });

    return time;
  }

  onChangeRoleHandler(role: Role, nurse: { profile: Profile, role: Role }) {
    if (role && nurse) {
      nurse.role = JSON.parse(JSON.stringify(role));
      this.data.modified = true;
      this.changeDetectorRef.detectChanges();
      this.objectifStatus = this.setObjectifStatus()
    }
  }

  onChangeRangeHandler(range: TimeRange, anes: profileCard) {
    if (range && anes) {
      anes.timeRange = range;
      const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
      if (range === TimeRange.afternoon) {
        anes.time.startTime = getHoursAndMinutes(anes.time.startTime) <= getHoursAndMinutes(hospital.middleTime) ? new Date(hospital.middleTime) : anes.time.startTime;
        anes.time.endTime = anes.originalTime.endTime;
      } else if (range === TimeRange.morning) {
        anes.time.endTime = getHoursAndMinutes(anes.time.endTime) >= getHoursAndMinutes(hospital.middleTime) ? new Date(hospital.middleTime) : anes.time.endTime;
        anes.time.startTime = anes.originalTime.startTime;
      } else {
        anes.time.startTime = anes.originalTime.startTime;
        anes.time.endTime = anes.originalTime.endTime;
      }
      this.roomsService.refreshAvailableProfilesSmartPlanning.next();
      this.changeDetectorRef.detectChanges();
    }
  }

  openSmartRanking() {
    const dialogRef = this.matDialog.open(ProfilesSuggestionsComponent, {
      width: '1250px',
      maxWidth: 'unset',
      height: '875px',
      data: {
        date: this.day,
        isBloc: this.isBloc,
        data: this.data,
        isExtra: !this.isBloc ? (this.data as consultationExtracliniqueData).specialty.name === "Extraclinique" ? true : false : false,
        paramedicalData: this.paramedicalsData,
        roleData: this.roles,
        nursesRanks: this.nursesRanks,
        anesthsRanks: this.anesthsRanks,
        nursesRanksLoaded: this.nursesRanksLoaded,
        anesthsRanksLoaded: this.anesthsRanksLoaded,
        rooms: this.rooms,
        consultExtraData: this.consultExtraData,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (this.isBloc) {
          let newCard: profileCard;
          if (data.profile && (NURSE_TYPES.includes(data.profile.position) || IADE.includes(data.profile.position))) {
            const originalProfile = this.allNurses.find((nurse2) => String(nurse2._id) === String(data.profile._id))
            newCard = {
              profile: data.profile,
              time: this.setCardTime(),
              originalTime: { startTime: moment.utc(originalProfile.startTime).toDate(), endTime: moment.utc(originalProfile.endTime).toDate() },
              role: data.role,
              isGarde: null
            };
          } else {
            const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(data._id))
            newCard = {
              profile: data,
              time: {
                startTime: null,
                endTime: null
              },
              originalTime: {
                startTime: null,
                endTime: null
              },
              isGarde: isGuard(originalProfile)
            };
          }
          (this.data as roomFinalData).team.others.push(newCard)
          this.setTeamOrder();
          this.roomsService.refreshAvailableProfilesSmartPlanning.next({card: newCard, removing: false});
        } else {
          const originalProfile = this.anesthetistsCalendars.find((calendar) => String(calendar.profile._id) === String(data._id))
          const newCard = {
            profile: data,
            time: this.setCardTime(),
            originalTime: { startTime: moment.utc(originalProfile.morningStartTime).toDate(), endTime: moment.utc(originalProfile.afternoonEndTime).toDate() },
            role: null,
            isGarde: isGuard(originalProfile)
          };
          (this.data as consultationExtracliniqueData).team.push(newCard)
          this.roomsService.refreshAvailableProfilesSmartPlanning.next({card: newCard, removing: false});
        }
        this.setAnesthBadge();
      }
    })
  }

  openRoomInfo(event: Event) {
    event.stopPropagation()
    let team;
    if (this.isBloc) {
      let data = this.data as roomFinalData;
      team = data.team;
      if (data.splitByVacation) {
        if (data.isBP)
          team = data.programs[this.programIndex].team;
        else
          team = data.surgeonOpenings[this.programIndex].team;

      }
    }

    this.dialog.open(DataDetailPopupComponent, {
      width: this.isHome ? '500px' : !this.isBloc ? '400px' : '800px',
      height: this.isHome ? '900px' : '1000px',
      data: {
        title: this.title,
        day: this.day,
        isHome: this.isHome,
        room: this.data,
        roleData: this.roles,
        interventions: this.interventions,
        blocNeeds: this.blocNeeds,
        consultationNeeds: this.consultationNeeds,
        paramedicals: this.paramedicalsData,
        formations: this.formations,
        objectifStatus: this.objectifStatus,
        team: this.isBloc ? team : this.data.team,
        isMorning: this.notSplitButHalfDay ? this.notSplitButHalfDayMorning : this.isMorningPrograms ,
        notSplitButHalfDay: this.notSplitButHalfDay,
        surgeonOpenings: this.isBloc && (this.data as roomFinalData).isSplit && (this.data as roomFinalData).splitByVacation
        ? [(this.data as roomFinalData).surgeonOpenings[this.programIndex]]
        : (this.data as roomFinalData).surgeonOpenings
      }
    })
  }

  openEmptyRoomConfirmation(event: Event) {
    event.stopPropagation();
    this.popupManagerService.openConfirmationPopup(
      "Vider cette salle ?",
      `Êtes-vous sûr de vouloir vider cette salle ?`,
      330,
      "danger",
      "Oui, vider"
    ).afterClosed().subscribe((result) => {
      if (result) {
        this.roomsService.emptyRoomSmartPlanning.next({ data: this.data, isBloc: this.isBloc, programIndex: this.programIndex })
      }
    })
  }

  canSplitMorningAfternoon() {
    const hospital = getFirstHospitalSelectedData(this.userService.getCurrentUser(), this.userService.getSelectedHospitals())
    const middleTime = new Date(hospital.middleTime)
    const middleTimeModified = new Date("1970-01-01");
    middleTimeModified.setUTCHours(middleTime.getUTCHours(), middleTime.getUTCMinutes(), middleTime.getUTCSeconds(), middleTime.getUTCMilliseconds())
    let time;

    if ((this.data as roomFinalData).isBP) {
        if ((this.data as roomFinalData).isSplit && !(this.data as roomFinalData).splitByVacation) {
          time = { startTime: moment.utc((this.data as roomFinalData).programs[0].startTime).toDate(), endTime: moment.utc((this.data as roomFinalData).programs[0].endTime).toDate() }
        } else {
          time = { startTime: moment.utc((this.data as roomFinalData).programs[this.programIndex].startTime).toDate(), endTime: moment.utc((this.data as roomFinalData).programs[this.programIndex].endTime).toDate() }
        }

        if (!(this.data as roomFinalData).isSplit || ((this.data as roomFinalData).isSplit && !(this.data as roomFinalData).splitByVacation)) {
          (this.data as roomFinalData).programs.forEach(program => {
            let startTime = moment.utc(program.startTime).toDate();
            let endTime = moment.utc(program.endTime).toDate();

            if (startTime.getTime() < new Date(time.startTime).getTime()) {
              time.startTime = startTime
            }

            if (endTime.getTime() > new Date(time.endTime).getTime()) {
              time.endTime = endTime
            }
          });
        }
    } else {
        if ((this.data as roomFinalData).isSplit && !(this.data as roomFinalData).splitByVacation) {
          time = { startTime: moment.utc((this.data as roomFinalData).surgeonOpenings[0].startTime).toDate(), endTime: moment.utc((this.data as roomFinalData).surgeonOpenings[0].endTime).toDate() }
        } else {
          time = { startTime: moment.utc((this.data as roomFinalData).surgeonOpenings[this.programIndex].startTime).toDate(), endTime: moment.utc((this.data as roomFinalData).surgeonOpenings[this.programIndex].endTime).toDate() }
        }

        if (!(this.data as roomFinalData).isSplit || ((this.data as roomFinalData).isSplit && !(this.data as roomFinalData).splitByVacation)) {
          (this.data as roomFinalData).surgeonOpenings.forEach(surgeonOpening => {
            let startTime = moment.utc(surgeonOpening.startTime).toDate();
            let endTime = moment.utc(surgeonOpening.endTime).toDate();

            if (startTime.getTime() < new Date(time.startTime).getTime()) {
              time.startTime = startTime
            }

            if (endTime.getTime() > new Date(time.endTime).getTime()) {
              time.endTime = endTime
            }
          });
        }
    }

    let tmp = new Date("1970-01-01")
    tmp.setUTCHours( time.startTime.getUTCHours(),  time.startTime.getUTCMinutes(),  time.startTime.getUTCSeconds(),  time.startTime.getUTCMilliseconds())
    let tmp2 = new Date("1970-01-01")
    tmp2.setUTCHours(time.endTime.getUTCHours(), time.endTime.getUTCMinutes(), time.endTime.getUTCSeconds(), time.endTime.getUTCMilliseconds())
    time.startTime = tmp
    time.endTime = tmp2

    if (time.startTime.getTime() >= (middleTimeModified.getTime() - 3600000) ||
    time.endTime.getTime() <= (middleTimeModified.getTime() + 3600000)) {
      return false;
    }

    return true;
  }

  openSplitConfirmation(event: Event) {
    event.stopPropagation();

    const width = 480 * OPPOSITE_ZOOM_VALUE

    this.matDialog
      .open(DefaultPopupComponent,
        {
          width: width.toString() + 'px',
          data: {
            title: "Configuration spéciale pour cette salle ?",
            description: `Voulez vous faire une configuration différente par chirurgien dans cette salle ?`,
            type: "default",
            buttons: this.isBloc && this.canSplitMorningAfternoon() && (((this.data as roomFinalData).programs && (this.data as roomFinalData).programs.length > 1) || ((this.data as roomFinalData).surgeonOpenings && (this.data as roomFinalData).surgeonOpenings.length > 1)) ? [
              { value: "false", name: 'Annuler', type: 'text', color: 'primary' },
              { value: "vacation", name: "Par vacation", type: 'primary', color: "primary" },
              { value: "splitDay", name: "Par demi-journée", type: 'primary', color: "primary" }
            ] : [
              { value: "false", name: 'Annuler', type: 'text', color: 'primary' },
              { value: "true", name: "Oui", type: 'primary', color: "primary" },
            ]
          }
        }
      )
      .afterClosed()
      .subscribe((result) => {
        if (result && result === "vacation") {
          this.roomsService.splitRoomSmartPlanning.next({ id: this.isBloc ? (this.data as roomFinalData).roomId : (this.data as consultationExtracliniqueData).specialty._id, isBloc: this.isBloc, mode: "vacation" })
        } else if (result && result === "splitDay") {
          this.roomsService.splitRoomSmartPlanning.next({ id: this.isBloc ? (this.data as roomFinalData).roomId : (this.data as consultationExtracliniqueData).specialty._id, isBloc: this.isBloc, mode: "splitDay" })
        } else if (result && result === "true") {
          this.roomsService.splitRoomSmartPlanning.next({ id: this.isBloc ? (this.data as roomFinalData).roomId : (this.data as consultationExtracliniqueData).specialty._id, isBloc: this.isBloc, mode: this.isBloc ? "splitDay" : "consultation" })
        }
      });
  }

  ngOnDestroy(): void {
    if (this.removeCardSubscription) {
      this.removeCardSubscription.unsubscribe();
    }

    if (this.reloadObjectifStatusSubscription) {
      this.reloadObjectifStatusSubscription.unsubscribe();
    }
  }
}
