import { Injectable } from "@angular/core";
import { BufferProgram } from "../models/buffer-program.model";
import { DyasStatus, Period } from "../models/period.model";
import { Observable, of } from "rxjs";
import { Room } from "../models/room.model";
import {ProgramCommentaryDal} from "../dal/program-commentary.dal";
import {ProgramCommentary} from "../models/program-commentary.model";

@Injectable({
  providedIn: "root",
})
export class ProgramCommentaryService {
  constructor(private programCommentaryDal: ProgramCommentaryDal) {}

  getProgramCommentary(date: string): Observable<any> {
    return this.programCommentaryDal.getProgramCommentary(date);
  }

  getProgramCommentaryPeriod(startDate: string , endDate:string): Observable<any> {
    return this.programCommentaryDal.getProgramCommentaryPeriod(startDate,endDate);
  }

  addProgramCommentary(body: ProgramCommentary): Observable<any> {
    return this.programCommentaryDal.addProgramCommentary(body);
  }

  updateProgramCommentary(body: ProgramCommentary, id: string): Observable<any> {
    return this.programCommentaryDal.updateProgramCommentary(body, id);
  }

}
