import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  @Input() formCtrl: FormControl;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() hintMessage: string = '';
  @Input() disabled: boolean;
  @Input() rows: number;
  @Input() cols: number;
  @Input() canResize: boolean = true;

  /*
  ** errorMessagesObject contains key value pairs of possible errors, 
  ** when 'key' represents the error name, 'value' represents the message to show when the error 'key' is present,
  */
  @Input() errorMessagesObject: any = {};

  // This is used to handle two way binding
  @Input() inputModel: string;
  @Output() inputModelChange = new EventEmitter<any>();

  @Output() onKeyUp: EventEmitter<string> = new EventEmitter<string>();
  @Output() blur: EventEmitter<string> = new EventEmitter<string>();


  constructor() { }

  ngOnInit() {
  }

  getIsError() {
    if (this.formCtrl && this.formCtrl.touched && this.formCtrl.errors) {
      return true;
    }

    return false;
  }

  getErrorMessageToShow(): string {
    const errorMessagesArray = Object.entries(this.errorMessagesObject);
    const currentErrorsArray = Object.entries(this.formCtrl.errors);
    for (const [key, value] of errorMessagesArray) {
      for (const [key2, value2] of currentErrorsArray) {
        if (key === key2) {
          return String(value);
        }
      }
    }
  }

  inputModelChangeHandler(): void {
    this.inputModelChange.emit(this.inputModel);
  }

  onKeyUpHandler(input: string): void {
    this.onKeyUp.emit(input);
  }

  onBlurHandler(): void {
    this.blur.emit(this.inputModel);
  }
}
