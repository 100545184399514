import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';

import {Hospital} from '../models/hospital.model';
import {HospitalDal} from '../dal/hospital.dal';
import { UserService } from './user.service';
import { OPTIONS } from '../const/glabals.const';
import { getFirstHospitalSelectedData } from '../utils/cross-functions';

@Injectable()
export class HospitalService {

  constructor(private hospitalDal: HospitalDal, private userService: UserService) {
  }

  checkIfHospitalHaveFeature(featureName: string): boolean {
    const currentUser = this.userService.getCurrentUser();

    if (!currentUser) {
      return false;
    }

    const hospital = getFirstHospitalSelectedData(currentUser, this.userService.getSelectedHospitals())

    if (hospital && hospital.features) {
        const found = hospital.features.find((f) => f.name === featureName);

        return found ? true : false;
    }

    return false;
  }

  checkIfHospitalHaveOption(optionName: string): boolean {
    const currentUser = this.userService.getCurrentUser();

    if (!currentUser) {
      return false;
    }

    const hospital = getFirstHospitalSelectedData(currentUser, this.userService.getSelectedHospitals())
    if (hospital && hospital.options) {
        const found = hospital.options.find((f) => {return f.name === optionName});

        return found ? true : false;
    }

    return false;
  }

  checkIfHospitalAllowsShowingRoles(): boolean {
    const currentUser = this.userService.getCurrentUser();

    if (!currentUser) {
      return false;
    }

    const hospital = getFirstHospitalSelectedData(currentUser, this.userService.getSelectedHospitals())

    const found = (hospital && hospital.showRoles !== undefined) ? hospital.showRoles : true
    return found;
  }

  checkIfHospitalHaveOptionForConfiguration(optionName: string, softwareName: string): boolean {
    const currentUser = this.userService.getCurrentUser();

    if (!currentUser) {
      return false;
    }

    const hospital = getFirstHospitalSelectedData(currentUser, this.userService.getSelectedHospitals())
    
    if (hospital && hospital.options) {
        const found = hospital.options.find((f) => {return f.name === optionName && f.softwareName === softwareName});

        return found ? true : false;
    }

    return false;
  }

  doesHospitalHaveAnesthDetailsOption(): boolean {
    return this.checkIfHospitalHaveOption(OPTIONS.ANESTH_DETAILS);
  }

  doesHospitalHaveNurseDetailsOption(): boolean {
    return this.checkIfHospitalHaveOption(OPTIONS.NURSE_DETAILS);
  }

  doesHospitalHaveDayNightOption(): boolean {
    return this.checkIfHospitalHaveOption(OPTIONS.DAY_NIGHT);
  }

  doesHospitalHaveProgramsDataPipelineOption(): boolean {
    return this.checkIfHospitalHaveOption(OPTIONS.PROGRAMS_DATA_PIPELINE);
  }
  
  doesHospitalHaveCalendarsDataPipelineOption(): boolean {
    return this.checkIfHospitalHaveOption(OPTIONS.CALENDARS_DATA_PIPELINE);
  }

  doesHospitalHaveTransformDAIntoSOOption(): boolean {
    return this.checkIfHospitalHaveOption(OPTIONS.TRANSFORM_DA_INTO_SO);
  }

  doesHospitalHaveBlockedPlanning(): boolean {
    const currentUser = this.userService.getCurrentUser();

    if (!currentUser) {
      return false;
    }

    const hospital = getFirstHospitalSelectedData(currentUser, this.userService.getSelectedHospitals())
    if (hospital) {
        return hospital.isBlocked ? true : false;
    }

    return false;
  }

  getAllHospitals(): Observable<Hospital[]> {
    return this.hospitalDal.getAllHospitals();
  }

  addHospital(hospital: Hospital): Observable<Hospital> {
    return this.hospitalDal.addHospital(hospital);
  }

  modifyHospital(hospital: Hospital): Observable<Hospital> {
    return this.hospitalDal.modifyHospital(hospital);
  }

  archiveHospital(idHospital): Observable<Hospital> {
    return this.hospitalDal.archiveHospital(idHospital);
  }

  getHospital(hospitalId?: string): Observable<any> {
    return this.hospitalDal.getHospital(hospitalId);
  }

  editIsBlockedOneHospital(hospitalId: string, isBlocked: boolean): Observable<any> {
    return this.hospitalDal.editIsBlockedOneHospital(hospitalId, isBlocked);
  }

  updateNursesAutofillingSettings(args: { hospitalId: string, nursesAutofillingSettings: any }): Observable<any> {
    return this.hospitalDal.updateNursesAutofillingSettings(args);
  }

  updateAnesthsAutofillingSettings(args: { hospitalId: string, anesthsAutofillingSettings: any }): Observable<any> {
    return this.hospitalDal.updateAnesthsAutofillingSettings(args);
  }

  checkCompatibility(): Observable<any> {
    return this.hospitalDal.checkCompatibility();
  }

  editCycleHospital(hospitalId: String, data: Object): Observable<any> {
    return this.hospitalDal.editCycleHospital(hospitalId, data);
  }
}
