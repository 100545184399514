import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { Observable, of } from "rxjs";

import { urlEndPoints } from "../config/end-points";
import { UserService } from "../services/user.service";
import {ProgramCommentary} from "../models/program-commentary.model";

@Injectable()
export class ProgramCommentaryDal {
  constructor(private http: HttpClient, private userService: UserService) {}

  getProgramCommentary(date: string): Observable<any> {
    return this.http.get<any>(`${urlEndPoints.programCommentary}?date=${date}`);
  }

  getProgramCommentaryPeriod(startDate: string , endDate:string): Observable<any> {
    return this.http.get<any>(`${urlEndPoints.programCommentary}/period?startDate=${startDate}&endDate=${endDate}`);
  }

  addProgramCommentary(body: ProgramCommentary): Observable<any> {
    return this.http.post<any>(`${urlEndPoints.programCommentary}`, body);
  }

  updateProgramCommentary(body: ProgramCommentary, id: string): Observable<any> {
    return this.http.put<any>(`${urlEndPoints.programCommentary}${id}`, body);
  }
}
